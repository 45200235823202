@import '../../../variables.scss';
@import '../../../mixins.scss';

.bold {
  font-weight: bolder;
}

.table-wrap-setting {
  padding: 0rem !important;
}

.msg-series-evaluation {
  .ui.yellow.message {
    background-color: #fff7da !important;
    box-shadow: none !important;
    color: #bd640d !important;
    font-size: $Body-Small !important;
  }

  .ui.icon.message > .icon:not(.close) {
    display: block;
    flex: 0 0 auto;
    width: auto;
    line-height: 1;
    font-size: $h6;
    opacity: 0.8;
    color: #bd640d !important;
  }

  border-radius: 0.375rem;
  margin-bottom: 1.563rem !important;
  background-color: #bd640d !important;
}

.projected-value {
  margin-top: 0.5rem !important;
  @include Body-Default($Text-Secondary);
}

.ui.divided.grid:not([class*='vertically divided']) > .column:not(.row),
.ui.divided.grid:not([class*='vertically divided']) > .row > .column {
  box-shadow: none !important;
  border: none;
}

.current-member-wrapper {
  display: flex !important;
  flex-direction: row !important;
  overflow: auto !important;
}

.plan-input-label {
  color: $black !important;
}

.activity-owner {
  margin-left: 0.625rem !important;
  border-radius: 0.25rem !important;
  background: rgba(239, 129, 35, 0.1);
  @include Body-Small($Primary);
  text-align: center !important;
  padding: 0.4em 0.833em;
  margin-top: -0.063rem;
}

.upload-media {
  .content {
    padding: 0.429rem 1.5rem 1.5rem 1.5rem !important;
  }
  label.form-text.text-muted {
    font-size: 0.85714rem !important;
  }
}
.add-guest-user-modal,
.edit-guest-user-modal {
  .ui.grid {
    margin: 0rem !important;
  }

  column-gap: 12px !important;

  .content {
    padding: 0rem !important;
  }

  .custom-segment {
    padding: $spacing-lg !important;
    border-bottom: none !important;
  }

  .ui.section.divider {
    margin: 0rem !important;
  }

  .column {
    padding: 0rem !important;
  }

  .column-space {
    padding-bottom: 0rem !important;

    .column:first-child {
      padding-right: $spacing-lg !important;
    }
  }

  .form-control {
    background-color: #fff0;
  }

  .form-check-inline {
    margin-right: 38% !important;
  }

  .empty-cell-space-custom {
    margin-right: 32% !important;
  }

  .empty-cell-space-outer-custom {
    padding-right: 3rem !important;
  }

  .submit-button-right-align {
    display: flex;
    justify-content: flex-end;
  }

  .right.floated.sixteen.wide.column {
    display: flex;
    justify-content: flex-end;

    .Primary-Button {
      margin: 0rem !important;
    }
  }
}

.activity-owner-withdrawn {
  margin-left: 10px !important;
  width: 94px !important;
  height: 20px;
  border-radius: 4px !important;
  background: rgba(239, 129, 35, 0.1);
  font-size: 10px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #757575 !important;
  background: #f5f5f5 !important;
}

.evidence-modal-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  @include Body-Default;
  height: 4rem !important;
  background-color: $white !important;
}

.evidence-type-dropdown {
  border-radius: 0.5rem !important;
}

.ui.small.modal {
  border-radius: 0.5rem !important;
}

.evidence-objective-checkbox-group-label {
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  color: $black !important;
}

.evidence-objective-checkbox-label {
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  color: $black !important;
}

.evidence-modal-body {
  font-size: $Body-Default !important;
  line-height: 1.875rem !important;
  background-color: $white !important;
  padding: 1rem $spacing-lg !important;
  background-color: transparent !important;
}

.evidence-modal-actions {
  background-color: $white !important;
}

.scroll-table {
  display: block;
  max-height: 600px !important;
  overflow: auto;
}

.program-activities-objective-container {
  margin-left: 1rem;
  .program-objective {
    display: list-item;
    text-align: left;
  }
}

.program-activities-objective {
  display: list-item;
  text-align: left;
  margin-left: 1rem;
}

.activity-evaluation-table {
  th:nth-child(4),
  td:nth-child(4) {
    text-align: left !important;
  }

  overflow-x: auto;
}

.activity-evaluation-table tbody {
  width: 100%;
  display: block;
}

.activity-evaluation-table thead,
.activity-evaluation-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 0rem !important;
}

.program-activity-list-item {
  display: list-item !important;
}

.icons-add {
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.scroll-accordion {
  .home-activity {
    .empty-row:hover {
      background-color: transparent !important;
    }
  }
}

.home-activity th {
  font-size: $Body-Small;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  //letter-spacing: 0.2000000029802321.5rem;
  color: $subLabel !important;
  padding: 0.375rem 0rem 0.375rem 0rem;

  tfoot {
    th {
      padding: $spacing-lg 0rem $spacing-lg 0rem !important;
    }
  }
}

.home-activity td {
  border: none !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.375rem;
  padding: $spacing-md $spacing-xs 0rem 0rem !important;
}

.home-activity tr {
  padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;
}

.home-activity thead {
  background-color: $background;
}

.tbody-hover tbody tr {
  &:hover {
    background-color: #f3f4f6;
  }
}

.home-activity-items {
  text-align: center !important;
}

.home-activity-team td,
.home-activity-team tr {
  padding: 0.75rem 2.857rem !important;
  @include font-style(1rem, $info);
}

.home-activity-custom-modify {
  width: 100% !important;
}

.home-activity-custom-modify th {
  font-size: $Body-Small;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  //letter-spacing: 0.2000000029802321.5rem;
  color: $subLabel !important;
  padding: 0.625rem 0.5rem 0.625rem $spacing-xl !important;
  background: #fafafa;
  border: 0.031rem solid #d6d6d6;
}

.home-activity-custom-modify td {
  border: none !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.375rem;
  padding: 0.938rem 0.5rem 0.75rem $spacing-xl !important;
  border-bottom: 0.063rem solid #ebebeb !important;
}

.home-activity-custom-modify tr {
  padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;
}

.home-activity-custom-modify thead {
  background-color: $background;
}

.home-activity-custom-modify-items {
  text-align: center !important;
}

.home-activity-custom-modify-team td,
.home-activity-custom-modify-team tr {
  padding: 0.75rem 2.857rem !important;
  @include font-style(1rem, $info);
}

.home-activity-custom {
  thead {
    background-color: $background !important;

    tr {
      padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;

      th {
        font-size: $Body-Small;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        // letter-spacing: 0.2000000029802321.5rem;
        color: $subLabel !important;
        padding: 0.625rem 0.5rem 0.625rem $spacing-xl !important;
      }
    }
  }

  tbody {
    display: block !important;
    height: 12.5rem !important;
    overflow: auto !important;
    min-height: 12.5rem !important;

    tr {
      padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;

      td {
        border: none !important;
        color: $info;
        font-family: $fontFamily;
        font-size: $Body-Small;
        line-height: 1.375rem;
        padding: 0.938rem 0.5rem 0.75rem $spacing-xl !important;
        border-bottom: 0.063rem solid #ebebeb !important;
      }
    }
  }
}

.home-activity-custom-items {
  text-align: center !important;
}

.column-title-custom {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: $Body-Small;
  line-height: 1.25rem;
  letter-spacing: 0.125rem;
  color: #757575;
}

.home-activity-custom-team td,
.home-activity-custom-team tr {
  padding: 0.75rem 2.857rem !important;
  @include font-style(1rem, $info);
}

.activity-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.625rem;

  .activity-actions-box-success {
    height: 2.188rem !important;
    width: 10.625rem;
    border: 0.063rem solid #ef8123;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    color: $gradientRight;

    .accept-text {
      color: $gradientRight;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      text-align: center;
      margin-top: 0.375rem;
    }
  }

  .reject {
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }

  .activity-actions-box-success:hover {
    background-color: $gradientRight;
    color: red;

    .accept-text {
      color: #fff;
    }
  }
}

.activity-actions-program-activities {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.reject-text {
  text-align: center;
  margin-top: 0.313rem;
  margin-left: 2.857rem;
  cursor: pointer;
  @include Body-Default($red);
}

.activity-actions-warn {
  font-weight: normal !important;
  color: $gradientRight !important;
  cursor: pointer;
}

.activity-actions-text {
  @include Body-Default;
  cursor: pointer;
}

.activity-actions-text-warning {
  @include Body-Default($red);
  cursor: pointer;
}

.activity-actions-text-native-color {
  @include Body-Default($Primary);
  text-align: right;
  cursor: pointer;
}

.delete-proceed-btn {
  background-color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  color: $white !important;
}

.delete-cancel-btn {
  border: 0.063rem solid $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  color: $gradientRight !important;
  background-color: transparent !important;
}

.delete-cancel-btn:hover {
  color: $white !important;
  background-color: $gradientRight !important;
}

.activity-actions-enter-activity {
  @include Body-Default($Primary);
  cursor: pointer;
  margin-bottom: 0.75rem;
}

.delete-media-header {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  height: fit-content !important;
}

.activity-actions-error {
  color: $red !important;
  display: contents;
  cursor: pointer;
}

.activity-actions-error-custom {
  @include Body-Default($Error);
  cursor: pointer;
}

.label-container {
  text-align: center;
  padding-top: 0.25rem;
  @include Body-Default($Primary);
  width: max-content;
  cursor: pointer;
}

.remove-evidence {
  @include Body-Default($Error);

  cursor: pointer;
  padding-top: 0.25rem;
}

.remove-department {
  @include Body-Default($Error);
  cursor: pointer;
}

.upload-doc-media {
  .ui.segment {
    box-shadow: none;
    padding: 0rem;
    border: none;
  }
  .ui.button {
    margin: 0 0 0 0.5rem;
  }
}

.remove-activity {
  color: $red !important;
}

.activity-actions-program-activities {
  display: flex;
  flex-direction: column;
  padding-left: 0.938rem;
}

.activity-actions-dropdown {
  .activity-actions-dropdown-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 214px !important;
    padding: 12px 16px !important;
    border-bottom: 2px solid #e0e0e0 !important;

    label {
      margin: 0rem !important;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }

  .activation-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: $Body-Small;
    line-height: 20px;
    color: #ef8123;
  }

  .activity-actions-dropdown-delete {
    padding: 0.438rem $spacing-sm !important;

    label {
      margin: 0rem !important;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}

.select-hover {
  .left.visible.menu.transition {
    .active.item {
      span {
        color: $amber !important;
      }
    }
  }
}

.activity-actions-success {
  @include Body-Default($gradientRight);
  text-align: right !important;
  cursor: pointer;
  margin-right: 1.25rem;
}

.green-text {
  color: $success !important;
}

.owned {
  display: flex;
  justify-content: flex-end;

  .ui.dropdown .menu > .item {
    border: 0.063rem solid #e0e0e0;
  }

  .ui.dropdown .menu > .item:hover {
    .black-text {
      color: $gradientRight !important;
      cursor: pointer;
    }

    .remove-activity {
      color: $gradientRight !important;
      cursor: pointer;
    }
  }
}

.invited {
  .ui.dropdown .menu > .item {
    border: 0.063rem solid #e0e0e0;
  }

  .ui.dropdown .menu > .item:hover {
    .black-text {
      color: $gradientRight !important;
      cursor: pointer;
    }

    .remove-activity {
      color: $gradientRight !important;
      cursor: pointer;
    }
  }
}

.evaluation-comments-margin {
  margin: $spacing-lg 0 !important;
}

.black-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.black-text {
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.red-text {
  color: $red !important;
}

.selected-group-edit {
  color: $editGroup !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.selected-group-remove {
  color: $red !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.outcomes-obj-unchecked-icon {
  color: $White;
  margin-right: 0.5rem !important;
}

.outcomes-obj-checked-icon {
  color: $White;
  margin-right: 0.5rem !important;
  background-color: $amber !important;
}

.outcomes-obj-error-icon {
  color: $White;
  margin-right: 0.5rem !important;
  background-color: $Error !important;
}

.activity-name {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    flex: 1;
  }

  .outline-square {
    min-height: 0.5rem;
    height: 0.5rem;
    min-width: 0.5rem;
    width: 0.5rem;
    border-radius: 0.125rem;
    margin-right: 0.5rem;
    box-shadow: 0 0 0 0.125rem #f2711c inset !important;
    color: #f2711c !important;
  }

  .activity-name-trigger {
    color: $gradientRight !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .activity-name-not-allowed {
    color: $gradientRight !important;
  }

  .square {
    height: 0.5rem !important;
    width: 0.5rem !important;
    border-radius: 50%;
    display: inline-block;
    border-radius: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 0.313rem;
    background-color: $info;
  }

  .activity-completed {
    background-color: $blue;
  }

  .activity-success {
    background-color: $green;
  }

  .activity-warning {
    background-color: $amber;
  }

  .activity-outline {
    background-color: #ffffff !important;
    box-shadow: 0 0 0 0.063rem #f2711c inset !important;
  }

  .activity-error {
    background-color: $red;
  }

  .date-added {
    display: flex;
    flex-direction: column;

    .date-created {
      display: flex;
      flex-direction: row;
      @include Body-Small($Text-Secondary);
    }
  }

  .date-display {
    font-size: $Body-Small !important;
  }

  .edit-update {
    margin-left: 0.625rem;
  }

  .enter-activity-link {
    color: #3b3b3b;

    &:hover {
      color: $gradientRight;
    }
  }
}

.square {
  min-height: $spacing-sm;
  height: 0.5rem !important;
  min-width: $spacing-sm;
  width: 0.5rem !important;
  border-radius: 0.188rem;
  margin-right: 0.625rem;
  margin-bottom: 0.313rem;
  background-color: $info;
}

.activity-completed {
  background-color: $blue;
}

.activity-success {
  background-color: $green;
}

.activity-warning {
  background-color: $amber;
}

.activity-error {
  background-color: $red;
}

@media only screen and (max-width: 767px) {
  .activity-actions {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.Program_Reports {
  .content-segment {
    padding: 0rem !important;
  }
}

.ui.visible.right.sidebar ~ .pusher {
  transform: translate3d(-300px, 0, 0);
}

.activity-form {
  .form-check {
    padding: 0;
    margin-top: 0.625rem;
  }

  .two-step-radio-container {
    margin: 0.5rem 0rem !important;
  }

  .content-segment {
    padding: 0rem $spacing-sm;
    border-radius: 0 !important;
  }

  .content-segment-checklist {
    padding: 0.625rem $spacing-xl !important;
  }
}

.content-segment-custom {
  padding: 0rem 0rem 0rem 0rem !important;
}

.org-sort {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0rem !important;

  .activities-filter-container {
    display: flex;
    flex-direction: row;
  }

  .activities-filter {
    display: flex;
    align-items: center;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default;
    line-height: 1.375rem;
    margin-left: 1.563rem;
    margin-right: 0rem;
  }
}

.manage-activity-drop {
  .dropdown.icon {
    margin-left: 0.625rem !important;
  }
}

.custom-accordian-outer {
  margin-left: -$spacing-xl !important;
  margin-right: -$spacing-xl !important;
}

.content-bg {
  background-color: $background !important;
}

// organization profile / layout
.ui.grid > .act-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .activity-sub-column {
    padding-top: 0.938rem;

    @media only screen and (max-width: 48rem) {
      .reference-custom-style {
        margin-left: 0rem !important;
      }
    }

    .act-inputs {
      height: 2.25rem !important;
      @include Body-Default();
      border-radius: 0.5rem !important;
    }

    .act-goal-inputs {
      height: 2.857rem !important;
      @include Body-Default();
      border-radius: 0.5rem !important;
    }

    .act-goal-label {
      @include Body-Default();
      border-radius: 0.5rem;
      min-height: 2.857rem !important;
      height: fit-content !important;
    }

    .act-inputs-label {
      height: 100% !important;
      min-height: 47px !important;
      @include Body-Default();
    }

    .obj-inputs {
      height: 47px !important;
      @include Body-Default();
      border-radius: 0.5rem !important;
      height: 2.857rem !important;
    }

    .segment-heading-div {
      display: contents;

      .segment-heading {
        @include Body-Default($Text-Secondary);
        margin: 0.313rem 0;
        padding: 0rem 0rem 0rem 1.125rem;
      }
    }

    .eval-plan-label {
      @include Body-Default($Gray-70);
    }

    .goal-label {
      @include Body-Default($Text-Secondary);
    }
  }

  .ui.grid > .act-row .activity-sub-column .evaluation-lbl {
    cursor: pointer;
    margin-top: 0.625rem;
    @include font-style(1rem, $gradientRight, 600);
  }

  .previous-button {
    width: 129px;
  }

  .survey-button:hover {
    color: $amber !important;
  }

  .survey-button {
    border: 0.125rem solid $gradientRight !important;
    border-radius: 0.25rem !important;
    background-color: $white !important;
    @include Body-Small($gradientRight);
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
    margin-left: 1.25rem !important;
  }

  .survey-button:hover {
    color: $amber !important;
  }

  .see-more-see-less-btn {
    width: 9.375rem;
    @include Body-Default-Semibold($gradientRight);

    background-color: transparent;
    border: 0.063rem solid transparent;
    text-align: left !important;
    padding: 0rem !important;
  }
}

.individual-account-img-upload {
  margin-top: $spacing-2xl;
}

.org-account-img-upload {
  margin-top: 40px;
  margin-bottom: $spacing-xl;
}

.account-type-toggle {
  display: -webkit-box;
  margin-top: -15px;

  .edit-outcome-status-bar {
    margin-right: 1rem;

    .two-step-radio-container-admin {
      margin-left: 1rem !important;
      margin-top: 2px;
    }

    label {
      margin-bottom: 5px;
    }
  }
}

.account-type-toggle-individual {
  display: -webkit-box;
  margin-top: 15px;

  .edit-outcome-status-bar {
    margin-right: 1rem;

    .two-step-radio-container-admin {
      margin-left: 1rem !important;
      margin-top: 2px;
    }

    label {
      margin-bottom: 5px;
    }
  }
}

.diff-postal-address-check {
  .form-check {
    padding: 0rem !important;
    display: flex;
  }

  .form-check-label {
    margin-bottom: 3px;
  }
}

.act-inputs-row {
  position: relative !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: stretch !important;
  width: 100% !important;
  padding: 0rem 0 !important;
}

.objectiveGoal-title {
  @include Body-Default;
}

.objectiveGoal-title-new {
  @include Body-Default;
}

.search-icon-custom {
  border-left: 0.063rem solid #e0e0e0 !important;
}

.objective-description {
  @include Body-Default($Text-Secondary);
  margin-top: 1rem !important;
}

.objective-description-goal {
  @include Body-Small($Text-Secondary);
}

.objective-description-new {
  @include Body-Default;
  margin-right: 84px;
}

.select-objective-label {
  @include Body-Default;
}

.act-row .row {
  .ui.grid > .row.goal-group {
    padding-top: 1.25rem !important;
  }

  .goal-description {
    padding-top: 1.25rem !important;
    background-color: #fafafa;
  }

  .objective-row {
    margin-bottom: 1.25rem !important;
  }
}

// evidence

.evidenceSearch {
  .results {
    max-height: 17.5rem;
    overflow: auto;
  }

  .results.transition.visible {
    width: 460px !important;
    max-height: 17.5rem;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

//activity header text

.activity-header-popup-text {
  max-width: 100% !important;
}

.search-custom-height {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
}

.evidence-sector {
  padding: 0 !important;

  .evidence-actions-warn {
    color: $gradientRight !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: normal !important;
    line-height: 1.25rem !important;
    cursor: pointer !important;
  }

  .ui.basic.table {
    background-color: $white;
  }

  .evidence-table {
    padding: 26px 0rem;
    background-color: $white;

    th,
    td {
      vertical-align: inherit !important;
    }

    thead tr th b {
      padding-left: $spacing-xl;
    }

    .evidence-container {
      display: flex !important;
      flex-direction: column !important;

      .ui.label {
        background-color: $white;
        @include Body-Default();
        padding: 0;
      }
    }

    .evidence-mainText {
      color: $black !important;
      font-family: $fontFamily !important;
      font-size: $Body-Small !important;
      line-height: 1.25rem !important;
      font-style: normal !important;
      font-weight: normal !important;
      padding: 0rem;
    }

    .evidence-text {
      color: $info !important;
      font-family: $fontFamily !important;
      font-size: $Body-Small !important;
      font-weight: 300 !important;
      line-height: 1.375rem !important;
    }

    .evidence-text-container {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-evenly !important;
    }

    .evidence-actions {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-evenly !important;
    }

    .cursor {
      cursor: pointer;
    }

    .evidence-actions-warn {
      color: $gradientRight !important;
      font-family: $fontFamily !important;
      font-size: $Body-Small !important;
      font-weight: normal !important;
      line-height: 1.25rem !important;

      .cursor-pointer {
        cursor: pointer !important;
      }
    }

    .evidenceSearch {
      width: 65% !important;
    }

    .ui.input > input {
      .ui.search .prompt {
        border-radius: 0rem;
      }
    }

    .ui.icon.input {
      width: 100% !important;

      input {
        border-radius: 0.25rem;
      }
    }
  }

  .title-evidence-edit {
    padding-right: 2.857rem !important;
  }

  .evidence-table-new th {
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.2000000029802321.5rem;
    color: $subLabel !important;
    padding: 0.625rem 0.5rem 0.625rem $spacing-xl !important;
  }

  .evidence-table-new td {
    border: none !important;
    border-bottom: 0.063rem solid #e0e0e0 !important;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.375rem;
    padding: 0.938rem 0.5rem 0rem 1.25rem !important;
    background-color: $white !important;
  }

  .evidence-table-new thead {
    background-color: $background;
  }

  .evidence-table-new-items {
    text-align: center !important;
  }

  .evidence-table-grant {
    th {
      background-color: #fafafa !important;
      padding-top: 0.688rem !important;
    }

    td {
      vertical-align: inherit !important;
      padding-top: 0.813rem !important;
    }

    th:first-child,
    td:first-child {
      padding-left: 1.75rem !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 1.75rem !important;
    }

    .evidence-container {
      display: flex !important;
      padding: 0rem !important;
    }

    .evidence-actions-warn {
      @include Body-Default($Primary);
      cursor: pointer !important;
    }

    .evidenceSearch {
      width: 65% !important;
    }

    .ui.input > input {
      .ui.search .prompt {
        border-radius: 0rem;
      }
    }

    .ui.icon.input {
      width: 100% !important;

      input {
        border-radius: 0.25rem;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .evidenceSearch {
      width: 100%;
    }

    .add-evidence-container {
      display: flex;
      margin-bottom: $spacing-xl;
      padding-top: 6.25rem;
      justify-content: center;
    }
  }
}

.current-member-label {
  display: flex !important;
}

.grants-greenlight-import-table {
  padding: 0 !important;
  height: 12.5rem !important;
  overflow-y: scroll !important;

  .ui.basic.table {
    background-color: transparent !important;
  }

  .imports-table {
    height: 0.625rem !important;
  }

  .imports-table th {
    position: sticky !important;
    top: 0 !important;
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    //letter-spacing: 0.2000000029802321.5rem;
    color: $subLabel !important;
    padding-left: $spacing-xl !important;
    background-color: $background !important;
  }

  .imports-table td {
    border: none !important;
    border-bottom: 0.063rem solid #e0e0e0 !important;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.375rem;
    padding: 0.75rem 0.5rem 0.75rem $spacing-xl !important;
    background-color: $white !important;
  }

  .imports-table thead {
    background-color: $background;
  }

  .imports-table-items {
    text-align: center !important;
  }

  .file-description {
    font-size: $Body-Small !important;
  }
}

.search-inline {
  display: flex;
  flex-direction: row;
  margin-left: $spacing-xl;
}

.search-inline-grant-evidence {
  display: flex;
  flex-direction: row;
}

.search-inline-single-evidence {
  display: flex;
  flex-direction: row;
}

.form-group-goal-heading {
  font-weight: 600 !important;
}

.form-group-goal-subheading {
  @include Body-Small($Text-Secondary);
  margin-bottom: $spacing-xl;
}

.form-group-objective-domain {
  margin-left: 1.125rem;
  margin-top: 1.25rem;
  margin-right: 1.25rem;
}

.form-group-evidence-margin-left {
  display: flex;
  flex-direction: column;
}

.intended-outcomes-margin-left {
}

.intended-outcomes-margin-right {
  margin-right: 1.25rem;
  margin-top: 0.625rem;
}

.form-group-evidence-margin-right {
  display: flex;
  flex-direction: column;
  margin-right: 1.125rem;
}

@media only screen and (max-width: 767px) {
  .form-group-evidence-margin-right {
    margin-left: 1.25rem;
    margin-right: 0rem;
  }

  .intended-outcomes-margin-right {
    margin-left: 1.25rem;
    margin-right: 0rem;
    margin-top: 0rem;
  }

  .form-group-objective-margin-right {
    margin-left: 1.25rem;
    margin-right: 0rem;
  }
}

.goal-title-single-evidence {
  font-size: $Body-Default !important;
  color: $black !important;
}

.bottom-divider {
  margin-bottom: 0.063rem solid #e0e0e0;
  width: 100%;
  height: 0.063rem;
  background-color: #e0e0e0;
}

.objective-description-domain {
  margin-right: 180px;
  display: flex;
  flex-direction: column;
}

.padding-left-activity-data {
  padding-right: 18.75rem !important;
}

.extra-top-margin-evidence-modal {
  margin-top: 0.625rem !important;
}

.extra-top-margin-theory {
  margin-top: 1.25rem !important;
}

.charts-method-row {
  margin-top: 6.25rem !important;
  padding-top: 6.25rem !important;
}

.checkbox-restrict {
  margin-right: 0.313rem;
  margin-left: 1.25rem;
  margin-top: 0.625rem;
}

.restrict-label {
  margin-top: 0.625rem;
}

.evidence-radio {
  padding-left: 0 !important;

  legend {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.063rem;
  }

  .form-control {
    background-color: transparent;
  }
}

.evidence-parent-head {
  padding-bottom: 1.25rem;

  h4 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Default;
    line-height: 1.5rem;
    color: $black;
  }
}

.evidence-title {
  padding-left: $spacing-xl;
  padding-right: 1.25rem;
  padding-top: $spacing-xl;
  padding-bottom: $spacing-xl;
  @include Body-Small(#3b3b3b);
}

.add-evidence {
  display: flex;
  flex-direction: row-reverse;
}

.add-evidence {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2.75rem;
}

.evidence-container {
  display: flex;
  flex-direction: column;
  color: $info;

  .evidence-description {
    color: $black;
    font-family: $fontFamily;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
  }
}

.library-evidence-description {
  font-size: $Body-Small !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.25rem !important;
  color: $black !important;
  text-align: justify !important;
}

// theory of change

.theory-container {
  display: flex;
  flex-direction: column;
  color: $info;

  .theory-description {
    color: $black !important;
    font-family: $fontFamily;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
  }
}

.theory-description {
  font-size: $Body-Default !important;
}

.see-more-see-less-btn-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  //margin-left: 1.25rem;
}

.theory-container-single {
  display: flex;
  flex-direction: column;
  color: $info;
}

.theory-container-aim {
  padding-left: 1.125rem;
}

.summarise-input-custom-label {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  color: #000000 !important;
}

.theory-title {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  color: #000000 !important;
}

.textarea-theory {
  @media (max-width: 1300px) {
    padding: 0rem $spacing-sm !important;
  }
}

.theory-textarea {
  min-height: 9.375rem !important;
  border-radius: 0.5rem !important;
}

.disabled-input-status {
  cursor: not-allowed !important;
}

.notes-textarea {
  border-radius: 0.5rem !important;
  max-width: 65% !important;
}

.theory-textarea-container-single {
  margin-left: 1rem;
}

.theory-text-row {
  padding: 0rem 1.063rem $spacing-lg !important;

  @media (max-width: 1300px) {
    padding-bottom: $spacing-lg !important;
  }
}

.theory-textarea-sm {
  min-height: 10em !important;
}

.evidence-modal-textarea {
  min-height: 300px !important;
  border-radius: 0.5rem !important;
}

// activity members

.act-member-segment {
  border-bottom: none !important;
  border-top: none !important;
}

.edit-permission-dropdown-grid {
  margin-left: 1.563rem !important;
  margin-right: 1.563rem !important;
}

@media only screen and (min-width: 112.5rem) {
  .custom-modal-size {
    .ui.small.modal {
      width: 600px;
      margin: 0;
    }
  }
}

.new-member-segment-custom {
  margin: 0;

  textarea.form-control {
    height: auto;
  }

  .form-control {
    height: 3.125rem;
  }

  select {
    width: 100% !important;
  }

  .permission-edit {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    color: #000000 !important;
    margin-top: $spacing-lg !important;
  }

  .submit-button {
    margin-top: $spacing-lg;
    height: 2.857rem;
    @include button-style;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 3.188rem, 0.563rem, 3.188rem);
  }

  .permission-dropdown {
    height: 3.125rem;
    @include display-flex(flex-start, center);

    i {
      height: 100%;
      @include display-flex(center, center);
    }

    .text {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
    }
  }

  .invite-member-custom {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }

  .permission-dropdown-custom {
    height: 2.25rem !important;
    border-radius: 0.5rem !important;

    @include display-flex(flex-start, center);

    i {
      height: 100%;
      @include display-flex(center, center);
    }

    .text {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
    }
  }

  .invalid-input {
    border-color: red !important;
  }

  .invalid-permission {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

.invite-user-modal-custom {
  margin-left: $spacing-lg !important;
  margin-right: $spacing-lg !important;
  margin-top: $spacing-lg !important;
  margin-bottom: $spacing-lg !important;
}

.new-member-segment-custom-participant {
  textarea.form-control {
    height: auto;
  }

  .form-control {
    height: 3.125rem;
  }

  select {
    width: 100% !important;
  }

  .permission-edit {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    color: #000000 !important;
  }

  .submit-button {
    margin-top: $spacing-lg;
    height: 2.857rem;
    @include button-style;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 3.188rem, 0.563rem, 3.188rem);
  }

  .permission-dropdown {
    height: 3.125rem;

    @include display-flex(flex-start, center);

    i {
      height: 100%;
      @include display-flex(center, center);
    }

    .text {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
    }
  }

  .invite-member-custom {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }

  .permission-dropdown-custom {
    height: 2.25rem !important;
    border-radius: 0.5rem !important;

    @include display-flex(flex-start, center);

    i {
      height: 100%;
      @include display-flex(center, center);
    }

    .text {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
    }
  }

  .invalid-input {
    border-color: red !important;
  }

  .invalid-permission {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

.invalid-permission-custom {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  padding-left: 2.25rem !important;
}

.invalid-permission-activity-participant {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #dc3545;
  margin-top: -2.857rem !important;
}

.invite-member-custom-wrap {
  display: flex !important;
  flex-direction: row !important;
  margin-left: 70% !important;
  background: #ef8123 !important;
  border: 0.125rem solid #ef8123 !important;
  color: white !important;
}

.invite-member-custom-wrap-participant {
  display: flex !important;
  flex-direction: row !important;
  margin-left: 82% !important;
  background: #ef8123 !important;
  border: 0.125rem solid #ef8123 !important;
  color: white !important;
}

.search-bar-wrapper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.contact-text {
  width: 50% !important;
}

.contact-btn-text {
  display: flex;
  justify-content: flex-end !important;
  width: 50% !important;
}

.search-field-custom {
  @include search-field;
  box-sizing: border-box;
  border-radius: 0.5rem !important;
  width: 28.4rem !important;
  height: 2.563rem !important;
}

.ui.grid > .act-member-row {
  background-color: white;
  border-radius: 0.313rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .add-member-column {
    padding: 0 !important;

    .custom-segment {
      @include set-padding(1.25rem);
      margin-bottom: 0;

      .participants-title {
        @include font-style;
        opacity: 0.6;
      }

      .participants-title-custom {
        @include font-style;
        font-family: Inter !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: $Body-Default !important;
        line-height: 1.5rem !important;
        color: #000000 !important;
      }

      display: flex;
      flex-direction: column;

      .search-label {
        padding-top: 1.25rem;
      }

      .search-label-custom {
        padding-top: 1.25rem;
        @include Body-Default($Text-Primary);

        /* identical to box height, or 21px */

        color: #000000;
      }

      .search-bar {
        @include search-field;
      }
    }

    .new-member-segment {
      margin: 0;
      @include set-padding(1.375rem, $spacing-xl, $spacing-lg, $spacing-xl);

      textarea.form-control {
        height: auto;
      }

      .form-control {
        height: 3.125rem;
      }

      select {
        width: 100% !important;
      }

      .submit-button {
        margin-top: $spacing-lg;
        height: 2.857rem;
        @include button-style;
        @include font-style(1rem, $white, bold);
        @include set-padding(0.563rem, 3.188rem, 0.563rem, 3.188rem);
      }

      .permission-dropdown {
        height: 3.125rem;
        @include display-flex(flex-start, center);

        i {
          height: 100%;
          @include display-flex(center, center);
        }

        .text {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Default;
          line-height: 1.375rem;
        }
      }

      .invalid-input {
        border-color: red !important;
      }

      .invalid-permission {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
    }

    .org-inputs {
      height: 2.857rem !important;
    }

    .org-inputs-textarea {
      min-height: 16em !important;
    }

    legend {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.063rem;
    }

    fieldset {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .contractor-search {
      padding-bottom: $spacing-xl;
      margin-bottom: 0;

      .participants-title {
        @include font-style;
        opacity: 0.6;
      }

      .participants-title-custom {
        @include font-style;

        font-family: Inter !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: $Body-Default !important;
        line-height: 1.5rem !important;
        color: #000000 !important;
      }

      display: flex;
      flex-direction: column;

      .search-label {
        padding-top: 1.25rem;
      }

      .search-bar {
        @include search-field;
      }
    }

    select {
      width: 70% !important;
    }

    .ui.button.contractor {
      background-color: #ffffff !important;
      font-size: $Body-Small !important;
      color: $gradientRight !important;
      font-weight: 100;
      font-style: normal;
      padding: 0;
      margin-top: 0 !important;
      margin-bottom: 0.438rem;
      display: flex;
      justify-content: flex-end;
    }

    .results.transition.visible {
      width: 100%;
    }

    .message.empty {
      width: 100%;
    }

    .contractor-notfound {
      font-size: $Body-Small !important;
    }

    .header {
      padding: 0.625rem;
    }

    .add-user {
      padding-top: 0.313rem;

      @media (min-width: 576px) {
        position: absolute;
        top: 1.438rem;
        left: 180px;
        padding-top: 0rem;
      }
    }

    .contractor-error {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .contractor-input-err {
      input {
        border-color: #dc3545;
      }
    }
  }

  .view-member-column {
    background-color: $orgBackground;
    padding: 0 !important;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .view-member-seg {
      max-height: 110vh; //edit to change the height shown for CURRENT MEMBERS column

      .scroll-members {
        padding-right: 1.25rem;
        max-height: 100%;
        overflow-y: auto;
      }

      .title-padding {
        padding-bottom: 1.25rem;
        margin-bottom: 0rem;
      }

      background-color: $orgBackground;
      @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

      .member-holder {
        @include set-padding(1.25rem, 0, 1.25rem, 0);
        border-bottom: 0.063rem solid #eeeeee;

        .member-name-role {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .member-name {
            @include font-style(1rem, $info, 600);
          }

          .member-role {
            @include font-style(0.75rem, $info, 600);
            opacity: 0.6;
            text-transform: capitalize;
          }
        }

        .member-email {
          @include font-style(0.75rem, $info);
          opacity: 0.6;
        }
      }
    }
  }

  .view-member-column,
  .add-member-column {
    .form-title {
      @include font-style(1rem, $info, 600);
      opacity: 0.8;
    }
  }
}

.ui.grid > .act-member-row-custom {
  background-color: #fafafa;
  border-radius: 0.313rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .add-member-column {
    padding: 0 !important;

    .custom-segment {
      @include set-padding(1.25rem);
      margin-bottom: 0;

      .participants-title {
        @include font-style;
        opacity: 0.6;
      }

      .participants-title-custom {
        @include font-style;
        font-family: Inter !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: $Body-Default !important;
        line-height: 1.5rem !important;
        color: #000000 !important;
      }

      display: flex;
      flex-direction: column;

      .search-label {
        padding-top: 1.25rem;
      }

      .search-label-custom {
        padding-top: 1.25rem;
        @include Body-Default;
        /* identical to box height, or 21px */
      }

      .program-activities-input {
        height: 2.857rem !important;
        border-radius: 0.5rem !important;
        font-size: $Body-Small !important;
      }

      .view-selectedGroups-column {
        background-color: $selected;

        color: #000000;
      }

      .search-bar {
        @include search-field;
      }
    }

    .new-member-segment {
      margin: 0;
      @include set-padding(1.375rem, $spacing-xl, $spacing-lg, $spacing-xl);

      textarea.form-control {
        height: auto;
      }

      .form-control {
        height: 3.125rem;
      }

      select {
        width: 70% !important;
      }

      .submit-button {
        margin-top: $spacing-lg;
        height: 2.857rem;
        @include button-style;
        @include font-style(1rem, $white, bold);
        @include set-padding(0.563rem, 3.188rem, 0.563rem, 3.188rem);
      }

      .permission-dropdown {
        height: 3.125rem;
        @include display-flex(flex-start, center);

        i {
          height: 100%;
          @include display-flex(center, center);
        }

        .text {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Default;
          line-height: 1.375rem;
        }
      }

      .invalid-input {
        border-color: red !important;
      }

      .invalid-permission {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
    }

    .org-inputs {
      height: 2.857rem !important;
    }

    .org-inputs-textarea {
      min-height: 16em !important;
    }

    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.063rem;
    }

    legend {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.063rem;
    }

    fieldset {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .contractor-search {
      padding-bottom: $spacing-xl;
      margin-bottom: 0;

      .participants-title {
        @include font-style;
        opacity: 0.6;
      }

      .participants-title-custom {
        @include font-style;

        font-family: Inter !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: $Body-Default !important;
        line-height: 1.5rem !important;
        color: #000000 !important;
      }

      display: flex;
      flex-direction: column;

      .search-label {
        padding-top: 1.25rem;
      }

      .search-bar {
        @include search-field;
      }
    }

    select {
      width: 100% !important;
    }

    .ui.button.contractor {
      background-color: #ffffff !important;
      font-size: $Body-Small !important;
      color: $gradientRight !important;
      font-weight: 100;
      font-style: normal;
      padding: 0;
      margin-top: 0 !important;
      margin-bottom: 0.438rem;
      display: flex;
      justify-content: flex-end;
    }

    .results.transition.visible {
      width: 100%;
    }

    .message.empty {
      width: 100%;
    }

    .contractor-notfound {
      font-size: $Body-Small !important;
    }

    .header {
      padding: 0.625rem;
    }

    .add-user {
      padding-top: 0.313rem;

      @media (min-width: 576px) {
        position: absolute;
        top: 1.438rem;
        left: 180px;
        padding-top: 0rem;
      }
    }

    .contractor-error {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .contractor-input-err {
      input {
        border-color: #dc3545;
      }
    }
  }

  .view-member-column {
    background-color: $orgBackground;
    padding: 0 !important;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .view-member-seg {
      max-height: 110vh; //edit to change the height shown for CURRENT MEMBERS column

      .scroll-members {
        padding-right: 1.25rem;
        max-height: 100%;
        overflow-y: auto;
      }

      .title-padding {
        padding-bottom: 1.25rem;
        margin-bottom: 0rem;
      }

      background-color: $orgBackground;
      @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

      .member-holder {
        @include set-padding(1.25rem, 0, 1.25rem, 0);
        border-bottom: 0.063rem solid #eeeeee;

        .member-name-role {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .member-name {
            @include font-style(1rem, $info, 600);
          }

          .member-role {
            @include font-style(0.75rem, $info, 600);
            opacity: 0.6;
            text-transform: capitalize;
          }
        }

        .member-email {
          @include font-style(0.75rem, $info);
          opacity: 0.6;
        }
      }
    }
  }

  .view-member-column,
  .add-member-column {
    .form-title {
      @include font-style(1rem, $info, 600);
      opacity: 0.8;
    }
  }
}

.participants-title-label {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  color: #000000 !important;
  margin-bottom: 1rem !important;
}

.search-label-participants {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  color: #000000 !important;
}

.group-wrapper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.customColor-wrapper-group {
  width: 169px !important;
  height: 2.857rem !important;
  background: #ffffff !important;
  border: 0.063rem solid #ef8123 !important;
  box-sizing: border-box;
  border-radius: 0.25rem;
  color: #ef8123 !important;
  margin-right: 1.875rem !important;
  margin-top: 0.625rem !important;
  padding: 0rem !important;
  font-family: Inter !important;
}

.add-contact-name {
  @include Body-Default;
}

.contact-name {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: $Body-Default !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-bottom: $spacing-lg !important;
}

.table-scroll {
  height: 12.5rem !important;
  min-height: 12.5rem !important;
}

.search-bar-invite-user {
  @include search-field-custom;
}

.extra-padding-sides-contact {
  margin-top: 14px !important;
  padding: 0px 30px 0px 45px !important;
}

.search-bar-custom {
  @include search-field-custom;
  width: 28.4rem !important;

  @media only screen and (max-width: 1198px) {
    width: 328px !important;
  }
}

.input-fields-radius {
  border-radius: 0.5rem !important;
  height: 2.25rem !important;
  font-size: $Body-Small !important;
}

.input-fields-radius-msg {
  border-radius: 0.5rem !important;
  height: 72px !important;
}

.media-modal-avfield {
  border-radius: 0.5rem !important;
  height: 2.857rem !important;
}

.media-modal-textarea {
  border-radius: 0.5rem !important;
  height: 70px !important;
}

.participant-input-label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #000000;
}

.add-venue-text {
  @include Body-Default;
  margin-bottom: 0.5rem !important;
}

.view-member-column-custom {
  background-color: $orgBackground;
  padding: 0 !important;
  @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

  .view-member-seg {
    max-height: 110vh; //edit to change the height shown for CURRENT MEMBERS column

    .scroll-members {
      padding-right: 1.25rem;
      max-height: 100%;
      overflow-y: auto;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $orgBackground;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .member-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .member-name-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-name {
          @include font-style(1rem, $info, 600);
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.view-member-column,
.add-member-column {
  .form-title {
    @include font-style(1rem, $info, 600);
    opacity: 0.8;
  }
}

.search-bar-wrap {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.view-selectedGroups-column {
  background-color: $selected;

  padding: 0 !important;
  margin-top: 6.25rem;
  @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

  .view-selected-seg {
    box-shadow: none;
    overflow-y: auto;
    max-height: 60vh; //edit to change the height shown for CURRENT MEMBERS column

    .scroll-selectedgroups {
      padding-right: 1.25rem;
      max-height: 100%;
      overflow-y: auto;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .selected-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .selected-name-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .selected-name {
          @include font-style(1rem, $info, 600);
        }

        .selected-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }

        .selected-sub {
          @include font-style(0.75rem, $info);
          opacity: 0.6;
        }
      }
    }
  }
}

.react-semantic-custom-table-venue {
  overflow-x: auto;
  border-radius: 0rem !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;

  tbody {
    display: block;
    height: 12.5rem;
    overflow: auto;
    border: none !important;
  }

  #header {
    background: #fafafa !important;
    color: #757575 !important;
    padding-left: $spacing-xl !important;
    border: none !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: $Body-Small;
    line-height: 1.25rem;
  }

  #body {
    color: #757575 !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    border: none !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.react-semantic-custom-table {
  overflow-x: auto;
  border-radius: 0rem !important;
  border: none !important;

  tbody {
    display: block;
    height: 12.5rem;
    overflow: auto;
    border: none !important;
    background-color: red !important;
  }

  #header {
    background: #fafafa !important;
    color: #757575 !important;
    padding-left: $spacing-xl !important;
    border: none !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: $Body-Small;
    line-height: 1.25rem;
  }

  #body {
    color: #757575 !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    border: none !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.activity-member-custom-table {
  table {
    width: 18.75rem !important;
    /* 8.75rem * 5 column + 1rem scrollbar width */
    min-width: 18.75rem !important;
    border-spacing: 0;
  }

  thead tr {
    background: #fafafa !important;
    border: 0.031rem solid #d6d6d6 !important;
    color: #757575 !important;
  }

  tbody td {
    color: #757575 !important;
  }

  tbody,
  thead tr {
    display: block;
  }

  tbody {
    height: 12.5rem !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  thead th {
    padding: 0.563rem 0rem 0.563rem $spacing-xl !important;
  }
}

.member-guest-container {
  .scroll-members {
    padding-right: 1.25rem;
    max-height: 100%;
    overflow-y: auto;
  }

  .title-padding {
    padding-bottom: 1.25rem;
    margin-bottom: 0rem;
  }

  @include set-padding(0rem, $spacing-xl, 2.857rem, $spacing-xl);

  .member-holder {
    @include set-padding(1.25rem, 0, 1.25rem, 0);
    border-bottom: 0.063rem solid #eeeeee;

    .member-name-role {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .member-name {
        @include font-style(1rem, $info, 600);
      }

      .member-role {
        @include font-style(0.75rem, $info, 600);
        opacity: 0.6;
        text-transform: capitalize;
      }
    }

    .member-email {
      @include font-style(0.75rem, $info);
      opacity: 0.6;
    }
  }
}

.outcomeError {
  @include field-err;
}

.outcome-error-contractor {
  border: 0.063rem solid #dc3545 !important;
  border-radius: 0.5rem !important;
  height: 40px;
}

.ui.grid > .inputs-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .expenditure-col {
    padding: 0 !important;

    .form-control {
      height: 3.125rem;
    }

    .expenditure-col-div {
      @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);
      display: flex;
      flex-direction: column;

      .inputs-title {
        @include font-style;
        opacity: 0.6;
        margin-bottom: 2.857rem;
      }

      .amount-input {
        .amount-input-prepend {
          width: 3.125rem;
          height: 3.125rem;

          .input-group-text {
            background-color: #ededed !important;
            @include font-style(1rem, $info !important, 600);
          }
        }
      }

      .expenditure-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.857rem;

        .expenditure-input,
        .expenditure-input-err {
          height: 2.857rem !important;

          input {
            padding-left: 3.375rem !important;
            padding-right: 1rem !important;
            background-color: red !important;
          }

          i.icon {
            background-color: #ededed;
            width: 3.125rem;
          }
        }

        .expenditure-input-err {
          border: 0.063rem solid #ff0000; //when error
          border-radius: 0.28571429rem;
        }

        .error-msg-div {
          @include field-err;
        }

        .error-msg-hidden {
          display: none;
        }
      }

      .date-input-row {
        padding-bottom: 3.125rem;

        .start-date,
        .end-date,
        .start-date-err,
        .end-date-err {
          width: 100%;
          font-size: $Body-Small;
          height: 2.857rem !important;

          input {
            font-size: $Body-Small !important;
          }

          i.icon {
            font-size: $Body-Default;
          }
        }

        .start-date-err,
        .end-date-err {
          border: 0.063rem solid #ff0000; //when error
          border-radius: 0.5rem !important;
        }

        .error-msg-div {
          @include field-err;
        }
      }

      .venue-label-div {
        @include display-flex(space-between, center);

        .add-user {
          .customColor,
          .contractor {
            background-color: #ffffff !important;
            @include font-style(1rem !important, $gradientRight !important);
            padding: 0;
            margin-top: 0 !important;
            margin-bottom: 0.438rem;
            display: flex;
            justify-content: flex-end;
          }
        }
      }

      .search-field {
        @include search-field;
        margin-bottom: 3.125rem;
      }
    }

    .div-title {
      @include font-style(1rem, $info, 600);
    }
  }

  @media only screen and (max-width: 767px) {
    .view-contractor-column {
      flex-direction: column !important;
    }
  }

  .view-contractor-column {
    background-color: $orgBackground !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: row;

    .venue-div,
    .contractor-div {
      width: 100%;
      height: 50vh;
      @include set-padding(2.857rem, $spacing-xl, 3.75rem, $spacing-xl);

      .div-title {
        @include font-style(1rem, $info, 600);
        opacity: 0.8;
        margin-bottom: $spacing-lg;
      }

      .contractor-div-scroll {
        max-height: 100%;
        overflow-y: scroll;

        @media only screen and (max-width: 767px) {
          .contractor-card {
            display: flex;
            flex-direction: column;
          }

          .contractor-btn {
            align-self: center;
          }
        }

        .contractor-card {
          @include display-flex(space-between, flex-start);
          padding-bottom: $spacing-xl;

          .cost-div {
            display: flex;
            flex-direction: column;

            .contract-cost-lbl {
              @include font-style(0.75rem, $info);
            }
          }

          .contractor-company {
            @include font-style(1rem, $info, 600);
          }

          .contact-name {
            @include font-style(0.813rem, $info, 400);
          }

          .contractor-btn {
            @include font-style(1rem, $red, 600);
            margin: 0;
            background-color: transparent;
          }

          .org-users-actions-warn {
            @include font-style(1rem, $gradientRight, 600);
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }

    .venue-div {
      height: 50vh;

      @media only screen and (max-width: 767px) {
        .venue-card {
          display: flex;
          flex-direction: column;
        }
      }

      .venue-card {
        @include display-flex(space-between);

        .venue-name {
          @include font-style(1rem, $info, 600);
        }

        .venue-btn {
          @include font-style(1rem, $red, 600);
          margin: 0;
          background-color: transparent;
        }

        .venue-inline {
          display: flex;
          align-items: center;
        }

        .org-users-actions-warn {
          @include font-style(1rem, $gradientRight, 600);
          margin: 0;
          background-color: transparent;
          cursor: pointer;
        }
      }

      .venue-div-scroll {
        max-height: 100%;
        overflow-y: scroll;
      }
    }
  }
}

.volunteer-input {
  height: 2.857rem !important;
}

.default-color {
  color: $gradientRight !important;
  margin-right: $spacing-lg !important;
  cursor: pointer !important;
}

.custom-contractor-company {
  width: 180px !important;
}

.contact-search-results-c {
  .company {
    color: #000 !important;
  }

  .group {
    color: $info;
    opacity: 0.9;
  }
}

.search-wrapper {
  .results {
    max-height: 400px;
    overflow: auto;
  }

  .results.transition.visible {
    width: 100% !important;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.date-input-row {
  .date-padding {
    margin-bottom: 2.188rem !important;
  }

  .start-date > .ui.icon.input {
    width: 100% !important;
  }

  .end-date > .ui.icon.input {
    width: 100% !important;
    height: 2.857rem !important;
  }
}

.input-group-prepend {
  height: 2.857rem !important;
  border-top: 0.031rem solid #c3d3e5 !important;
  border-left: 0.031rem solid #c3d3e5 !important;
  border-bottom: 0.031rem solid #c3d3e5 !important;
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;

  .input-group-text {
    background-color: #ededed !important;
    @include Body-Default($Black);
  }

  i.inverted.circular.grey.icon {
    display: flex;
    background-color: #767676 !important;
    color: #fff !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    align-items: center;
    justify-content: center;
    margin: 0.78rem $spacing-sm 0.78rem $spacing-sm;
  }
}

.group-objective-select {
  margin-left: 26px;
}

.form-check-input {
  position: static;
}

.ui.grid > .outputs-row {
  padding: 0 !important;

  .form-group {
    margin: 0 !important;

    .form-group {
      margin: 0;

      .form-control {
        height: 3.125rem;
      }
    }
  }

  .form-control {
    height: 3.125rem;
  }

  .act-data-checkbox {
    .form-group {
      .form-check {
        display: flex;
        margin-left: 0rem;
        margin-top: 1.8rem;
      }
    }
  }

  .outputs-col {
    padding: 0 !important;

    .outputs-col-div {
      .outcome-input-div-custom {
        display: flex !important;
        flex-direction: row !important;
      }

      .outputs-div-title {
        @include font-style(1rem, $info);
        opacity: 0.6;
        margin: 0;
        margin-bottom: 1.25rem;
      }

      .income-title {
        @include font-style(1rem, $info, 600);
        margin: 0;
      }

      .question-div {
        font-size: $Body-Default;
      }

      .core-participant-book {
        left: 2.76%;
        right: 93.1%;
        top: 58.66%;
        bottom: 38.31%;
        background: #fafafa;
        border: 0.063rem solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 0.25rem;
        width: 2.857rem;
        height: 2.313rem;
        margin-top: 0.75rem;
      }

      .group-book-logo {
        margin-left: 0.5rem;
        margin-top: 0.438rem;
      }

      .core-participant-title {
        @include font-style(1rem, $info, 600);
        margin: 0.313rem;
        padding-bottom: 0.625rem;
        margin-top: 1.438rem;
        margin-left: 1.063rem;
      }

      .group-objective-select {
        margin-top: 1.563rem;
      }

      .core-participant-label-selected {
        @include font-style(1rem, $info, 600);
        color: $info;
        font-family: $fontFamily;
        font-weight: 600;
        line-height: 1.375rem;
      }

      .group-selected-col {
        margin-top: 3.75rem;
        background-color: $selected;

        padding: 0 !important;
      }

      .income-div {
        display: flex;
        flex-direction: column;

        .close-date,
        .close-date-err {
          div {
            width: 57%;
          }

          input {
            height: 3.125rem;
          }

          i.icon {
            font-size: $Body-Default !important;
          }
        }

        .close-date-custom,
        .close-date-custom-err {
          div {
            width: 100% !important;
            border-radius: 0rem 0.5rem 0.5rem 0rem !important;
          }

          input {
            width: fit-content !important;
            height: 2.25rem;
          }

          i.icon {
            font-size: $Body-Default !important;
          }
        }
      }

      .icon-inputs {
        flex-wrap: nowrap !important;

        .form-group {
          width: 50%;
        }
      }

      .icon-inputs-custom {
        .form-group {
          width: 100% !important;
        }
      }

      .withoutIcon-inputs {
        .form-group {
          width: 100%;
        }
      }

      .parent-withoutIcon-inputs {
        .form-group {
          width: 59%;
        }
      }

      .activity-data-div {
        .activity-data-title {
          @include font-style(1rem, $info, 600);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .sessions-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .receptive-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .active-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .total-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }
      }

      .core-participants-div {
        .core-participants {
          @include font-style(1rem, $info, 600);

          margin: 0;
          padding-bottom: 0.625rem;
        }

        .question-div {
          @include font-style(0.75rem, $info);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .core-participant-row {
          display: flex;
          flex-direction: row;
        }

        .radio-form {
          display: flex;
          flex-direction: row;

          .radio-field {
            padding-right: 2.857rem;

            label {
              font-size: $Body-Default;
            }
          }
        }
      }

      .works-created-div {
        .works-created-input {
          height: 3.125rem;
        }
      }
    }
  }

  .outputs-col-participant-group {
    padding: 0 !important;

    .outputs-col-div {
      @include set-padding(0.938rem, $spacing-xl, 0.938rem, $spacing-xl);

      .outputs-div-title {
        @include font-style(1rem, $info);
        opacity: 0.6;
        margin: 0;
        margin-bottom: 1.25rem;
      }

      .income-title {
        @include font-style(1rem, $info, 600);
        margin: 0;
        padding-bottom: 0.625rem;
      }

      .question-div {
        font-size: $Body-Default;
      }

      .core-participant-book {
        left: 2.76%;
        right: 93.1%;
        top: 58.66%;
        bottom: 38.31%;
        background: #fafafa;
        border: 0.063rem solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 0.25rem;
        width: 2.857rem;
        height: 2.313rem;
        margin-top: 0.75rem;
      }

      .group-book-logo {
        margin-left: 0.5rem;
        margin-top: 0.438rem;
      }

      .core-participant-title {
        @include font-style(1rem, $info, 600);
        margin: 0.313rem;
        padding-bottom: 0.625rem;
        margin-top: 1.438rem;
        margin-left: 1.063rem;
      }

      .group-objective-select {
        margin-top: 1.563rem;
      }

      .core-participant-label-selected {
        @include font-style(1rem, $info, 600);
        color: $info;
        font-family: $fontFamily;
        font-weight: 600;
        line-height: 1.375rem;
      }

      .group-selected-col {
        margin-top: 3.75rem;
        background-color: $selected;

        padding: 0 !important;
      }

      .income-div {
        display: flex;
        flex-direction: column;

        .close-date,
        .close-date-err {
          div {
            width: 57%;
          }

          input {
            height: 3.125rem;
          }

          i.icon {
            font-size: $Body-Default !important;
          }
        }
      }

      .icon-inputs {
        .form-group {
          width: 50%;
        }
      }

      .withoutIcon-inputs {
        .form-group {
          width: 100%;
        }
      }

      .parent-withoutIcon-inputs {
        .form-group {
          width: 59%;
        }
      }

      .activity-data-div {
        .activity-data-title {
          @include font-style(1rem, $info, 600);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .sessions-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .receptive-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .active-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .total-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }
      }

      .core-participants-div {
        .core-participants {
          @include font-style(1rem, $info, 600);

          margin: 0;
          padding-bottom: 0.625rem;
        }

        .question-div {
          @include font-style(0.75rem, $info);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .core-participant-row {
          display: flex;
          flex-direction: row;
        }

        .radio-form {
          display: flex;
          flex-direction: row;

          .radio-field {
            padding-right: 2.857rem;

            label {
              font-size: $Body-Default;
            }
          }
        }
      }

      .works-created-div {
        .works-created-input {
          height: 3.125rem;
        }
      }
    }
  }

  .book-logo-wrapper {
    display: flex !important;
    flex-direction: row !important;
  }

  .group-book-logo {
    background: #fafafa;
    border: 0.063rem solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 0.25rem;
    padding: 1.25rem;
  }

  .outputs-col-custom {
    padding: 0 !important;

    .outputs-col-custom-div {
      @include set-padding(0rem 0rem 0rem 0rem);

      .outputs-div-title {
        @include font-style(1rem, $info);
        opacity: 0.6;
        margin: 0;
        margin-bottom: 1.25rem;
      }

      .income-title {
        @include font-style(1rem, $info, 600);
        margin: 0;
        padding-bottom: 0.625rem;
      }

      .question-div {
        font-size: $Body-Default;
      }

      .core-participant-book {
        left: 2.76%;
        right: 93.1%;
        top: 58.66%;
        bottom: 38.31%;
        background: #fafafa;
        border: 0.063rem solid #f0f0f0;
        box-sizing: border-box;
        border-radius: 0.25rem;
        width: 2.857rem;
        height: 2.313rem;
        margin-top: 0.75rem;
      }

      .group-book-logo {
        margin-left: 0.5rem;
        margin-top: 0.438rem;
      }

      .core-participant-title {
        @include font-style(1rem, $info, 600);
        margin: 0.313rem;
        padding-bottom: 0.625rem;
        margin-top: 1.438rem;
        margin-left: 1.063rem;
      }

      .group-objective-select {
        margin-top: 1.563rem;
        margin-left: 0.625rem !important;
      }

      .core-participant-label-selected {
        @include font-style(1rem, $info, 600);
        color: $info;
        font-family: $fontFamily;
        font-weight: 600;
        line-height: 1.375rem;
      }

      .group-selected-col {
        margin-top: 3.75rem;
        background-color: $selected;

        padding: 0 !important;
      }

      .income-div {
        display: flex;
        flex-direction: column;

        .close-date,
        .close-date-err {
          div {
            width: 57%;
          }

          input {
            height: 3.125rem;
          }

          i.icon {
            font-size: $Body-Default !important;
          }
        }
      }

      .icon-inputs {
        .form-group {
          width: 50%;
        }
      }

      .withoutIcon-inputs {
        .form-group {
          width: 100%;
        }
      }

      .parent-withoutIcon-inputs {
        .form-group {
          width: 59%;
        }
      }

      .activity-data-div {
        .activity-data-title {
          @include font-style(1rem, $info, 600);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .sessions-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .receptive-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .active-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }

        .total-participants-div {
          display: flex;
          flex-direction: column;
          padding-bottom: 2.857rem;
        }
      }

      .core-participants-div {
        .core-participants {
          @include font-style(1rem, $info, 600);

          margin: 0;
          padding-bottom: 0.625rem;
        }

        .question-div {
          @include font-style(0.75rem, $info);
          margin: 0;
          padding-bottom: 0.625rem;
        }

        .core-participant-row {
          display: flex;
          flex-direction: row;
        }

        .radio-form {
          display: flex;
          flex-direction: row;

          .radio-field {
            padding-right: 2.857rem;

            label {
              font-size: $Body-Default;
            }
          }
        }
      }

      .works-created-div {
        .works-created-input {
          height: 3.125rem;
        }
      }
    }
  }

  .final-result-col {
    padding: 0 !important;

    .final-result-div {
      @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

      .final-result,
      .final-result-actual {
        display: flex;
        flex-direction: column;
        padding-top: 5.5rem;
      }

      .final-result-actual {
        padding-bottom: 69px;
      }

      .final-result {
        padding-bottom: 104px;
      }

      .total-amt-div {
        display: flex;
        flex-direction: column;

        .total-amt-input {
          height: 3.125rem;

          input {
            padding-left: 3.375rem !important;
            padding-right: 1rem !important;
          }

          i.icon {
            background-color: #ededed;
            width: 3.125rem;
          }
        }
      }

      .fee-div {
        padding-top: 3.375rem;
        display: flex;
        flex-direction: column;
      }

      .receptive-fee-div {
        padding-top: 38px;
        display: flex;
        padding-bottom: 184px;
        flex-direction: column;
      }

      .obj-div {
        .radio-form {
          display: flex;
          flex-direction: row;

          .radio-field {
            padding-right: 2.857rem;

            label {
              font-size: $Body-Default;
            }
          }
        }
      }
    }
  }
}

.search-icon-custom {
  border-left: 0.063rem solid #e0e0e0 !important;
}

.icon-inputs-custom {
  position: relative !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
  width: 100% !important;

  .form-group {
    width: 50% !important;
  }
}

.icon-inputs-style-custom {
  position: relative !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
  width: 102% !important;

  .form-group {
    width: 50% !important;
  }
}

.income-title-custom {
  margin-top: $spacing-lg !important;
}

.hr-tag {
  border: 0.031rem solid #e0e0e0 !important;
}

.outcome-input-div-custom {
  display: flex !important;
  flex-direction: row !important;
}

.book-logo-wrapper-custom {
  display: flex !important;
  flex-direction: row !important;
}

.core-participant-book-custom-group {
  background: #fafafa;
  border: 0.063rem solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: 2.857rem;
  height: 2.313rem;
  margin-top: 0.75rem;
  margin-left: 1.875rem !important;
}

.outputs-col-custom-group-participant {
  padding: 0 !important;

  .outputs-col-custom-div {
    @include set-padding(0rem 0rem 0rem 0rem);

    .outputs-div-title {
      @include font-style(1rem, $info);
      opacity: 0.6;
      margin: 0;
      margin-bottom: 1.25rem;
    }

    .income-title {
      @include font-style(1rem, $info, 600);
      margin: 0;
      padding-bottom: 0.625rem;
    }

    .question-div {
      font-size: $Body-Default;
    }

    .core-participant-book {
      left: 2.76%;
      right: 93.1%;
      top: 58.66%;
      bottom: 38.31%;
      background: #fafafa;
      border: 0.063rem solid #f0f0f0;
      box-sizing: border-box;
      border-radius: 0.25rem;
      width: 2.857rem;
      height: 2.313rem;
      margin-top: 0.75rem;
    }

    .group-book-logo {
      margin-left: 0.5rem;
      margin-top: 0.438rem;
    }

    .core-participant-title {
      @include font-style(1rem, $info, 600);
      margin: 0.313rem;
      padding-bottom: 0.625rem;
      margin-top: 1.438rem;
      margin-left: 1.063rem;
    }

    .group-objective-select {
      margin-top: 1.563rem;
      margin-left: 0.625rem !important;
    }

    .core-participant-label-selected {
      @include font-style(1rem, $info, 600);
      color: $info;
      font-family: $fontFamily;
      font-weight: 600;
      line-height: 1.375rem;
    }

    .group-selected-col {
      margin-top: 3.75rem;
      background-color: $selected;

      padding: 0 !important;
    }

    .income-div {
      display: flex;
      flex-direction: column;

      .close-date,
      .close-date-err {
        div {
          width: 57%;
        }

        input {
          height: 3.125rem;
        }

        i.icon {
          font-size: $Body-Default !important;
        }
      }
    }

    .icon-inputs {
      .form-group {
        width: 50%;
      }
    }

    .withoutIcon-inputs {
      .form-group {
        width: 100%;
      }
    }

    .parent-withoutIcon-inputs {
      .form-group {
        width: 59%;
      }
    }

    .activity-data-div {
      .activity-data-title {
        @include font-style(1rem, $info, 600);
        margin: 0;
        padding-bottom: 0.625rem;
      }

      .sessions-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.857rem;
      }

      .receptive-participants-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.857rem;
      }

      .active-participants-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.857rem;
      }

      .total-participants-div {
        display: flex;
        flex-direction: column;
        padding-bottom: 2.857rem;
      }
    }

    .core-participants-div {
      .core-participants {
        @include font-style(1rem, $info, 600);

        margin: 0;
        padding-bottom: 0.625rem;
      }

      .question-div {
        @include font-style(0.75rem, $info);
        margin: 0;
        padding-bottom: 0.625rem;
      }

      .core-participant-row {
        display: flex;
        flex-direction: row;
      }

      .radio-form {
        display: flex;
        flex-direction: row;

        .radio-field {
          padding-right: 2.857rem;

          label {
            font-size: $Body-Default;
          }
        }
      }
    }

    .works-created-div {
      .works-created-input {
        height: 3.125rem;
      }
    }
  }
}

.outputs-wrapper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-bottom: 1.563rem !important;
  margin-right: 30% !important;
}

.outputs-wrapper-custom {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 1.563rem !important;
  position: relative !important;
  flex-wrap: wrap !important;
  justify-content: inherit !important;
  align-items: stretch !important;
  width: 100% !important;
  padding: 0;
  padding-top: 0 !important;
}

.date-picker-btn-custom {
  height: 45.69px !important;
  width: 100% !important;
  background: #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 0.5rem !important;
}

.aiming-outcomes-text {
  margin-top: $spacing-lg !important;
  margin-bottom: $spacing-xl !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Default;
  line-height: 1.5rem;
  color: #000000;
}

.ui.grid {
  .goal-group {
    margin-top: 0rem;
  }

  .evaluation-seg {
    padding: 0 !important;

    .evaluation-div {
      @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

      .primary-obj {
        margin: 0;
        padding-bottom: 0.625rem;
      }

      .diversity-label {
        @include font-style(1rem !important);
        margin: 0;
        padding-bottom: 2.857rem;
      }

      .evaluation-method-lbl {
        margin: 0;
        padding-bottom: 0.5rem;
      }

      .evaluation-method-radio {
        label {
          font-size: $Body-Small;
        }
      }

      .radio-form-eval {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .field {
          padding-right: 3.75rem;
          margin: 0;

          label {
            @include font-style(1rem);
          }
        }
      }

      .radio-holder {
        padding-bottom: 2.857rem;
      }

      .question-div {
        width: 50%;
        margin-bottom: 1.563rem;

        .question-lbl {
          cursor: pointer;
          @include font-style(1rem, $gradientRight, 600);
          @include display-flex(flex-start, center);

          i.icon {
            font-size: $h6;
          }
        }
      }

      .show-q {
        background-color: #f5f5f5;
        padding: 1rem 1.125rem;
        margin-bottom: 2.857rem;
      }

      .hide-q {
        display: none;
      }

      .divider {
        margin: 2.125rem 0;
      }

      .edit-show-q {
        margin-left: 98%;
        cursor: pointer;
      }
    }
  }
}

.custom-icon-input {
  margin-top: 0.188rem !important;
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  background-color: transparent !important;
  border-left: none !important;
}

.expenditure-input-custom {
  width: 87% !important;
  height: 2.857rem !important;
}

.outer-inputgroup-custom {
  display: flex !important;
  flex-direction: row !important;
}

.group-book-logo-custom {
  margin-left: 0.5rem;
  margin-top: 0.438rem;
}

.core-participant-title-custom {
  @include font-style(1rem, $info, 600);
  margin: 0.313rem;
  padding-bottom: 0.625rem;
  margin-top: 1.438rem;
  margin-left: 1.063rem;
}

.participant-title-custom {
  margin-left: 26px !important;
  margin-right: 2.813rem !important;
}

.evaluation-notes-lbl {
  @include font-style(0.75rem, $gradientRight, 400);
  line-height: 1.063rem !important;
  font-family: $fontFamily !important;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.eval-mtd-div {
  display: flex;
  flex-direction: column;
  width: 66%;

  .desc-div {
    display: flex;
    flex-direction: column;

    .description {
      font-size: $Body-Default;
    }

    .sample-text {
      @include Body-Default-Semibold;
    }
  }
}

.ui.evaluation-response-seg {
  padding: 0 !important;

  .evaluation-response-div {
    padding: 2.857rem $spacing-xl;

    .title-div {
      display: flex;
      flex-direction: column;
      padding-bottom: 2.857rem;

      .title-sub {
        @include font-style(1rem);
      }
    }

    .divider-line {
      margin-top: 0;
      margin-bottom: 2.857rem;
    }

    .sample-size-cal {
      display: flex;
      flex-direction: column;
      padding-top: 2.857rem;

      .sample-size-cal-btn {
        width: 12.5rem;
        background-color: $gradientRight;
        @include font-style(1rem, $white, bold);
      }

      .sample-sub {
        font-size: $Body-Default;
        margin-bottom: 1rem;
      }
    }
  }
}

.ui.grid .collection-seg {
  background: $white;

  .evaluation-div {
    padding: 2.125rem $spacing-xl;
  }

  .radio-holder {
    padding-left: $spacing-xl;
  }
}

.ui.approval-seg {
  padding: 0 !important;

  .approval-div {
    background: $white;

    .code-div {
      display: flex;
      flex-direction: column;

      .code-field {
        height: 2.857rem !important;
        border-radius: 0.5rem !important;
      }
    }

    .paragraph {
      margin-bottom: 1rem;
      @include font-style(1rem);
    }

    .check-box {
      label {
        @include font-style(1rem);
      }
    }

    .paragraph-div {
      padding-top: $spacing-lg;

      .pending-lbl {
        @include font-style(1rem, $gradientLeft, bold);
      }
    }

    .approved-content-div {
      display: flex;
      flex-direction: column;

      .approve-lbl {
        margin-left: $spacing-xl;
        margin-top: $spacing-lg;
        font-size: $Body-Default;
      }

      .approve-btn {
        width: 9.375rem;
        background-color: $gradientRight;
        @include font-style($spacing-sm, $white);
      }
    }
  }
}

.greenlight-standard-label-sixteen-px {
  font-size: $Body-Default !important;
}

.approved-bg {
  background: #e8f5e9;
  border: 0.031rem solid #d6d6d6;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  //height: 3.375rem;
  width: 100%;
}

.approved-content-div-documentation {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  padding: $spacing-md $spacing-xl $spacing-md $spacing-xl;

  .approval-active-data {
    flex: 1;
    margin: 0;
    padding: 0;

    label {
      @include Body-Default-Semibold(#2e7d32);
      color: #2e7d32 !important;
      margin: 0rem;
    }
  }

  .approval-active-data-1 {
    margin: 0;
    padding: 0;

    label {
      font-family: Inter !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: $Body-Default !important;
      line-height: 1.5rem !important;
      letter-spacing: -0.063rem !important;
      color: #2e7d32 !important;
    }
  }
}

.outcome-title-ul-custom {
  margin: 0rem 0rem 0rem 0.938rem !important;
  padding: 0rem 0rem 0rem 0rem !important;
  text-align: left;
}

.outer-select-objective-text {
  margin-bottom: 0.313rem !important;
}

.select-objective-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 143% */

  color: #000000;
}

.ui.survey-seg {
  padding: 0 !important;

  .survey-div {
    padding: 1.25rem $spacing-xl;

    .link-row {
      .normal-card {
        padding-top: 1.25rem;
      }

      .greenlight-evaluation-plan-objective {
        padding-top: 0rem !important;
        padding-left: 1rem !important;
      }

      .url-card {
        padding-top: 2.857rem;
        word-wrap: normal;
        padding-left: 1rem;

        .goal-div,
        .obj-div,
        .link-div {
          padding: 0;
          display: flex;
          flex-direction: column;
        }

        .link-div {
          margin-top: 0.625rem;
        }
      }

      .url-card-links {
        word-wrap: normal;
        padding-left: 2.125rem;

        .goal-div,
        .obj-div,
        .link-div {
          padding: 0;
          display: flex;
          flex-direction: column;
        }

        .link-div {
          margin-top: 0.625rem;
        }
      }

      .url-card-first {
        word-wrap: normal;
        padding-left: 1.125rem;

        .goal-div,
        .obj-div,
        .link-div {
          padding: 0;
          display: flex;
          flex-direction: column;
        }

        .link-div {
          margin-top: 0.625rem;
        }
      }

      .question-and-objective-card {
        padding-top: 1.25rem;
        word-wrap: normal;
        padding-left: 1rem;
        margin-bottom: 1.875rem !important;
        margin-right: 180px !important;
        margin-left: 1.125rem !important;
      }

      .url-link {
        margin-top: $spacing-xl;
        padding: 0.625rem 1rem;
        background-color: #efefef;

        a {
          @include font-style($Body-Small, $info, 600);
        }
      }
    }
    .eval-method-radio {
      .form-check-input {
        position: absolute;
      }
    }
  }
}

.refer-target-guideline-row {
  padding-left: 2.125rem !important;
  padding-right: 230px !important;
  padding-bottom: 1.25rem !important;
}

.projected-info-text {
  font-size: $Body-Default !important;
  font-weight: 400 !important;
}

.results-row {
  margin-bottom: 12.5rem !important;
}

.bold-text {
  padding-left: 0rem !important;
  font-weight: 600 !important;
  color: black !important;
}

.ui.document-media-seg {
  padding: 0 !important;

  .document-media-div {
    padding: $spacing-lg $spacing-xl !important;

    p {
      margin-bottom: $spacing-lg;
    }

    .upload-btn {
      background-color: $gradientRight;
      margin-bottom: $spacing-xl;
      @include font-style(1rem, $white, 600);
    }

    .info-bar {
      display: flex;
      flex-direction: column;

      .info-title {
        @include Body-Small($Text-Secondary);
      }

      .desc-row {
        border-bottom: 0.063rem solid #eeeeee;

        .doc-desc-col {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          width: 50%;

          div {
            padding-right: 3.75rem;
          }

          .desc-title {
            font-weight: 600;
          }
        }

        .controls-div {
          display: flex;
          flex-direction: row;

          div {
            padding-right: 2.857rem;
          }

          .edit-div {
            @include font-style($spacing-sm, $gradientLeft, 600);
          }

          .view-div {
            @include font-style($spacing-sm, $success, 600);
          }

          .delete-div {
            @include font-style($spacing-sm, $red, 600);
          }
        }
      }
    }
  }
}

.ui.cultural-eval-seg {
  padding: 0 !important;

  .cultural-eval-div {
    padding: 2.857rem $spacing-xl;
    display: flex;
    flex-direction: column;

    .cultural-eval-title {
      @include font-style(1rem !important, $info !important);
      opacity: 0.6;
      margin: 0;
      padding-bottom: 1.563rem;
    }

    .date-div {
      display: flex;
      flex-direction: column;
      padding-bottom: 2.857rem;

      .date-input {
        height: 3.125rem;

        input {
          font-size: $Body-Small !important;
        }

        i.icon {
          font-size: $Body-Default;
        }
      }
    }

    .radio-container {
      margin-bottom: 2.857rem;

      .ui.radio.checkbox {
        label {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Small;
        }
      }

      .ui.radio.checkbox input:focus:checked ~ .box:before,
      .ui.radio.checkbox input:focus:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:before,
      .ui.radio.checkbox input:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:focus:checked ~ .box:after,
      .ui.radio.checkbox input:focus:checked ~ label:after {
        background-color: $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:after,
      .ui.radio.checkbox input:checked ~ label:after {
        background-color: $gradientRight;
      }
    }

    .radio-label {
      @include Body-Small($info);
    }

    .radio-btn {
      margin: 0.5em 2.857rem 0.5em 0;
    }

    .participants-div {
      display: flex;
      flex-direction: column;
      width: 50%;

      .participants-top {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: $Body-Small;
        line-height: 1.25rem;
        /* identical to box height, or 143% */

        color: #000000;
      }

      .participants-input {
        height: 3.125rem;
        min-width: 235px;
      }

      .participants-disabled {
        input {
          background: #eaecef;
        }
      }

      .participants-bottom {
        @include font-style(0.75rem, $info, 100, italic);
        opacity: 0.8;
      }
    }

    .participants-type {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.857rem;

      .info-title {
        @include font-style(0.75rem);
      }

      .info-desc {
        @include font-style($spacing-sm);
      }
    }

    .evaluation-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.857rem;

      .info-title {
        @include font-style(0.75rem);
      }

      .info-desc {
        @include font-style($spacing-sm);
      }
    }
  }
}

.checkbox-outer-custom {
  display: flex !important;
  flex-direction: row !important;
  margin-right: 1.875rem !important;
}

.checkbox-outer-main-custom {
  display: flex !important;
  flex-direction: row !important;
}

.checkbox-text {
  margin-top: 0.375rem !important;
  margin-left: -1.25rem !important;
}

.participants-bottom-custom {
  /* identical to box height, or 1.125rem */
  @include Body-Small(#8c8c8c);
}

.ui.document-note-seg {
  padding: 0 !important;

  .document-note-div {
    padding: 1.25rem $spacing-xl;

    @media (max-width: 48rem) {
      width: 100%;
    }

    .notes-title {
      @include font-style(1rem !important);
      opacity: 0.6;
      padding-bottom: 0.625rem;
    }

    .text-desc {
      display: flex;
      flex-direction: column;
      padding-bottom: 2.857rem;

      .text-hint {
        @include font-style(0.75rem, $info, 100, italic);
        opacity: 0.8;
      }
    }

    div {
      color: black;
    }
  }
}

.evidence-search > div {
  .item {
    width: 100% !important;
    background-color: white !important;
  }

  .item:hover {
    background-color: $orgBackground !important;
  }
}

.theory-of-change-report-objective {
  margin-top: 0.5rem !important;
  font-size: $Body-Small !important;
}

.ui.eval-que-seg {
  padding: 0 !important;
  max-width: -webkit-fill-available;

  .eval-que-div {
    .question-div {
      padding: $spacing-lg $spacing-lg $spacing-sm $spacing-lg !important;

      .question-lbl {
        cursor: pointer;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: $Body-Small;
        line-height: 1.25rem;
        color: #ef8123;
      }
    }

    .participant-res {
      margin-left: $spacing-lg;
      margin-top: $spacing-lg;
      .participant-lbl {
        @include font-style(1rem, $info, 600);
      }

      .upload-btn {
        background-color: $gradientRight;
        margin-bottom: $spacing-xl;
        @include font-style(1rem, $white, 600);
      }

      .upload-anchor {
        text-decoration: none !important;
        color: white !important;
      }
    }

    .response-table-div {
      max-width: 80%;
      padding-left: 1.375rem;

      .response-table {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: none !important;

        thead {
          tr {
            th {
              background-color: #fafafa;
              text-align: center;
              @include font-style(0.75rem, $info);
              border-top: 0;
              border-left: 0.188rem solid #ffffff;
              border-right: 0.188rem solid #ffffff;
              border-bottom: none !important;
              border-top-right-radius: 0.25rem !important;
              border-top-left-radius: 0.25rem !important;
              min-width: 6rem !important;
              padding: 0.5rem !important;
              font-size: $Body-Small !important;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 0rem 0.75rem 0.5rem 0.75rem !important;
              background-color: #fafafa;
              @include font-style(0.75rem, $info);
              border-left: 0.188rem solid #ffffff;
              border-right: 0.188rem solid #ffffff;
              border-bottom: none !important;
              border-bottom-right-radius: 0.25rem !important;
              border-bottom-left-radius: 0.25rem !important;
              text-align: center !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }

    .graph-div {
      .title-lbl {
        font-family: Inter !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: $Body-Small !important;
        line-height: 1.25rem !important;

        text-align: center !important;
        letter-spacing: 0.125rem !important;

        color: #000000 !important;
      }

      .line-chart {
        margin-top: $spacing-lg;
        padding: 1.25rem 1.25rem;
        border: 0.063rem solid #e7e7e7;
      }

      .pre-post-chart-div {
        display: block !important;
        border: 0rem !important;
        border-radius: 0rem !important;
        padding: 0rem !important;
      }

      .graph-tab > div > .item {
        width: 16em !important;
      }

      .graph-tab > div > .item.active {
        border-bottom-color: $gradientRight !important;
      }

      .left-align {
        margin-left: -1.375rem !important;
      }
      .left-align-sm {
        margin-left: -1.375rem !important;
      }
    }
  }
}

.left-align-single {
  margin-left: -1.375rem !important;
}

.react-semantic-custom-table-venue {
  overflow-x: auto;
  border-radius: 0rem !important;
  border-left: none !important;
  border-right: none !important;

  tbody {
    display: block;
    height: 18.75rem;
    overflow: auto;
    border: none !important;
  }

  #header {
    background: #fafafa !important;
    color: #757575 !important;
    padding-left: $spacing-xl !important;
    border: none !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: $Body-Small;
    line-height: 1.25rem;
  }

  #body {
    color: #757575 !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    border: none !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.react-semantic-custom-table-groups {
  overflow-x: auto;
  border-radius: 0rem !important;
  border-left: none !important;
  border-right: none !important;

  tbody {
    display: block;
    height: 11.25rem;
    overflow: auto;
    border: none !important;
  }

  #header {
    background: #fafafa !important;
    color: #757575 !important;
    padding-left: $spacing-xl !important;
    border: none !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: $Body-Small;
    line-height: 1.25rem;
  }

  #body {
    color: #757575 !important;
    border-bottom: 0.313rem solid #d6d6d6 !important;
    border: none !important;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.link-button-document {
  margin: 0rem 1.25rem 0rem 0;
}

.copy-link-warn {
  cursor: pointer;
  color: $gradientRight !important;
}

.file-type-custom {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */

  color: #000000;
}

.ui.report-activity-seg {
  padding: 0 !important;

  .row {
    padding: 0rem 0rem !important;
  }

  .report-activity-div {
    .info-theory-parent {
      margin-bottom: 1.25rem;
      font-style: $fontFamily;
      font-size: $Body-Default;
      text-align: justify;
    }

    .info-desc {
      @include Body-Default;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      white-space: pre-line;
    }

    .info-gap {
      margin-top: 1.25rem !important;
    }

    .info-pad {
      padding: 0.938rem 1.25rem !important;
    }

    .info-desc-last {
      padding-bottom: $spacing-md;
      margin: 0;
      font-style: normal;
      font-weight: 400 !important;
      font-size: $Body-Small;
      line-height: 1.5rem;
    }

    .info-desc-column {
      padding-bottom: 2.857rem !important;
      margin: 0 !important;
    }
  }

  .report-activity-single-div {
    padding-top: $h4;

    .info-title {
      @include font-style($spacing-sm);
      color: #757575 !important;
    }

    .info-theory-parent {
      margin-bottom: 1.25rem;
      font-style: $fontFamily;
      font-size: $Body-Default;
      text-align: justify;
    }

    .info-desc {
      @include font-style($spacing-sm);
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      white-space: pre-line;
    }

    .info-gap {
      margin-top: 1.25rem !important;
    }

    .info-pad {
      padding: 0.938rem 1.25rem !important;
    }

    .info-desc-last {
      padding-bottom: 0.188rem;
      margin: 0;
      font-style: normal;
      font-weight: 400 !important;
      font-size: $Body-Small;
      line-height: 1.5rem;
    }

    .info-desc-column {
      padding-bottom: 2.857rem !important;
      margin: 0 !important;
    }

    .activity-name-row {
      padding: 0.938rem 0 !important;
    }
  }
}

.chart-column {
  margin-left: -0.313rem !important;
  padding: 0rem !important;
}

.method-type-self-assessed {
  padding-top: 1.125rem !important;
  margin-left: -0.313rem !important;
  padding-left: 0rem !important;
}

@media only screen and (max-width: 48rem) {
  .report-activity-div {
    padding: 0.625rem 0.625rem !important;
  }
}

∂ .activity-modal {
  .browse-input {
    width: 100%;
    max-width: 100%;
  }

  .text-muted {
    @include Body-Small($Text-Secondary);
    margin: -2.188rem;
    display: none;
  }
}

.modal-date-input {
  width: 100%;
}

.activity-users-mainText {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  line-height: 1.375rem !important;
  font-weight: 300;
  margin: 0;
}

.activity-users-mainText-custom {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 143% */

  color: #000000 !important;
}

.media-cancel-button-custom {
  background: transparent !important;
  border: 0.063rem solid #ef8123 !important;
  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  width: 6rem !important;
  height: 2.857rem !important;
  margin-right: 1.563rem !important;
}

.media-cancel-button-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 143% */
  text-align: center !important;
  color: #ef8123 !important;
}

.core-cancel-button-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1rem !important;
  /* identical to box height, or 143% */
  text-align: center !important;
  color: #ef8123 !important;
}

.media-upload-button-custom {
  background: #ef8123 !important;
  border: 0.063rem solid #ef8123 !important;
  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  width: 6rem !important;
  height: 2.857rem !important;
  color: white !important;
}

.media-upload-button-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 143% */
  text-align: center !important;
  color: white !important;
}

.core-upload-button-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.125rem !important;
  /* identical to box height, or 143% */
  text-align: center !important;
  color: white !important;
}

.activity-users-text {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 300;
  line-height: 1.375rem !important;
}

.activity-users-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.document-description {
  font-size: $Body-Default !important;
  /* or 167% */

  /* Color Usage/Text Secondary */

  color: #757575 !important;
}

.download-button:hover {
  text-decoration: none;
}

.projected-activity-modal-margins {
  margin-top: 0.938rem !important;
  margin-bottom: 0.938rem !important;
}

.report-checklist {
  padding: 2.188rem !important;

  .checklist-row {
    padding: 0.5rem 0rem !important;
  }

  .check-list {
    padding-top: 1.875rem;

    h2 {
      margin-bottom: 1.563rem !important;
    }
  }

  .info-desc {
    @include font-style($spacing-sm);
    display: flex;
    align-items: center;
    white-space: pre-line;

    .circular {
      margin-right: 0.625rem;
    }
  }
}

.c-h1 {
  font-size: $h6;
  width: 100%;
  font-family: $fontFamily;
  font-weight: 100;
}

.c-h2 {
  font-size: $Body-Default;
  color: $info;
  margin: 0.625rem 0;
  margin-top: 1.563rem;
  width: 100%;
  font-weight: 600;
  font-family: $fontFamily;
  margin-bottom: $spacing-xl;
}

.c-h3 {
  width: 100%;
  @include Body-Default($Text-Secondary);
  margin-bottom: 0 !important;
}

.c-h4 {
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0rem;
  text-align: left;
  margin-bottom: 2.857rem;
}

.green-qs {
  margin-top: 1.25rem;
  margin-left: 0.688rem;
  cursor: pointer;
}

.show-color {
  font-size: $Body-Default;
  color: $gradientRight;
  margin: 0rem 0;
  padding-left: 1.188rem !important;
  width: 100%;
}

.grey-content {
  background-color: $selected;
}

.c-content {
  font-style: normal;
  font-size: $Body-Small;
  line-height: 1.5rem;
  font-family: $fontFamily !important;
  text-align: justify !important;
  margin-bottom: 0.625rem;
}

.c-content-eval {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin: 0.5rem 0 !important;
  text-align: justify !important;
  @include Body-Default;

  span {
    margin-bottom: 0.313rem;
  }
}

.c-content-eval-bold {
  width: 100% !important;
  font-size: $Body-Default !important;
  line-height: 1.25rem !important;
  color: $info !important;
  margin: 0.5rem 0 !important;
  font-weight: 300 !important;
  font-family: $fontFamily !important;
  text-align: justify !important;
}

.link-title {
  font-size: $Body-Default;
  color: $info;
  margin: 0.188rem 0;
  text-align: justify;
}

.copy-link-btn {
  font-family: $fontFamily !important;
  background-color: $gradientRight !important;
  color: $white !important;
  font-weight: 400 !important;
}

.download-survey-btn {
  margin-left: 21px !important;
  font-family: $fontFamily !important;
  background-color: $gradientRight !important;
  color: $white !important;
  font-weight: 400 !important;
}

.url-link-container {
  margin-top: 1.25rem;

  a {
    font-size: $Body-Small;
  }

  .link-button {
    margin: 1.25rem 0 1.25rem 0;
  }
}

.error-text {
  color: red !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 20px;
}

.core-participant-reveal {
  margin-top: 1.25rem;
  padding: 0.625rem 1.875rem;
}

.participant-col {
  margin-top: 1.375rem;
}

.participant-group-div-custom {
  box-shadow: none;
  margin: 0rem 0.625rem 0rem 0.625rem !important;
}

.avfield-reason {
  height: 72px !important;
  border-radius: 0.5rem !important;
  font-size: $Body-Small !important;
}

.avfield-name {
  width: 319px !important;
  border-radius: 0.5rem !important;
  font-size: $Body-Small !important;
}

.modal-grouping {
  width: 562px !important;
}

.avfield-participants {
  border-radius: 0.5rem !important;
  font-size: $Body-Small !important;
}

.avfield-wrap {
  display: flex !important;
  flex-direction: row !important;
}

.participant-buttonGroup-div {
  margin-top: 1.25rem;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}

.ui-message {
  background-color: red;
}

.participant-group-discard {
  margin-right: 0.625rem;
}

.participant-group-discard-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  border: 0.063rem solid $gradientRight !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  height: 2.857rem !important;
  border-radius: 0.25rem !important;
  text-align: center;
  width: 8.75rem !important;
}

.participant-group-discard-button:hover {
  background-color: $gradientRight !important;
  color: $white !important;
}

.core-participant-selected {
  background-color: $selected;
  box-shadow: none;
}

.core-participant-selected-groups {
  margin-top: 0.5rem;
}

.export-dropdown-btn {
  .ui.dropdown {
    display: flex;
    align-items: center !important;
  }

  .icon.button {
    padding-left: 4em !important;
    padding-right: 4em !important;
  }

  .labeled.button.Primary-Button.export {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    float: right;
  }

  .Primary-Button {
    float: right;
  }

  .menu.transition.left.visible {
    margin-right: -1.8rem;
    margin-top: 0.938rem;
  }

  i.angle.down.icon {
    padding-left: 0.625rem;
  }
}

.show-q {
  background-color: #fafafa;
  padding: 1rem $spacing-lg;
  width: 100%;
  border: 0.063rem solid #eeeeee;
  box-sizing: border-box;
  border-radius: 0.25rem;
}

.new-show-q {
  padding: 1rem $spacing-lg;
  margin-bottom: 0.625rem;
  margin-left: 1.8rem !important;
  margin-right: 0.625rem;
  background: #fafafa;
  border: 0.063rem solid #eeeeee;
  box-sizing: border-box;
  border-radius: 0.25rem;
}

.border-sep {
  border: 0.063rem solid #e0e0e0;
}

.show-qs {
  padding: 0rem 2.286rem;

  .rating-qs {
    margin: 1.125rem 0 !important;
  }
}

.show-evaluation-questions {
  background-color: #fafafa !important;
  border-radius: 0.25rem !important;
  padding: 1rem $spacing-lg;
  width: 100%;
}

.show-facilitiator-notes {
  background-color: #fafafa !important;
  border-radius: 0.25rem !important;
  padding: 1rem $spacing-lg;
  width: 100%;
}

.editBtn {
  display: flex;
  justify-content: flex-end;
  padding: 0.313rem;
  cursor: pointer;
}

.custom-width {
  width: 440px;
}

.add-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding-bottom: 0.625rem;

  .add-more-ques {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: #ef8123;
    padding-left: 0.313rem !important;
  }

  .add-text {
    margin-left: 0.625rem;
  }
}

.add-icon-activity-add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .add-text {
    margin-left: 0.5rem;
  }
}

.editBtnCustom {
  float: right;
  margin-right: 0.938rem;
  cursor: pointer;
  color: $gradientRight;
}

.wrap-edit_btn {
  background: #ffffff;
  box-shadow: 0rem 0.125rem 0.25rem rgba(184, 184, 184, 0.5);
  border-radius: 0.25rem;
}

.disabled-ques {
  opacity: 0.9 !important;
  color: rgba(40, 40, 40, 0.9) !important;
  cursor: not-allowed !important;
}

.facilitator-div {
  padding-bottom: 1.25rem;
}

.show-q-content {
  font-size: $Body-Small;
  color: $info;
  margin: 0.5rem 0;
  font-weight: 300;
  font-family: $fontFamily;
  text-align: justify;
}

.hide-q {
  display: none;
}

.ui.dropdown .menu > .item {
  width: 100% !important;
}

.form-check.disabled {
  opacity: 0.9 !important;
  color: rgba(40, 40, 40, 0.9) !important;
  cursor: not-allowed !important;

  a,
  i,
  input,
  textarea,
  label,
  span,
  select,
  button,
  table,
  div,
  .ui.checkbox,
  .date-holder,
  .eval-check {
    opacity: 0.9 !important;
    pointer-events: none !important;
  }

  input[type='checkbox']:checked:after {
    -webkit-box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    -moz-box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    background: $info;
  }

  input[type='checkbox']:after {
    -webkit-box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    -moz-box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    box-shadow:
      inset 0 0.063rem 0.063rem $white,
      0 0.063rem 0 $white;
    background: $info;
  }
}

.eval-check {
  width: 68.75rem;
  .checkbox-padding {
    padding: 0.286rem 0rem;
  }
  .row {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding: 0.063rem 0rem 0.313rem 0rem !important;
    margin-top: 0.813rem !important;
    padding-top: 0 !important;
    padding-bottom: 1rem;
  }
}

.objectives-checkbox {
  .form-check.disabled {
    input[type='checkbox']:checked:after {
      background: $complete !important;
    }

    input[type='checkbox']:after {
      background: $complete !important;
    }
  }
}

// add here class, tag or id need to be disable
.ui.disabled.segment {
  opacity: 0.9 !important;
  color: rgba(40, 40, 40, 0.9) !important;
  cursor: not-allowed !important;

  a,
  i,
  input,
  textarea,
  label,
  span,
  select,
  button,
  table,
  div,
  .ui.checkbox,
  .date-holder,
  .eval-check {
    pointer-events: none !important;
  }
}

.afterBerforeHeading {
  color: $info;
  font-family: $fontFamily;
  padding: $spacing-sm;
  margin-bottom: 0rem !important;
}

.permission-edit-button {
  width: 173px !important;
  height: 2.188rem !important;
  border-radius: 0.25rem !important;
  background-color: $gradientRight !important;
  margin: 0.313rem 0rem 0rem 1.063rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff !important;
}

.evidence-search-result {
  color: $info;
  font-family: $fontFamily;
  display: flex;
  flex-direction: column;

  .info {
    opacity: 0.9;
  }

  .ui.search > .results {
    display: none !important;
  }

  .evidence-date {
    margin-bottom: 0rem !important;
  }
}

.contact-search-result {
  color: $info;
  font-family: $fontFamily;
  display: flex;
  flex-direction: row;

  .info {
    opacity: 0.7;
    margin-left: 0.188rem;
  }

  .sub-info {
    opacity: 0.9;
  }

  .leftInfo {
    margin-left: 0.938rem;
  }
}

.search-fl {
  display: flex;
}

.guest-searchbar {
  margin-top: -10px;
}

.org-search-partner {
  margin-top: 30px;
}

.desc-error {
  margin-top: -20px;
}

.partner-relationship {
  margin-top: -20px;
  margin-bottom: 10px;
}

.partner-description {
  margin-top: 0px;
}

.anonymous-row {
  margin-top: -30px;
  margin-bottom: -20px;
}

.existing-user-partner {
  margin-top: -14px;
}

.existing-user-partner-pr {
  margin-top: -19px;
}

.existing-user-partner-middle {
  margin-top: -26px;
}

.contactSearch {
  .results {
    max-height: 17.5rem;
    overflow: auto;
  }

  .results.transition.visible {
    width: 430px !important;
    max-height: 17.5rem;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ui.search > .results .result {
      padding: 0.625rem 0rem 0rem 11.25rem !important;
    }
  }
}

.icon-button-partner {
  .ui.labeled.icon.button,
  .ui.labeled.icon.buttons .button {
    padding-left: 2.4rem !important;
    padding-right: 1rem !important;
  }

  .ui.labeled.icon.button > .icon,
  .ui.labeled.icon.buttons > .button > .icon {
    background-color: transparent !important;
  }
}

.search-button-contact {
  @include btn;
  color: $Text-Secondary !important;
  height: 2.857rem !important;
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}

.hideContractorSearch {
  .results {
    display: none !important;
  }

  .results.transition.visible {
    display: none !important;
  }
}

.eval-self-segment {
  .ui.segment {
    padding: 0rem !important;
  }

  .show-q .ui.segment {
    background: #fafafa;
  }
}

.eval-survey-loading-container {
  display: flex;
  justify-content: center;
}

.eval-method-title {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
}

.eval-method-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.938rem;

  .eval-method-header {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    color: #000000 !important;
    padding-bottom: 1.125rem !important;
  }

  .grid {
    .eval-method-radio {
      display: flex;
      align-items: center;
      padding-bottom: 16px;

      .eval-method-radio-text {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Small !important;
        line-height: 1.375rem !important;
        padding-left: 0.625rem !important;
        padding-top: 0.188rem !important;
      }

      .eval-method-radio-text-custom {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Small !important;
        line-height: 1.375rem !important;
        padding-left: 0.625rem !important;
        padding-top: 0.5rem !important;
      }
    }

    .eval-method-checkbox {
      display: flex;
      align-items: center;
      padding-bottom: 16px !important;

      .form-check {
        padding-left: 0rem !important;
      }

      .eval-method-radio-text {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Small !important;
        line-height: 1.375rem !important;
        padding-left: 0.625rem !important;
        padding-top: 0.188rem !important;
      }

      .eval-method-radio-text-custom {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Small !important;
        line-height: 1.375rem !important;
        padding-left: 0.625rem !important;
        padding-top: 0.5rem !important;
      }
    }
  }
}

.survey-row {
  padding-top: 0rem !important;
}

.sample-size-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 150% !important;
  color: #8c8c8c !important;
  margin-top: -1.25rem !important;
  margin-bottom: 1.75rem !important;
}

.grid-column-assessment {
  padding-top: 0rem !important;
  padding-bottom: 0.75rem !important;
}

.inputs-title {
  @include font-style(1rem !important, $info !important);
  opacity: 0.6;
  margin: 2.857rem 0rem;
}

.contractor-cost-custom {
  color: #757575 !important;
  margin-left: 235px !important;
}

.expenditure-div {
  display: flex;
  flex-direction: column;

  .expenditure-input,
  .expenditure-input-err {
    height: 2.857rem !important;

    input {
      padding-left: 3.375rem !important;
      padding-right: 1rem !important;
      border-radius: 0.5rem !important;
      border-radius: 0rem 0.5rem 0.5rem 0rem !important;
      padding-left: 1rem !important;
      height: 2.857rem !important;
    }

    i.icon {
      background-color: #ededed;
      width: 3.125rem;
    }
  }

  .expenditure-input-err {
    border: 0.063rem solid #ff0000; //when error
    border-radius: 0.28571429rem;
  }

  .error-msg-div {
    @include field-err;
  }

  .error-msg-hidden {
    display: none;
  }
}

.error-count {
  color: #ff0000; //when error
}

.venue-label-div {
  @include display-flex(space-between, center);

  .add-user {
    .ui.button.customColor,
    .ui.button.contractor {
      background-color: #ffffff !important;
      @include font-style(1rem !important, $gradientRight !important);
      padding: 0;
      margin-top: 0 !important;
      margin-bottom: 0.438rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.search-field {
  @include search-field;
  margin-bottom: 1.875rem;
}

.extra-padding {
  padding: 0rem 1.429rem 0rem 0rem !important;
}

.extra-padding-sides {
  padding: 0rem 1.875rem 0rem 2.813rem !important;
}

.hr-extra-padding {
  padding: 0rem 0rem 0rem 0rem !important;
}

.empty-cell-space {
  width: 0.938rem !important;
}

.empty-cell-space-custom {
  width: 0.938rem !important;
}

.empty-cell-space-outer {
  margin-left: 0.688rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */

  color: #000000;
}

.empty-cell-space-outer-custom {
  margin-left: 0rem !important;
  padding-right: 5rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Default;
  line-height: 1.25rem;
  /* identical to box height, or 143% */

  color: #000000;
}

.activity-section-cell {
  margin-left: 0.938rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */
  color: #000000;
}

.activity-section-cell-custom {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Default;
  line-height: 1.25rem;
  /* identical to box height, or 143% */
  color: #000000;
}

.permission-empty-label {
  text-align: center !important;
  margin-left: 1% !important;
  color: #dc3545 !important;
  padding-top: 0.938rem !important;
  font: Inter !important;
}

.venue-custom {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: $Body-Default !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
  margin-bottom: $spacing-lg !important;
}

.extra-padding-custom {
  border-top: 0.063rem solid #e0e0e0 !important;
  padding: 0rem 43px 0rem $spacing-xl !important;
}

.col-lg-3 {
  margin: 0.938rem 0rem;
}

.member-action-area {
  display: flex;
  flex-direction: column;

  .member-actions {
    width: 6.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }

  .member-actions-output {
    width: 9.375rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    i {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .selected-name-role {
    align-items: center !important;
    flex-direction: column !important;
  }

  .selected-name {
    align-items: center !important;
    flex-direction: column !important;
  }

  .member-action-area {
    margin-top: 0.625rem;
  }

  .member-actions {
    align-items: center !important;
    flex-direction: column !important;
  }

  .selected-sub {
    display: flex;
    justify-content: center;
    margin-top: 0.313rem;
  }

  .ui.attached.tabular.menu {
    display: flex;
    flex-direction: column;
  }
}

.participants-remaining-container {
  display: flex;
  justify-content: center;
  @include font-style(0.75rem !important, $info !important);

  .number {
    @include font-style(0.938rem !important, $gradientRight !important);
    margin-left: 0.313rem;
    margin-top: 0.063rem;
  }
}

.approval-active-data {
  label {
    font-family: $fontFamily;
    font-weight: 400 !important;
    font-size: $Body-Default !important;
    color: $subLabel !important;
  }
}

.approval-active-data-code {
  label {
    font-family: $fontFamily;
    font-weight: 400 !important;
    font-size: $Body-Default !important;
    color: $subLabel !important;
  }
}

.objective-select-check {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;

  .ui.checkbox {
    margin-bottom: 1rem;

    label {
      @include font-style(1rem !important, $info !important);
    }
  }
}

.objective-select-check-new {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-lg;

  .ui.checkbox {
    label {
      @include Body-Default;
    }
  }

  .objective-select-spacing {
    margin-right: 80px !important;
  }
}

.domain-select-check {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.313rem !important;
  @include Body-Default;
}

.ui.check-box input:checked ~ .box:after,
.ui.check-box input:checked ~ label:after {
  border-radius: 0.25rem !important;
  color: $white !important;
  background: $gradientRight !important;
}

.ui.check-box label {
  color: $black !important;
}

.participant-delete-button-container {
  display: flex;
  justify-content: flex-end;
}

.select-modal-button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  color: $gradientRight;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
}

.select-modal-button-confirm {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  margin-left: 0.938rem;
}

.objective-list-goal-container {
  margin-top: 0.813rem;
  display: flex;
  flex-direction: column;

  .objective-list-goal-header {
    @include Body-Default();
  }

  .objective-list-goal-list {
    @include Body-Small($Text-Secondary);
  }
}

.objective-list-goal-container-extra-margin {
  display: flex;
  flex-direction: column;

  .objective-list-goal-header {
    @include Body-Default();
  }

  .objective-list-goal-list {
    @include Body-Small($Text-Secondary);
  }
}

.objective-list-goal-list:last-child {
  margin-bottom: 0rem !important;
}

.goal-primary-policy-domain {
  display: flex;
  flex-direction: column;

  .objective-list-goal-header {
    @include Body-Default();
  }

  .objective-list-goal-list {
    @include Body-Small($Text-Secondary);
  }
}

.secondary-policy-domain-label {
  font-weight: 600 !important;
}

.secondary-policy-domain-label-muted-text {
  font-size: $Body-Small !important;
  margin-top: 0rem !important;
}

.goal-secondary-policy-domain {
  display: flex;
  flex-direction: column;

  .objective-list-goal-header {
    @include Body-Default();
  }

  .objective-list-goal-list {
    @include Body-Small($Text-Secondary);
  }
}

.domain-list-goal-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.75rem;
  margin-bottom: $spacing-lg;
  color: $subLabel;

  .domain-list-goal-list {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    color: #757575 !important;
  }
}

.evaluation-div-loader {
  display: flex !important;
  width: 100% !important;
  margin-bottom: 1.563rem !important;
}

.ui.tabular.menu .active.item {
  color: $gradientRight !important;
}

.clear-lbl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.857rem;
  cursor: pointer;
  @include font-style(1rem !important, $gradientRight !important);
}

.calculate-lbl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.857rem;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: $gradientRight;
  @include font-style(1rem !important, $white !important);
}

.sample-size-tot {
  height: 5.5rem;
  border-radius: 0.25rem;
  background-color: $orgBackground;
  display: flex;
  align-items: center;
}

.sample-size-tot-container {
  display: flex;
  justify-content: flex-end;
  margin-top: $spacing-lg;
}

.calculate-lbl:hover,
.sample-sizeuse-button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.sample-sizeuse-button {
  background-color: $gradientRight !important;
  color: $white !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  line-height: 1.375rem !important;
  text-align: center !important;
}

.sample-sizeuse-button:active {
  background-color: $gradientRight !important;
}

.cultureCount-check {
  .form-control {
    .form-check {
      padding-left: 0 !important;
    }
  }
}

.form-check-inline.disabled {
  input {
    opacity: 0.5 !important;
  }

  label {
    color: $info !important;
    opacity: 0.5 !important;
  }
}

.program-activities {
  padding: 0 !important;

  .inputs-table-head {
    background-color: #e87808;
    height: 2rem !important;
  }
}

.table-holder {
  overflow-x: auto;
  width: 100%;
}

.inputs-table {
  margin-top: $spacing-lg;
  margin-bottom: 2.857rem;

  thead {
    tr {
      th {
        border-top: 0rem;
        @include font-style(0.75rem, $info, 600);
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1.25rem 1.25rem !important;
        @include font-style(1rem, $info);
      }
    }
  }
}

.tdata {
  padding: 0;
}

.right-float {
  float: right;
}

.modal-toggle {
  margin-top: -0.25em;
}

.modal-toggle-bevidence {
  margin-top: -0.25em;
  margin-left: 12px;
}

.modal-toggle-bdemo {
  margin-top: -0.25em;
  margin-left: 12px;
}
.contribution-bar-model {
  margin-top: -13px !important;
}

.activate-notify-row {
  .activate-col {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }

  .linked-activity-model-column {
    margin-top: 10px !important;
  }

  .notify-col {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    width: 2.75rem;
    height: 1.375rem;
  }

  .ui.toggle.checkbox input:focus ~ .box:before,
  .ui.toggle.checkbox input:focus ~ label:before {
    background-color: rgba(0, 0, 0, 0.15);
  }

  .ui.toggle.checkbox input ~ .box:after,
  .ui.toggle.checkbox input ~ label:after {
    width: 1.125rem;
    height: 1.125rem;
    left: 0.1rem;
    top: 0.15rem;
    box-shadow: none;
  }

  .two-step-radio-container .ui.toggle.checkbox input:checked ~ .box:before,
  .two-step-radio-container .ui.toggle.checkbox input:checked ~ label:before {
    height: 1.375rem;
    width: 2.75rem;
  }

  .ui.toggle.checkbox input:checked ~ .box:after,
  .ui.toggle.checkbox input:checked ~ label:after {
    left: 1.7rem;
    top: 0.15rem;
    width: 1.125rem;
    height: 1.125rem;

    box-shadow: none;
  }
}

.modal-toggle-text {
  padding-top: 0.3em;
  font-size: $Body-Small !important;
}

.outcome-label {
  padding-top: 2em;
}

.linked-activity {
  cursor: pointer;
}

.parent-objectives {
  background-color: $background !important;
  margin: 0 !important;

  .ui.grid {
    margin-top: 0rem;
  }
}

.objective {
  background-color: $background !important;
  margin: 0 !important;

  .ui.grid {
    margin-top: 0rem;
  }
}

.extra-row-padding {
  padding: 2em 1em !important;
}

.theory-row-padding {
  padding: 2em 0em !important;
}

.check-box-description {
  @include Body-Small($Gray-70);
  margin-top: 0rem;
  margin-left: 1.8rem;
}

.objective-select-spacing {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-xl !important;
}

.column.objective-select-spacing:last-child {
  margin-bottom: 0rem !important;
}

.objectiveGoal-description {
  @include Body-Default;
  color: $black !important;
}

.parent-outcomes {
  margin-top: 0.625rem !important;
  cursor: pointer;
  line-height: 0.938rem !important;
  width: 100% !important;
}

.grant-evidence-intended-outcomes {
  margin-left: 0.75rem !important;
  margin-top: 0.625rem !important;
}

.create-evidence-grant-intended-outcomes {
  display: list-item;
  @include Body-Default;
  margin-bottom: 0.625rem !important;
}

.see-more-see-less-btn-container-grant {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: transparent !important;
  text-align: right;

  .see-more-see-less-btn-grant {
    width: 9.375rem;
    font-family: $fontFamily !important;
    color: $gradientRight !important;
    font-size: $Body-Small !important;
    font-weight: 400 !important;
    text-align: right !important;
    background-color: unset !important;
    padding: 0rem !important;
  }
}

.parent-info {
  @include Body-Default-Semibold($Gray-90);
  @include set-padding(0.625rem, 0rem, 0.625rem, 0rem);
}

.parent-act-type-container {
  display: flex;
  flex-direction: column;

  .parent-act-type-title {
    @include font-style(1rem !important, $info);
    line-height: 1.375rem !important;
  }
}

.pointer {
  cursor: pointer;
}

.group-bg {
  .form-control {
    background-color: transparent;
  }
}

.orange-text {
  color: $gradientLeft;
}

.green-text {
  color: $green;
}

.policy-duplicate {
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0rem;
  text-align: left;
}

.header-goal-duplicate {
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0rem;
  text-align: left;
}

.update-contact-btn {
  background-color: $gradientRight !important;
  font-family: $fontFamily !important;
  color: $white !important;
  font-weight: 300 !important;
}

.new-activity-modal {
  border-radius: 0.5rem !important;

  .new-activity-modal-close-icon {
    float: right;
    font-size: $h5 !important;
    margin-top: $spacing-sm !important;
    margin-right: 1rem !important;
    width: fit-content;
    cursor: pointer !important;
  }

  .new-activity-modal-header {
    padding-top: 1.375rem !important;
    font-family: $fontFamily !important;
    font-size: $Body-Default !important;
  }
}

.small-modal-header {
  padding-top: 0.125rem !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
}

.notify-styles {
  margin-top: 14px;
  margin-left: 20px;
}
.notify-up {
  margin-top: -30px;
}

.notify-modal-header {
  padding-top: 1.375rem !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
}

.small-modal-buttons {
  float: right !important;
  margin-bottom: $spacing-sm !important;
}

.new-activity-modal-close-icon {
  float: right;
  font-size: $h5 !important;
  margin-top: $spacing-sm !important;
  margin-right: 1rem !important;
  width: fit-content;
  cursor: pointer !important;
}

.new-collective-save-btn {
  height: 2rem !important;
  width: 5.063rem !important;
  font-family: $fontFamily !important;
  font-weight: 300 !important;
  line-height: 0.125rem !important;
  background-color: $gradientRight !important;
  color: $white !important;
}

.linked-act-collective-save-btn {
  height: 2.25rem !important;
  width: 5.063rem !important;
  font-family: $fontFamily !important;
  font-weight: 300 !important;
  line-height: 0.125rem !important;
  background-color: $gradientRight !important;
  color: $white !important;
}

.activity-activation-cancel-btn {
  height: 2rem !important;
  width: 5.063rem !important;
  font-family: $fontFamily !important;
  font-weight: 300 !important;
  line-height: 0.125rem !important;
  color: $gradientRight !important;
  border: 0.063rem solid $gradientRight !important;
  background-color: transparent !important;

  &:hover {
    background-color: $gradientRight !important;
    color: $white !important;
  }
}

.ui.modal > .content {
  .activity-model-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .activity-model-buttons {
      flex-direction: column;

      .activity-confirm-button {
        margin-top: 1.25rem;
      }
    }
  }

  .act-new {
    .act-inputs-new {
      height: 2.857rem !important;
      background: #ffffff;

      box-sizing: border-box;
      border-radius: 0.5rem;
    }

    .sub-label {
      height: 2.25rem;
      left: 0rem;
      right: 0rem;
      bottom: -3rem;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 150%;
      color: #8c8c8c;
    }

    .add-bottom {
      margin-bottom: 1.875rem !important;
    }

    label {
      color: $black;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
    }
  }

  .act-inputs {
    height: 2.857rem !important;
    border-radius: 0.5rem !important;
  }

  .act-inputs-textarea {
    min-height: 10em !important;
  }
}

.ui.button.activity-confirm-button:hover,
.ui.buttons.activity-confirm-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.activity-confirm-button {
  width: 86px;
  border-radius: 0.25rem;
  background-color: $gradientRight !important;
  color: $white !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  line-height: 1.5rem;
  text-align: center;
}

.activity-reinvite-button {
  font-family: $fontFamily !important;
  font-size: $Body-Large;
  line-height: 1.5rem;
  text-align: center;
}

.ui.button.activity-confirm-button:active {
  background-color: $gradientRight !important;
}

.inline-flex {
  display: flex;
  flex-direction: row;
}

.grant-export-row {
  .program-data-upload {
    display: flex;
    flex-direction: column;
    padding-top: $spacing-lg !important;
    padding-bottom: $spacing-sm !important;

    .title {
      font-size: $Body-Default !important;
      font-weight: 600 !important;
      line-height: 1.063rem !important;
      font-family: $fontFamily !important;
      text-align: left !important;
      letter-spacing: 0rem !important;
      color: $info !important;
    }

    .description {
      margin-top: 0.75rem !important;
      font-size: $Body-Small !important;
      line-height: 1.375rem !important;
      font-family: $fontFamily !important;
      text-align: left !important;
      letter-spacing: 0rem !important;
      color: $subLabel !important;
    }

    .template-link {
      label {
        padding-left: $spacing-lg !important;
        padding-right: $spacing-lg !important;
        margin-top: 0.625rem !important;
        font-weight: 400 !important;
        font-size: $Body-Small !important;
        line-height: 2.313rem !important;
        font-family: $fontFamily !important;
        text-align: left !important;
        letter-spacing: 0rem !important;
        color: $gradientRight !important;
        cursor: pointer !important;
      }
    }

    .export-program-data-btn {
      width: fit-content;

      .Secondary-Button {
        @include btn;
        background: $white;
        color: $Primary !important;

        &:hover {
          background-color: $Primary-lighter !important;
          color: $Primary !important;
        }

        &:active {
          color: $Primary !important;
        }

        i.icon {
          display: flex;
          align-items: center;
        }
      }
    }

    .btn-container {
      margin-top: 1.188rem !important;

      button {
        font-size: $Body-Default !important;
        line-height: 1.375rem !important;
        font-family: $fontFamily !important;
      }
    }
  }
}

.grant-import-row {
  .program-data-upload {
    display: flex;
    flex-direction: column;
    padding-top: $spacing-lg !important;
    padding-bottom: $spacing-sm !important;

    .title {
      font-size: $Body-Default !important;
      font-weight: 600 !important;
      line-height: 1.063rem !important;
      font-family: $fontFamily !important;
      text-align: left !important;
      letter-spacing: 0rem !important;
      color: $info !important;
    }

    .description {
      margin-top: 0.75rem !important;
      font-size: $Body-Small !important;
      line-height: 1.375rem !important;
      font-family: $fontFamily !important;
      text-align: left !important;
      letter-spacing: 0rem !important;
      color: $subLabel !important;
    }

    .template-link {
      label {
        padding-left: $spacing-lg !important;
        padding-right: $spacing-lg !important;
        margin-top: 0.625rem !important;
        font-weight: 400 !important;
        font-size: $Body-Small !important;
        line-height: 2.313rem !important;
        font-family: $fontFamily !important;
        text-align: left !important;
        letter-spacing: 0rem !important;
        color: $gradientRight !important;
        cursor: pointer !important;
      }
    }

    .export-program-data-btn {
      width: fit-content;

      .Secondary-Button {
        @include btn;
        background: $white;
        color: $Primary !important;

        &:hover {
          background-color: $Primary-lighter !important;
          color: $Primary !important;
        }

        &:active {
          color: $Primary !important;
        }

        i.icon {
          display: flex;
          align-items: center;
        }
      }
    }

    .btn-container {
      margin-top: 1.188rem !important;

      button {
        font-size: $Body-Default !important;
        line-height: 1.375rem !important;
        font-family: $fontFamily !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .extra-vertical-margin {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
}

.browse-file {
  width: 100% !important;
}

.browse-file-grant {
  width: 100% !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  height: 2.857rem !important;
  background-color: $white !important;
}

.browse-btn-grant {
  width: max-content !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  cursor: pointer !important;
  background-color: $white !important;
  border: 0.063rem solid #e0e0e0 !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  height: 2.857rem !important;
}

.grantee-checkbox {
  padding: unset !important;

  .form-check {
    padding-left: unset !important;
  }

  label {
    font-size: $Body-Default !important;
    line-height: 1.375rem !important;
    font-family: $fontFamily !important;
  }
}

.grantee-checkbox-outcome {
  display: flex !important;
  align-items: center !important;

  input {
    margin-right: 0.375rem !important;
  }

  .form-check {
    padding-left: unset !important;
  }

  label {
    font-size: $Body-Default !important;
    line-height: 1.375rem !important;
    font-family: $fontFamily !important;
  }
}

.ui.secondary.pointing.menu .active.item {
  border-bottom-color: $gradientRight !important;
}

.ui.secondary.pointing.menu .item {
  background-color: $white !important;
  margin: 0rem;
}

.document-input {
  .ui.grid > .row {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    align-items: stretch;
    width: 100% !important;
    padding: 0rem 0 !important;
  }
}

.c-content-container {
  display: flex;
  flex-direction: column;

  .c-content {
    font-size: $Body-Default !important;
    color: $info !important;
    font-weight: 500 !important;
    font-family: $fontFamily !important;
    text-align: justify !important;
  }

  .c-content-topic-greenlight {
    font-size: $Body-Small !important;
    color: $subLabel !important;
  }

  .c-content-list-greenlight {
    display: list-item !important;
    margin-left: 1.125rem !important;
  }

  .c-content-outcome {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: $Body-Small !important;
    color: $info !important;
    font-weight: 300 !important;
    font-family: $fontFamily !important;
    text-align: justify !important;
    margin-left: 0.313rem !important;
  }
}

.user-act-heading-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  .user-act-heading-type {
    color: #898989;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.188rem;
    text-align: center;
  }
}

.eval-table-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.188rem;
}

.eval-table th,
.eval-table td {
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
}

.eval-table th {
  padding: 0.625rem !important;
}

.complete-act-info {
  @include font-style($spacing-sm, $info);
  text-align: justify;
}

.two-step-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 25%;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  label {
    @include font-style(1rem !important, $info);
    flex: 2;
    margin-right: 0.938rem;
  }

  margin: 1.25rem 0rem;

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }
}

.linked-activity-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: 1.25rem !important;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  label {
    @include font-style(1rem !important, $info);
    flex: 2;
    margin-right: 0.938rem;
  }

  margin: 1.25rem 0rem;

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    margin-right: 1.125rem !important;

    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }
}

.ui.checked.fitted.toggle.checkbox.report-filter input:checked ~ label:before {
  background-color: $gradientRight !important;
}

.ui.checked.fitted.toggle.checkbox.report-filter
  input:focus:checked
  ~ label:before {
  background-color: $gradientRight !important;
}

.linked-activity-model-column {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.manage-user-padding {
  margin-top: 5px !important;
}

.partner-activity-model-column {
  padding-bottom: 0.2rem !important;
  padding-top: 0.2rem !important;
  margin-top: -8px !important;
}

.partner-activity-model-column-pr {
  padding-bottom: 0.2rem !important;
  padding-top: 0.2rem !important;
  margin-top: -19px !important;
}
.partner-activity-model-column-pr-2 {
  padding-bottom: 0.2rem !important;
  padding-top: 0.2rem !important;
  margin-top: -9px !important;
}

.add-linked-activity .formik-custom-field {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  label {
    color: #3b3b3b !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}

.center-verification-new {
  display: flex !important;
  margin-top: 30.5px;
  padding-left: 11.5px !important;
}

.Verify-Primary-Button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background: #ef8123 !important;
  border-radius: 0.285714286rem !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.285714286rem !important;
  color: #ffffff !important;
  line-height: 1.285714286rem !important;
  padding: 0.625rem 1rem !important;
  border: 0.063rem solid #ef8123 !important;
  height: 2.857rem !important;
}
.Verify-Primary-Button-next {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background: #ef8123 !important;
  border-radius: 0.285714286rem !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.285714286rem !important;
  color: #ffffff !important;
  line-height: 1.285714286rem !important;
  padding: 0.625rem 1rem !important;
  border: 0.063rem solid #ef8123 !important;
  height: 2.857rem !important;
}
.existing-users {
  margin-top: 25px;
}

.Verify-Secondary-Button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background: #ef8123 !important;
  border-radius: 0.285714286rem !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.285714286rem !important;
  color: #ffffff !important;
  line-height: 1.285714286rem !important;
  padding: 0.625rem 1rem !important;
  border: 0.063rem solid #ef8123 !important;
  height: 2.857rem !important;
  background: #ffffff !important;
  color: #ef8123 !important;
}

.activity-summary-grid {
  p {
    line-height: 18px;
    font-size: $Body-Small;
    width: 400;
    font-family: 'Inter', sans-serif;
    color: #000000;
  }

  .confirm-btn {
    color: #ef8123 !important;
    cursor: pointer;
    width: 100px;
  }

  background-color: #fafafa;
  height: 136px;
  margin-bottom: 15px !important;
}

.summary-management-grid {
  p {
    line-height: 38px;
    font-size: $Body-Small;
    width: 400;
    font-family: 'Inter', sans-serif;
    color: #000000;
  }

  .confirm-btn {
    color: #ef8123 !important;
    cursor: pointer;
    width: 100px;
  }

  background-color: #fafafa;
  height: 136px;
  margin-bottom: 15px !important;
}

.email-verification {
  .formik-custom-field {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.email-verification-two {
  .formik-custom-field {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.email-response-details {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.radio-padding .formik-custom-field {
  margin-top: 0px;
  margin-bottom: 0px;
}

.linked-model-column-avfield {
  .form-group {
    margin-bottom: 1rem;
  }
}

.linked-activity-model-margin-bottom {
  margin-bottom: 0rem !important;
  padding: 0rem 0rem 0rem 1rem !important;
}

.linked-activity-model-column.av-margin {
  .form-group {
    margin-bottom: 0rem !important;
  }
}

.existing-user-label {
  font-size: 0.857142857rem !important;
  font-weight: 400 !important;
  line-height: 1.142857143rem !important;
  color: #757575 !important;
  display: inline-block;
}

.dil-fl {
  display: flex;
}

.activities-filter.linked-activity-margin-left {
  margin-left: 26px !important;
}

.ui.button.participant-group-button {
  height: 2.75rem;
  width: 21.25rem;
  border-radius: 0.25rem;
  background-color: $gradientRight;
  color: $white;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
}

.ui.button.participant-group-button:active {
  background-color: $gradientRight !important;
}

.ui.button.participant-group-button:hover,
.ui.buttons.participant-group-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.preview-survey {
  background: #ffffff;
  border: 0.125rem solid $gradientRight;
  box-sizing: border-box;
  border-radius: 0.25rem;
  line-height: 1.125rem;
  width: 160px;
  flex-direction: row;
  border-radius: 0.25rem;
  background-color: transparent;
  font-family: $fontFamily;
  font-weight: 600;
  line-height: 2.857rem;
  text-align: center;
  font-size: $Body-Default;
  text-align: center;
  color: $gradientRight;
}

.preview-survey:hover {
  border: 0.125rem solid $amber;
  color: $amber;
}

.culture-count {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;
  border-radius: 0.25rem;
  width: fit-content;
  flex-direction: row;
  border-radius: 0.25rem;
  background-color: transparent;

  font-family: $fontFamily;
  font-weight: 600;
  line-height: 2.75rem;
  text-align: center;
  font-size: $Body-Small;
  text-align: center;
  width: 234px;
  height: 3rem;
  color: $gradientRight;
  margin-top: 1.563rem;
  margin-bottom: 1.563rem;
}

.culture-count:hover {
  background-color: rgba(239, 129, 35, 0.1);
}

.document-culture-count {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  flex-direction: row;
  border-radius: 4px;
  background-color: transparent;

  font-family: $fontFamily;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  font-size: $Body-Small;
  text-align: center;
  width: 234px;
  height: 42px;
  color: $gradientRight;
}

.document-culture-count:hover {
  background-color: rgba(239, 129, 35, 0.1);
}

.evaluation-culture-count {
  background: $gradientRight;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  flex-direction: row;
  border-radius: 4px;
  background-color: $gradientRight;
  font-size: 16px !important;
  font-family: $fontFamily;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  font-size: $Body-Small;
  text-align: center;
  width: 234px;
  height: 42px;
  color: #fff;
}

.button-survey {
  width: 60.66%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1.25rem;

  .create-button-text {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.25rem;
  }

  .create-button-edit {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.25rem;
  }
}

.program-activities-input {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
  font-size: $Body-Small !important;
}

.button-survey-new {
  width: 60.66%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1.25rem;

  .create-discard {
    display: flex;
    flex-direction: row;

    .discard-eval {
      font-style: normal;
      cursor: pointer;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      color: #ef8123;
      padding: 0.938rem 3.75rem 0rem 0rem !important;
    }
  }

  .ui.button.create-eval-plan-button {
    height: 3rem;
    width: 21.25rem;
    border-radius: 0.25rem;
    background-color: #ef8123;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: $Body-Default;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }

  .create-button-text {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.25rem;
  }

  .back-button {
    background: #ffffff;
    border: 0.063rem solid $gradientRight;
    box-sizing: border-box;
    border-radius: 0.25rem;
    width: 102px;
    height: 3rem;
    margin-left: 1.563rem;
    text-align: center;
    color: $gradientRight;
  }

  .back-text {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.5rem;
    margin-top: 0.625rem;
  }
}

.ui.button.btn-add-subtitle {
  position: static;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  /* identical to box height, or 200% */
  text-align: center;
  color: #6d28d9;
  background-color: #f5f3ff;
}

@media only screen and (max-width: 767px) {
  .button-survey {
    flex-direction: column;
    align-items: center !important;
  }

  .preview-survey {
    margin-top: 0.625rem;
  }

  .participant-group-discard-button {
    margin-top: 0.938rem;
  }

  .custom-width {
    width: 171px;
  }

  .add-icon {
    margin-top: 0.063rem;
  }
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.content-container {
  .report-graph-col {
    .ui.labeled.icon.button,
    .ui.labeled.icon.buttons .button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .loading-btn {
    background-color: #fff !important;
  }

  .prepare-graph {
    width: 12.5rem !important;
  }
}

.act-member-segment {
  border-bottom: none !important;
  border-top: none !important;
}

.segment-heading {
  color: #000000;
  font-family: Inter !important;
  font-style: normal !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.segment-sub-heading {
  @include Body-Default($Text-Secondary);
  margin: 0.25rem 0 !important;
}

.objective-modal-toggle {
  display: flex;
  align-items: center;
}

.objective-modal-header {
  background-color: $orgBackground !important;
}

.projected-activity-modal-header {
  height: 3rem !important;
  border: 0.063rem solid $gradientRight !important;
  border-radius: 0.25rem !important;
}

.ui.button.participant-group-discard {
  width: 21.25rem;
  border-radius: 0.25rem;
  color: $red;
  font-family: Inter;
  font-size: $Body-Default;
  font-weight: 600;
  line-height: 1.125rem;
  text-align: center;
}

.evalPlan-col {
  border-bottom: none !important;
  border-top: none !important;
  padding-left: $spacing-xl !important;
  padding-right: 2.563rem !important;
  padding-bottom: 1.875rem !important;

  .form-control {
    height: 3.125rem;
  }

  .newme {
    background-color: red;
  }

  .scroll-selectedgroups {
    padding-right: 1.25rem;
    max-height: 100%;
    overflow-y: auto;

    .selected-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .selected-name-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .selected-name {
          @include font-style(1rem, $info, 600);
        }

        .selected-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }

        .selected-sub {
          @include font-style(0.75rem, $info);
          opacity: 0.6;
        }
      }
    }
  }
}

.evalPlan-col.white {
  background-color: $white;
}

.av-padding {
  border-top: 0rem;
  padding-top: 0.625rem !important;
  padding-bottom: 0.125rem !important;
  line-height: 0rem;
  margin-top: 0.813rem;
}

.add-left-margin {
  margin-left: $spacing-sm;
}

.custom-ques {
  padding-bottom: 0.625rem !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Default !important;
  line-height: 1.25rem !important;
  color: #000000 !important;
  display: flex !important;
  background-color: $white !important;

  .move-right {
    margin-left: 0.625rem !important;
  }
}

.evalPlan-question {
  border-bottom: none !important;
  border-top: 0rem;
  padding-left: 0rem !important;

  background-color: white;

  .question-text {
    padding: 0.625rem 0rem 0.625rem 0rem !important;
    @include Body-Default-Semibold(#3b3b3b);
  }

  .select-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1.25rem;
    color: #000000;
    margin-top: 0.313rem;
  }

  .content-highlight {
    background-color: #c3c3c3;
  }

  .outputs-col-div {
    width: 100%;
  }

  .eval-qs {
    cursor: pointer;
    @include font-style(1rem, $gradientRight, 600);
    @include display-flex(flex-start, center);

    i.icon {
      font-size: $h6;
    }
  }

  .eval-qs-obj {
    font-size: $Body-Small;
  }

  .eval-form {
    height: 3.125rem;
  }

  .eval-qs-new {
    margin-right: 0.313rem;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $Body-Default;
    line-height: 1.375rem;
    color: #006572;
    margin-left: 19.313rem;
    cursor: pointer;
  }

  .eval-qs-next-to-button {
    margin-right: 0.313rem;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $Body-Default;
    line-height: 1.375rem;
    color: #006572;
    margin-left: 19.313rem;
    cursor: pointer;
    margin-top: 0.625rem;
  }

  .eval-qs-remove {
    color: red;
    cursor: pointer;
    text-align: right;
    position: absolute;
  }

  .show-facilitator {
    padding: 0rem 2.286rem !important;
  }

  .show-cr {
    background-color: #ffffff;
    padding: 1rem 0.938rem;
    margin-top: 0.75rem;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;

    .centre-the-edit {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .custom-question-input {
    background: #ffffff;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;
    height: 2.857rem;

    .survey-text {
      padding: 0.563rem;
    }
  }
}

.avfield-activity-type-field {
  height: 36.313rem !important;
  width: 100% !important;
}

.avfield-income-field {
  width: 100% !important;
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}

.income-icon-input {
  display: flex;
  flex-wrap: nowrap !important;

  .form-group {
    margin-bottom: 0rem !important;
    height: 2.857rem !important;
    width: 100%;
  }

  .input-group-prepend-err {
    border-top: 0.313rem solid #dc3545 !important;
    border-left: 0.313rem solid #dc3545 !important;
    border-bottom: 0.313rem solid #dc3545 !important;

    .ired.icon {
      background-color: #dc3545 !important;
    }
  }
}

.date-custom,
.date-custom-err {
  div {
    width: 100% !important;
    border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  }

  input {
    width: fit-content !important;
    height: 2.25rem;
    font-size: $Body-Default !important;
  }

  i.icon {
    font-size: $Body-Default !important;
  }
}

.date-custom-err {
  input {
    border-color: #dc3545 !important;
  }

  i.icon {
    color: #dc3545 !important;
  }
}

.invalid-date-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.date-custom-disabled {
  div {
    width: 100% !important;
    border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  }

  input {
    width: fit-content !important;
    height: 2.25rem;
    font-size: $Body-Default !important;
    background-color: #e9ecef !important;
  }

  i.icon {
    font-size: $Body-Default !important;
  }
}

.evaluation-activity-radio-group {
  width: 100%;
  display: flex !important;
  flex-direction: column;

  .radio-group {
    display: flex !important;

    .radio-item {
      display: flex;
      margin-right: 0.938rem;

      input {
        margin-right: 0.5rem;
      }
    }
  }
}

.evaluation-activity-toggel-group {
  width: 100%;
  display: flex !important;

  input[type='checkbox']:checked {
    background-color: #ef8123 !important;
  }

  div {
    margin-right: 2.857rem;

    input[type='checkbox']:checked {
      background-color: #ef8123 !important;
    }
  }

  .lable-margin-left {
    margin-right: 0.5rem !important;
    font-size: $Body-Small !important;
    color: $black !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }
}

.ui.button.Primary-Button {
  @include btn;
}

.ui.button.Secondary-Button {
  @include btn;
  background: $white;
  color: $Primary !important;

  &:hover {
    background-color: $Primary-lighter !important;
    color: $Primary !important;
  }

  &:active {
    color: $Primary !important;
    border: 0.063rem solid $Primary;
  }

  &:disabled {
    @include Body-Default($Gray-50);
    background: $White;
    border: 0.063rem solid $Gray-50;

    border-radius: 0.25rem;
  }
}

.daterangepicker .applyBtn {
  margin-left: 0.5rem !important;
  font-size: $Body-Small !important;
  font-weight: bold !important;
  padding: 0.25rem 0.5rem !important;
  background-color: #ef8123 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #ef8123 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.daterangepicker td.in-range {
  background-color: #ffeccf !important;
  border-color: transparent !important;
  color: #000 !important;
  border-radius: 0 !important;
}

.evalPlan-question-model {
  border-bottom: none !important;
  border-top: 0rem;
  padding-left: 0rem !important;
  padding-right: 2.563rem !important;
  padding-bottom: 1.875rem !important;

  .outputs-col-div-model {
    width: 100%;
  }

  .eval-qs-model {
    margin-top: 1rem;
    margin-left: -0.5rem;

    cursor: pointer;
    @include font-style(1rem, $gradientRight, 600);
    @include display-flex(flex-start, center);

    i.icon {
      font-size: $h6;
    }
  }

  .eval-qs-obj-model {
    font-size: $Body-Small;
  }

  .eval-form {
    height: 3.125rem;
  }

  .eval-qs-new-model {
    margin-right: 0.313rem;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $Body-Default;
    line-height: 1.375rem;
    color: #006572;
    margin-left: 19.313rem;
    cursor: pointer;
  }

  .eval-qs-next-to-button-model {
    margin-right: 0.313rem;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: bold;
    font-size: $Body-Default;
    line-height: 1.375rem;
    color: #006572;
    margin-left: 19.313rem;
    cursor: pointer;
    margin-top: 0.625rem;
  }

  .eval-qs-remove-model {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Default;
    line-height: 1.375rem;
    color: red;
    cursor: pointer;
    position: absolute;
    margin-left: 386px;

    text-align: right;
  }

  .show-cr-model {
    background-color: #f5f5f5;
    padding: 1rem 0.938rem;
  }
}

.evalPlanType-question {
  border-bottom: none !important;
  border-top: none !important;
  background-color: white;

  .evalPlanTypes {
    padding-bottom: 1.25rem !important;
  }

  .evaluations-method-label {
    font-style: normal;
    margin-bottom: 1.438rem;
    @include Body-Default-Semibold(#3b3b3b);
  }
}

.model-view-evaluation {
  border-bottom: none !important;
  border-top: 0rem;
  font-family: $fontFamily;

  .eval-name-model {
    font-size: $Body-Large;
    font: weight 600;
  }

  .eval-objective {
    margin-top: 1.875rem;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1rem;
    color: #636060;
  }

  .eval-participant-type {
    margin-top: $spacing-lg;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1rem;
  }

  .eval-objective-sub {
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.375rem;
  }

  .selected-eval-edit {
    color: $editGroup !important;
    font-family: Inter !important;
    font-size: $Body-Default !important;
    font-weight: 600;
    line-height: 1.375rem !important;
    text-align: right;
    margin-left: 500px;
    cursor: pointer;
  }

  .delete-eval-edit {
    color: $red !important;
    font-family: $fontFamily !important;
    font-size: $Body-Default !important;
    font-weight: 600;
    line-height: 1.375rem !important;
    text-align: right;
    cursor: pointer;
  }

  .eval-groups {
    margin-top: 0.625rem;
    font-size: $Body-Default;
  }

  .show-qs-model {
    background-color: #fbfbfb;
  }

  .eval-groups-qs {
    margin-top: 0.625rem;
    font-size: $Body-Small;

    div {
      background-color: #fbfbfb;
    }
  }

  .eval-groups-qs-custom {
    margin-top: 0.313rem;
    font-size: $Body-Small;
    padding: 0.375rem 0rem 0.625rem 0.5rem;
  }

  .eval-groups-custom-title {
    font-size: $Body-Default;
    margin-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .eval-group-custom {
    margin-left: 1rem;
    opacity: 0.7;
  }

  .eval-action-area {
    display: flex;
    flex-direction: column;

    .eval-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: -1.25rem;

      i {
        cursor: pointer;
      }
    }
  }
}

.tabs-evaluation {
  .tabs-graph .ui.secondary.pointing.menu {
    margin-left: $spacing-sm;
    margin-right: $spacing-xs;
    margin-top: $spacing-sm;
  }
}

.additional-group {
  display: flex;
  flex-direction: column;

  label {
    span {
      padding-left: 0.625rem !important;
    }
  }
}

.outcome-summary-title {
  @include font-style(1rem !important, $black !important, 600 !important);
}

.plan-documentation {
  margin-top: 1.25rem !important;

  h3 {
    opacity: 0.7 !important;
  }

  .cultural-eval-title {
    @include font-style(1rem !important, $info !important);
    opacity: 0.6;
    margin: 0;
    padding: 1.563rem 0rem;
  }

  .obj-div {
    .radio-container {
      .ui.radio.checkbox {
        label {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Small;
        }
      }

      .ui.radio.checkbox input:focus:checked ~ .box:before,
      .ui.radio.checkbox input:focus:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:before,
      .ui.radio.checkbox input:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:focus:checked ~ .box:after,
      .ui.radio.checkbox input:focus:checked ~ label:after {
        background-color: $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:after,
      .ui.radio.checkbox input:checked ~ label:after {
        background-color: $gradientRight;
      }
    }

    .radio-label {
      @include Body-Small($info);
    }

    .radio-btn {
      margin: 0.5em 2.857rem 0.5em 0;
    }
  }
}

.plan-documentation-custom {
  margin-top: 1.25rem !important;
  padding: 1.875rem 0.375rem 0.188rem 0.938rem !important;

  h3 {
    opacity: 0.7 !important;
  }

  .cultural-eval-title {
    @include font-style(1rem !important, $info !important);
    opacity: 0.6;
    margin: 0;
    padding: 1.563rem 0rem;
  }

  .obj-div {
    .radio-container {
      .ui.radio.checkbox {
        label {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Small;
        }
      }

      .ui.radio.checkbox input:focus:checked ~ .box:before,
      .ui.radio.checkbox input:focus:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:before,
      .ui.radio.checkbox input:checked ~ label:before {
        background-color: #fff !important;
        border: solid 0.125rem $gradientRight;
      }

      .ui.radio.checkbox input:focus:checked ~ .box:after,
      .ui.radio.checkbox input:focus:checked ~ label:after {
        background-color: $gradientRight;
      }

      .ui.radio.checkbox input:checked ~ .box:after,
      .ui.radio.checkbox input:checked ~ label:after {
        background-color: $gradientRight;
      }
    }

    .radio-label {
      @include Body-Small($info);
    }

    .radio-btn {
      margin: 0.5em 2.857rem 0.5em 0;
    }
  }
}

.eval-participants-div {
  display: flex;
  flex-direction: column;
  width: 100%;

  .participants-input {
    height: 3.125rem;
    min-width: 235px;
  }

  .participants-disabled {
    input {
      background: #eaecef;
    }
  }

  .participants-bottom {
    @include font-style(0.75rem, $info, 100, italic);
    opacity: 0.8;
  }
}

.singleObjective {
  height: 100%;
  display: flex;
  align-items: center;
  @include font-style(1rem !important, $info, 600);
}

.eval-plan-objectiveSelector {
  .ui.selection.dropdown {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 3.125rem;
    background: #ffffff;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;

    i {
      padding: 0.438rem 0rem 0rem 0.938rem;
    }

    .text {
      margin-top: 0.188rem;
      font-size: $Body-Default;
      line-height: 1.25rem;
    }
  }
}

.eval-plan-objectiveSelector-documents {
  .ui.selection.dropdown {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: auto;
    width: auto;
    background: #ffffff;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;

    i {
      padding: 0.438rem 0rem 0rem 0.938rem;
    }

    .text {
      margin-top: 0.188rem;
      font-size: $Body-Default;
      line-height: 1.25rem;
    }
  }
}

.eval-plan-objectiveChartToggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.inside-question {
  padding-left: 0rem !important;
}

.semantic-date-form {
  input {
    pointer-events: none;
    opacity: 0.5;
  }
}

.disabled-csv {
  pointer-events: none;
}

.outcome-summary-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 767px) {
    margin-top: 0.625rem;
  }

  label {
    @include font-style(1rem !important, $info);
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }
}

.outcomes-summary-chart-div {
  background-color: #ffffff;
  width: 100%;

  .graph-tab > div > .item {
    flex: 1;
    justify-content: center;
  }

  .graph-tab > div > .item.active {
    border-bottom-color: $gradientRight !important;
  }
}

.summary-chart-col {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

@media only screen and (max-width: 48rem) {
  .outcomes-summary-chart-div {
    padding: 0.625rem !important;
  }
}

.individual-rate-heatmap {
  .pre-post-chart-div {
    border: none !important;
    padding: 0rem !important;
  }
}

.pre-post-chart-div {
  border: 0.063rem solid #e7e7e7;
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 1.875rem !important;
  width: 100%;

  .graph-tab > div > .item {
    flex: 1;
    justify-content: center;
  }

  .graph-tab > div > .item.active {
    border-bottom-color: $gradientRight !important;
  }

  .heat-map {
    padding-top: 1.25rem;

    .total-data-label {
      @include font-style(0.75rem);

      @media (max-width: 48rem) {
        @include font-style(0.625rem);
      }
    }

    .total-data-label {
      @include font-style(0.75rem, $info, bolder);
      padding-bottom: 1.563rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .heat-map-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 0.063rem solid #e0e0e0 !important;

      .left {
        flex: 1 0 4%;
        align-self: center;

        .y-axis-label {
          transform: rotate(-90deg);
        }

        .y-axis-label {
          @include font-style(0.75rem);

          @media (max-width: 48rem) {
            @include font-style(0.625rem);
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        flex: 1 0 96%;

        .x-axis-label {
          display: flex;
          justify-content: space-between;
          padding-top: 0.625rem;
          padding-left: 2.857rem;
        }

        .x-axis-label {
          @include font-style(0.75rem);

          @media (max-width: 48rem) {
            @include font-style(0.625rem);
          }
        }

        div {
          div {
            div:first-child {
              flex: 0 0 2.6vw !important;
            }

            div {
              height: 0.625rem;
              display: flex;
              font-size: $Body-Small;

              div {
                div {
                  padding: 0 0.313rem 0 0 !important;
                  font-size: $Body-Small;
                  display: flex !important;
                  align-items: center;
                  justify-content: flex-end !important;
                }
              }
            }
          }

          div:last-child {
            div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.ui.actual-outcomes-seg {
  .column {
    padding-left: $spacing-xl !important;
  }

  padding: 0 !important;

  .outcomes-container {
    padding: $spacing-lg $spacing-xl;
    display: flex;
    flex-direction: column;

    .chart-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.25rem;

      .heading {
        margin-left: $spacing-sm !important;
        @include font-style(1rem, $gradientRight, 600);
      }
    }
  }
}

.evaluation-heading {
  @include Body-Default-Semibold($Primary);
}

.evaluation-heading-doc {
  font-size: $Body-Default !important;
  color: #000000 !important;
  font-weight: 600 !important;
  margin-right: 0.5rem !important;
}

.eval-button-separate {
  font-size: $Body-Large !important;
  margin-right: 0.5rem;
  margin-left: 0.688rem;
  color: #acacac !important;
  font-weight: 600 !important;
}

.evaluation-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #000000;
  margin-left: 0.188rem;
}

.evaluation-edit-icon {
  cursor: pointer;
}

.evaluation-subtitle {
  position: static;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  text-align: center;
  color: #6d28d9;
  background-color: #f5f3ff;
  margin-bottom: 0.375rem;
  margin-left: 0.313rem;
  padding: 0.063rem 0.75rem;
  cursor: pointer;
  border-radius: 0.25rem;
}

.evaluation-subtitle-lbl {
  position: static;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  text-align: center;
  color: #6d28d9;
  background-color: #f5f3ff;
  margin-bottom: 0.375rem;
  margin-left: 0.313rem;
  padding: 0.063rem 0.75rem;
  border-radius: 0.25rem;
}

.evaluation-heading-input {
  width: 514px;
  margin-left: 0.188rem !important;
  margin-top: 0.5rem !important;
  margin-right: 1.125rem !important;
}

.evaluation-name-column {
  display: flex;
  align-items: flex-end;
}

.evalation-model.ui.small.modal {
  width: 562px;
}

.icons-edit {
  height: 1rem;
  width: 1rem;
  margin-bottom: 0.625rem;
}

.evaluation-save-button.ui.button {
  font-family: Inter !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  text-align: center !important;
  color: #ffffff !important;
  background: $gradientRight !important;
  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  border: 0.063rem solid $gradientRight;
  height: 2.25rem;
}

.evaluation-save-button.ui.button:hover {
  background: $amber !important;
  color: $white !important;
}

.evaluation-icons-add {
  height: $spacing-sm !important;
  width: $spacing-sm !important;
}

.summary-chart-title {
  @include font-style(1rem !important, black !important, 600 !important);
  margin-bottom: $spacing-lg;
}

.objective-list-actual-outcome {
  display: list-item !important;
  margin-left: 1.125rem !important;
  font-size: $Body-Small !important;
}

.question-text {
  padding: 1.563rem 0rem 0.625rem 0rem !important;
  @include Body-Default-Semibold(#3b3b3b);
}

.select-label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #000000;
  margin-top: 0.313rem;
}

.objective-non-list-actual-outcome {
  font-size: $Body-Small !important;
}

.evaluation-group-actual-outcome {
  font-size: $Body-Small !important;
}

.evaluation-info-actual-outcome {
  font-size: $Body-Small !important;
}

.actions-manage-group {
  display: flex;
  flex-direction: row;

  .rename-title {
    color: $amber;
    cursor: pointer;
    margin-right: 1.25rem;
  }

  .remove-title {
    color: $red;
    cursor: pointer;
  }
}

tfoot .ui.secondary.pointing.menu a.item {
  background-color: transparent;
  color: #000000;
  border-radius: 0.25rem;
  box-sizing: border-box;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  border: 0.063rem solid #efefef;
}

tfoot .ui.secondary.pointing.menu a.item.active {
  border: 0 !important;
  background-color: #fff !important;
  color: #ef8123 !important;
  border: 0.063rem solid #ef8123 !important;
}

.ui.search .prompt {
  border-radius: 0.5rem;
  height: 2.857rem;
}

@media only screen and (max-width: 767px) {
  .activity-nav-container {
    height: 4rem !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 100 !important;
  }
}

.mavane {
  background-color: red !important;
}

.activity-nav-container {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 73px;
  width: 100%;
  background-color: white;
  z-index: 100;
  padding: 0.625rem 97px;

  @media (min-height: 250px) {
    position: fixed;
    bottom: -1.563rem !important;
  }

  @media (min-width: 1280px) {
    height: 4rem !important;
    margin-bottom: 1.563rem !important;

    .back-button,
    .next-button,
    .close-button,
    .close-valid-btn,
    .close-reopen-btn {
      margin-bottom: 0.063rem !important;
    }
  }

  @media (max-width: 1440px) {
    height: 4rem !important;
    margin-bottom: 1.563rem !important;

    .back-button,
    .next-button,
    .close-button,
    .close-valid-btn,
    .close-reopen-btn {
      margin-bottom: 0.063rem !important;
    }
  }

  .back-button,
  .next-button,
  .close-button,
  .close-valid-btn,
  .close-reopen-btn {
    margin-bottom: 0.313rem;
  }

  .ui.button.back-button {
    width: 129px;
    height: 2.857rem;
    background: white;
    border: 0.125rem solid $gradientRight;
    box-sizing: border-box;
    border-radius: 0.25rem;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    color: $gradientRight;
    margin-right: 1.563rem;
    padding: 0rem !important;
  }

  .ui.button.back-button:hover,
  .ui.buttons.back-button .button:hover {
    color: white;
    background: $amber;
  }

  .ui.button.back-button:active {
    color: white;
    background: $amber;
  }
}

.avfield-projected-outputs {
  border-radius: 0.5rem !important;
  height: 2.857rem !important;
}

.avfield-projected-outputs-custom {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  height: 2.857rem !important;
}

.extra-height-container {
  height: 6.25rem;
}

@media only screen and (max-width: 767px) {
  .extra-height-container {
    height: 9.375rem;
  }
}

.core-group-flex {
  display: flex;
  flex-wrap: wrap;
}

.group-check-wrapper {
  display: grid !important;
}

.core-participant-res {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.act-checkbox {
  div {
    display: flex;
    background-color: transparent !important;
  }
}

.objective-goal {
  margin-top: $spacing-lg !important;
}

.question-width {
  width: 60.66%;
}

.custom-question-input {
  background: #ffffff;
  border: 0.031rem solid #c3d3e6;
  box-sizing: border-box;
  border-radius: 0.5rem;
  height: 2.857rem;
}

.request-email-div {
  background: $white !important;
  border-radius: 0.25rem;

  .custom-ques-email {
    display: flex;
    padding: 0.625rem 0 0 $spacing-sm !important;
  }

  .req-text {
    margin-left: 0.625rem;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    color: #000000 !important;
    font-size: $Body-Small !important;
    line-height: 1.125rem;
  }

  .label-email {
    .form-group {
      margin-top: 0rem !important;
      padding: $spacing-sm 1.25rem 0rem $spacing-sm !important;
    }
  }

  .email-input {
    background: #f5f5f5;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;
    height: 2.857rem;
  }

  .email-active {
    border-radius: 0.5rem;
    height: 2.857rem;
  }

  .invalid-feedback {
    margin-top: 0.625rem !important;
  }
}

.custom-ques-input-field {
  margin-left: $spacing-sm;
}

.right-trash {
  display: flex;
  flex-direction: row-reverse;

  .remove-icon-eval {
    position: absolute;
    margin-top: 1.875rem;
    margin-right: 2.188rem;

    .eval-qs-remove {
      color: #757575;
      cursor: pointer;
      text-align: right;
    }
  }
}

.checkbox-padding {
  .form-check {
    padding: 0rem !important;
  }
}

.objectives-selection {
  display: flex;
  flex-direction: row;

  .obj-title {
    padding-right: 156px;
  }
}

.checkbox-container-include-in-report {
  display: flex;
  justify-content: flex-end;

  .include-in-report-label {
    margin-right: $spacing-sm;
    font-size: $Body-Default;
    font-weight: 400;
    margin-top: 0.5rem;
  }
}

.disabled-input-checkbox {
  cursor: not-allowed !important;
}

.removed-margin {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.checkbox-container-intercept-only {
  display: flex;
  justify-content: flex-start;

  .intercept-only-label {
    margin-left: 0.5rem;
    font-size: $Body-Default;
    font-weight: 400;
  }
}

.radiobutton-container-intercept-only {
  display: flex;
  justify-content: space-around;
}

.outcome-summary-checkbox-radiobtn-flex-container {
  justify-content: center;
  display: flex;
  width: 100%;
}

.outcome-summary-include-report-flex-item {
  justify-content: flex-start;
  flex-grow: 1;
  margin: auto;
}

.outcome-summary-intercept-flex-item {
  flex-grow: 1;
  justify-content: flex-end;
  margin: auto;
}

.outcome-summary-loading {
  margin-top: 3.75rem !important;
}

.outcome-summary-radiobutton-flex-item {
  flex-grow: 4;

  margin: auto;
}

.actions-charts-container {
  padding: 0rem !important;
}

.evidence-search-checkbox {
  .form-check {
    display: flex;
    margin-top: 0.313rem;
  }
}

.evidence-view-edit-delete-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: $spacing-xl !important;
}

.restrict-container {
  display: flex;
  justify-content: center !important;
}

@media only screen and (max-width: 660px) {
  .ui.search.evidenceSearch {
    display: flex;
    justify-content: center;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .add-evidence {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 0.625rem !important;
    align-content: center !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .add-evidence-container {
    justify-content: center;
    padding-left: 0rem !important;
    padding-top: 0rem !important;
    padding-right: 0rem !important;
  }

  .add-evidence {
    justify-content: center;
    padding-left: 0rem !important;
    padding-top: 0rem !important;
    margin-top: 0.313rem !important;
    padding-right: 0rem !important;
  }

  .evidence-search {
    width: 100% !important;
  }

  .search-inline-single-evidence {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    justify-content: center;
    align-content: center;
    display: flex !important;
    flex-direction: column !important;
  }

  .search-inline-grant-evidence {
    display: flex !important;
    flex-direction: column !important;

    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .restrict-container {
    display: flex;
    justify-content: center !important;
  }
}

.evidence-remove-button {
  margin-right: 2.188rem !important;
}

.report-theory-grid {
  .row-spacing {
    margin: 0.75rem 2.28571rem 0.75rem 2.28571rem !important;
  }

  .header-spacing {
    margin: 0.5rem 0 !important;
    @include Body-Default-Semibold;
  }

  .header-spacing-no-margin {
    display: block !important;
    color: $subLabel !important;
    margin-bottom: 0.75rem !important;
  }
}

.header-spacing {
  margin: 0.5rem 0 !important;
  @include Body-Default-Semibold;
  display: block !important;
}

.domain-font {
  font-weight: 600 !important;
}

.report-objective-list {
  @include Body-Default;
  display: list-item !important;
  margin-bottom: 1rem !important;
  margin-left: 1.125rem;
}

.intended-outcomes-col-spacing {
  margin-left: 0rem !important;
}

.intended-outcomes-row-spacing {
  margin-bottom: 1.25rem !important;
}

.objective-title {
  margin-left: 1rem !important;
  margin-bottom: 1.25rem !important;
  margin-top: 0.625rem !important;
}

.primary-title {
  @include Body-Default($Text-Secondary);
  margin-bottom: 1rem !important;
}

.secondary-title {
  @include Body-Default($Text-Secondary);
  margin-bottom: 1rem !important;
}

.evidence-types-used-grants-col {
  margin: 0.75rem 2.28571rem 0.75rem 2.28571rem !important;
}

.outcome-summary-selector {
  display: flex;
  align-items: baseline !important;

  .form-group {
    margin-bottom: 0rem !important;
  }

  .outcome-eval-plan-radio {
    padding-left: 2rem;

    label {
      font-size: $Body-Default !important;
    }
  }

  .method-selector {
    width: 175px !important;
    height: 2.857rem !important;
    border: 0.031rem solid #c3d3e6;
    border-radius: 0.5rem !important;
  }

  input {
    margin-bottom: 0rem !important;
  }

  label {
    margin-left: 0.438rem;
    margin-right: $spacing-sm;
  }
}

.act-footer {
  .close-button,
  .Primary-Button {
    @include set-margin($spacing-2xl, 0rem, 0rem, $spacing-lg);
  }

  .Secondary-Button {
    @include set-margin($spacing-2xl, 0rem, 0rem, $spacing-lg);
  }

  .footer-buttons {
    margin-bottom: 1.563rem !important;

    button.ui.button.Secondary-Button {
      width: 8.063rem;
    }

    button.ui.button.Primary-Button {
      width: 8.063rem;
    }
  }

  .survey-button:hover {
    color: $amber !important;
  }

  .survey-button {
    font-family: $fontFamily !important;
    border: 0.125rem solid $gradientRight !important;
    border-radius: 0.25rem !important;
    background-color: $white !important;
    color: $gradientRight !important;
    line-height: 0.75rem !important;
    text-align: center !important;
    margin-left: 0.75rem;
    height: 2.857rem;
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
  }

  .survey-button:hover {
    color: $amber !important;
  }

  .see-more-see-less-btn {
    width: 9.375rem;
    font-family: $fontFamily !important;
    color: $gradientRight !important;
    line-height: 0.75rem !important;
    text-align: center !important;
    margin-left: 0.75rem;
    height: 2.857rem;
    font-weight: 600 !important;
    background-color: transparent;
    border: 0.063rem solid transparent;
    text-align: left !important;
    padding: 0rem !important;
  }
}

.outcome-summary-heading {
  font-size: $Body-Default !important;
  color: $gradientRight !important;
  font-weight: 600 !important;
  margin-left: 1.0625rem !important;
}

.outcome-summary-border {
  border: 0.063rem solid #d6d6d6 !important;
}

.objective-margin-bottom {
  margin-bottom: 0.625rem !important;
  margin-top: 1rem !important;
  padding-left: $spacing-xl !important;
}

.report-eval-plan-top-border {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  border: 0.063rem solid #d6d6d6 !important;
  margin-top: 2.5rem !important;
}

.evidence-save-button {
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  background-color: $gradientRight !important;
  color: $white !important;
  width: 93px !important;
  height: 2rem !important;
  line-height: 0.625rem !important;
}

.evidence-textarea {
  font-size: $Body-Default !important;
  min-height: 10em !important;
}

.evidence-view-textarea {
  font-size: $Body-Small !important;
  height: 12.5rem !important;
}

.evidence-modal-checkbox-label {
  font-size: $Body-Small !important;
}

.create-new-evidence-modal {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  line-height: 1.75rem !important;
  height: 4rem !important;
}

.edit-evidence-modal {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  line-height: 1.75rem !important;
  height: 4rem !important;
}

.library-evidence-edit-alert {
  background-color: #ffebee !important;
  border-radius: 0.25rem !important;
  color: #d50000 !important;
  padding: 0.625rem 1rem !important;
  margin-top: -0.938rem !important;
}

.grants-modal-header {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  margin-top: 0.375rem !important;
}

.grants-modal-content {
  background-color: $white !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
}

.grants-modal-close {
  width: fit-content;
  float: right;
  font-size: $h5 !important;
  margin-right: 1.125rem !important;
  margin-top: 1.125rem !important;
  cursor: pointer !important;
}

.grants-modal-close-btn {
  font-family: $fontFamily !important;
  border: 0.063rem solid $gradientRight !important;
  font-weight: 400 !important;
  color: $gradientRight !important;
  background-color: transparent !important;
  height: 2rem !important;
  line-height: 0.5rem !important;
}

.grants-modal-confirm-btn {
  font-family: $fontFamily !important;
  background-color: $gradientRight !important;
  color: $white !important;
  font-weight: 400 !important;
  height: 2rem !important;
  line-height: 0.5rem !important;
  margin-left: 0.938rem !important;
}

.grants-modal-label {
  font-size: $Body-Small !important;
  font-weight: 400 !important;
}

.grants-modal-textarea {
  border-radius: 0.5rem !important;
  font-size: $Body-Small !important;
}

.grants-modal-buttons {
  margin-top: 26px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}

.grants-modal-guideline {
  margin-top: -0.313rem !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  color: #8c8c8c !important;
}

.grants-error-msg-container {
  margin-top: -0.313rem !important;

  .grants-error-msg-title {
    color: #d50000 !important;
  }

  .grants-error-msg {
    background-color: #ffebee !important;
    border-radius: 0.25rem !important;
    color: #d50000 !important;
  }
}

.percentage {
  display: flex;
  flex-direction: row;

  .per-right {
    margin-left: 0.25rem;
  }
}

.scale {
  font-size: $Body-Small !important;
  color: #757575;

  label {
    font-size: $Body-Small !important;
    margin-bottom: 0rem !important;
  }
}

.icon-org-summary {
  cursor: pointer;
}

.eval-plan-objective-row-documentation {
  margin-top: 0.625rem !important;
}

.eval-plan-eval-grp-row-documentation {
  margin-top: 0.625rem !important;
}

.eval-plan-eval-info-row-documentation {
  margin-top: 0.625rem !important;
}

.modal-cancel {
  background-color: transparent !important;
  border: 0.063rem solid $gradientRight !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
}

.modal-cancel:hover {
  background-color: $gradientRight !important;
  border: 0.063rem solid $gradientRight !important;
  color: $white !important;
}

.modal-delete {
  background-color: transparent !important;
  border: 0.063rem solid $gradientDelete !important;
  color: $gradientDelete !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
}

.modal-delete:hover {
  background-color: $gradientDelete !important;
  border: 0.063rem solid $gradientDelete !important;
  color: $white !important;
}

.modal-proceed {
  background-color: $gradientRight !important;
  border: 0.063rem solid $gradientRight !important;
  color: $white !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
}

.change-ownership-form {
  margin: 0rem !important;

  .data-section {
    padding: 0 !important;
    margin-top: 10px !important;
  }

  .title {
    font-family: $fontFamily !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Default !important;
    line-height: 1.5rem !important;
    color: black !important;
    margin-bottom: $spacing-lg !important;
  }

  .info-text {
    font-family: $fontFamily !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 150% !important;
    color: #8c8c8c !important;
  }

  .form-group {
    label {
      font-family: $fontFamily !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: $Body-Small !important;
      line-height: 1.25rem !important;
      color: black !important;
    }
  }
}

.change-ownership-model {
  .form-spacing {
    padding: 0rem $spacing-lg !important;
    margin-bottom: 20px !important;

    .title {
      margin-top: 1rem;
    }
  }

  .column {
    // padding: 0rem !important;
  }

  .content {
    padding: 0rem !important;
  }
  .data-section {
    .row {
      flex-wrap: nowrap !important;

      column-gap: 16px;
    }
  }
  .activity-model-buttons {
    margin: 1rem 1rem;
  }
}

.button-include {
  .ui.button {
    background: #f2f2f2;
    border: 0.063rem solid #e0e0e0 !important;
    box-sizing: border-box;
    height: 2.125rem !important;
    width: 3.75rem !important;
    font-size: $Body-Small !important;
  }

  .ui.active.button {
    background: $amber !important;
    color: #ffffff !important;
    border: 0.063rem solid $amber !important;
  }
}

.pre-line {
  white-space: pre-line;
}

.industry-list-container {
  .ui.segment {
    padding: 0 !important;
  }
}

.super-admin-table {
  background-color: $White !important;
}

.super-admin-system-log-table {
  width: 130% !important;
  background-color: $White !important;
}

.blocked-badge {
  @include Body-Default;
}

.demo-badge {
  @include Body-Default;
}

.evidence-block-badge {
  @include Body-Default;
}

.ui.basic.grey.label {
  @include Body-Default;
}

.disabled {
  color: #acacac !important;

  .active-status-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .active-status {
      color: $green !important;
    }

    .block-status {
      color: $red !important;
    }
  }
}

.daterangepicker thead tr td,
.daterangepicker tbody tr td {
  border: 1px solid rgba(34, 36, 38, 0.1) !important;
  background-color: #fff !important;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  font-family: 'Inter' !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  border-radius: 0rem !important;
}

.daterangepicker td.in-range {
  border: 0.063rem solid #e0e0e0 !important;
}

.daterangepicker .calendar-table th {
  background-color: #f9fafb !important;
  border-radius: 0rem !important;
}

.daterangepicker .drp-selected,
.daterangepicker .drp-buttons .btn {
  font-family: 'Inter' !important;
  border-radius: 1rem !important;
  border: none !important;
}

.daterangepicker .drp-calendar.right {
  padding: 0rem 0rem 0rem 1rem !important;
}

.daterangepicker .drp-calendar.left {
  padding: 0rem 0rem 0rem 0rem !important;
}

.daterangepicker select.monthselect .daterangepicker select.yearselect {
  border-radius: 1rem !important;
  border: 0.1rem solid #ef8123 !important;
  color: #636363 !important;
}

.daterangepicker .ranges li {
  font-family: $fontFamily !important;
}

.daterangepicker .ranges li.active {
  background-color: #ef8123 !important;
}

.invite-green-form {
  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0.625rem !important;
  }

  .radio-green-light {
    font-size: $Body-Small !important;
  }
}

.invitation-GreenLight {
  padding: 0.313rem 0rem 0rem 0.938rem;

  label {
    font-size: $Body-Small !important;
  }
}

@mixin segment-container {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .column:first-child {
    padding-right: $spacing-lg !important;
  }

  label {
    margin-bottom: 0.25rem !important;
  }
}

//new styles
.organization-goal-to-be-addressed {
  @include segment-container;

  .column:first-child {
    padding-right: 3.429rem !important;
  }

  .pre-line,
  .show-more-less-clickable {
    @include Body-Small($Gray-70);
  }
}

.set-objective {
  .column:first-child {
    padding-right: 3.429rem !important;
  }

  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .objective-select-spacing {
    label {
      margin-bottom: 0.25rem !important;
    }
  }

  .objective-description {
    margin-top: 0rem !important;
  }
}

.evidence-about-object {
  @include segment-container;

  .evidence-t {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: $Body-Default !important;
    line-height: 1.5rem !important;
    color: #000000 !important;
    margin-bottom: $spacing-lg !important;
  }
}

.Evidence_supporting_the_grants_program {
  @include segment-container;
}

.program-activity-description {
  .ui.grid {
    column-gap: 80px !important;
  }

  .form-group {
    margin-bottom: 0rem;
  }

  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;

    .column {
      padding-right: 1.429rem !important;
    }
  }

  .ui.segment.content-segment {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }

  .select-festival-type {
    margin-bottom: $spacing-lg;
  }

  .form-check {
    padding-left: 0rem !important;
  }
}

.ui.raised.segments.Program_Description {
  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }

  .column {
    padding-right: $spacing-lg !important;
  }

  .content-segment {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }

  .festival-type {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }

  .form-group {
    margin: 0rem !important;
  }
}

.program-report {
  max-height: 1300px !important;
  overflow: scroll;
  .row {
    margin: $spacing-lg $spacing-xl 1.7rem $spacing-xl !important;
  }
  .ui.grid.segment-margin {
    margin-top: -100px !important;
  }
  &.open-panel {
    padding-left: 300px !important;
  }
}

.create-theory-of-change {
  form.activity-form.av-valid {
    .row {
      margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
    }

    .form-group {
      margin-bottom: 0rem !important;
    }

    .Primary-Button {
      margin-top: $spacing-xl;
      margin-bottom: 1.25rem;
    }
  }
}

.theory-of-change {
  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }

  .report-activity-seg {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }
}

.ui.raised.segments.Activity_Members {
  .add-member-column {
    padding: 1rem $spacing-xl 1rem $spacing-xl !important;
  }
}

.ui.raised.segments.Grants_Program {
  @include segment-container;

  .button-segment {
    display: flex;
    align-items: baseline;
  }
}

.activity-approval {
  .Primary-Button {
    margin-left: $spacing-xl;
    margin-bottom: $spacing-lg;
    margin-top: 0.75rem;
  }

  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }

  .approve-lbl {
    margin: $spacing-lg 0rem 0.75rem $spacing-xl;
  }

  .approval-active-data {
    label {
      margin-bottom: 0rem !important;
    }
  }

  .greenlight-approved {
    margin: 0rem !important;
    column-gap: $spacing-lg;
  }

  .form-group {
    margin-bottom: 0.5rem !important;
  }
}

.projected-inputs {
  .row {
    margin: 0rem $spacing-xl 0rem $spacing-xl !important;
  }

  .grid {
    row-gap: $spacing-lg !important;
    column-gap: $spacing-xl !important;
    padding: $spacing-lg 0rem $spacing-lg 0rem !important;
  }

  .extra-padding-sides {
    padding: 1rem $spacing-xl 1rem $spacing-xl !important;
  }

  .ui.table {
    margin: 0rem !important;
  }

  .avfield-income {
    width: 100% !important;
  }

  .icon-inputs {
    input[type='text'] {
      border-radius: 0rem 0.5rem 0.5rem 0rem !important;
    }
  }

  .current-member-label {
    display: block !important;
  }
}

.key-activity-data,
.analysis-and-reflection {
  .report-activity-seg {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }

  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }
}

.key-activity-data-column {
  .display-row {
    display: flex;
    flex-direction: column;
  }
  .column {
    padding-bottom: 8px !important;
    padding-right: 16px !important;
  }
}

.ui.raised.segments.actual-outputs {
  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
    column-gap: $spacing-lg !important;
  }

  .align-left-avg-resp {
    padding-left: 1.125rem;
  }

  .income-div {
    margin: 0.75rem 0rem !important;
  }

  .income-title {
    margin: 0.75rem 0rem !important;
  }
}

.key-activity-data-row {
  .display-row {
    display: flex;
    flex-direction: column;
  }
  .column {
    padding-bottom: 24px !important;
    padding-right: 16px !important;
  }
}

.row.key-activity-data-row-reduce {
  margin-top: -8px !important;
}

.activity,
.ui.raised.segments.Reflection,
.objective_ {
  .report-activity-seg {
    padding: 0.75rem 0rem 0.75rem 0rem !important;
  }

  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }

  .info-title {
    @include Body-Small($Text-Secondary);
  }
}

.actual-outcomes {
  .results-row {
    margin-top: 2rem !important;
  }

  .evaluation-heading {
    margin: $spacing-lg 0rem $spacing-lg $spacing-xl;
  }

  ul {
    padding-left: 17px !important;
    margin-bottom: 0rem !important;
  }
}

.actual-inputs {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }
}

.activity-on-a-page {
  .row {
    margin: 1.25rem 0rem 1.25rem 0rem !important;
  }
}

.projected-outputs {
  .grid {
    margin: 0rem !important;
  }

  .outputs-row {
    .row {
      column-gap: $spacing-lg !important;
    }
  }

  .row {
    margin: 0.75rem $spacing-xl 0.75rem $spacing-xl !important;
  }

  .column {
    margin: 0.75rem 0rem !important;
  }

  .form-check-input {
    margin-top: 3.4px !important;
  }

  .outcome-border {
    padding: $spacing-lg $spacing-xl !important;

    .outputs-col-div {
      padding-bottom: $spacing-lg !important;
    }

    border-bottom: 0.063rem solid $Gray-40;
  }

  .row {
    column-gap: $spacing-sm;
  }

  .form-check {
    padding-left: 0rem !important;
  }
  .form-check-inline {
    align-items: flex-start;
    margin-top: 1.8rem !important;
  }
  .multi-checkbox-group {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  }
  .multi-checkbox-container {
    display: flex;
    flex-direction: column;
  }
}

.core-participant-group {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .Secondary-Button {
    margin-top: $spacing-sm;
  }

  .outputs-segment {
    padding-bottom: 0rem !important;
  }

  .lable {
    margin-bottom: 0rem !important;
  }
}

.create-evaluation-plan {
  padding: 0.25rem $spacing-xl 0.25rem $spacing-xl !important;

  .column {
    padding-left: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .row {
    margin: 1.25rem 0rem !important;
  }

  .form-group {
    margin-bottom: 0rem !important;
  }

  .form-check-inline {
    margin-right: 2.25rem !important;
  }

  .form-check-inline .form-check-input {
    margin-right: 0rem !important;
  }
}

.green-light-report {
  .ui.grid {
    padding: 1.25rem $spacing-xl 0rem $spacing-xl !important;
  }

  .objective-margins,
  .heading-margin {
    margin: 0rem !important;
  }
}

.icon-input-filed {
  input[type='text'] {
    border-radius: 0rem 0.5rem 0.5rem 0rem !important;
  }
}

.activities {
  .label {
    @include Body-Default($black);
  }
}

.manage-reference-lbl,
.manage-reference-lbl:hover {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */
  text-align: right;
  /* WhiteBox/Primary */
  color: #ef8123;
  cursor: pointer;
}

.program-activity-description {
  .select-ref-header {
    @include Body-Default;
    padding-bottom: 1rem;
  }

  .form-group {
    margin: 0rem !important;
  }

  .select-ref {
    margin-top: 40px;
    padding-right: 0rem $spacing-lg 0rem 0rem !important;
  }

  .select-ref-seg {
    .column {
      padding: 0rem !important;
    }
  }

  li.ref-item:first-child {
    margin-top: 1rem !important;
  }

  li.ref-item {
    margin-top: 1rem;
    @include Body-Default;
  }

  .tablet.column {
    padding-top: 0rem;
  }

  .three.column.row {
    padding-bottom: 0rem;
  }

  .column {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.evaluation-plans {
  .eval-plan-label {
    margin-bottom: 1.2rem !important;
  }
  .culture-count-btn {
    margin-bottom: 1.143rem;
  }
}

.select-ref-model {
  .content {
    padding: 1rem $spacing-lg $spacing-lg $spacing-lg !important;
  }

  label {
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    font-weight: 400 !important;
    margin-top: 1rem !important;
    margin-bottom: 0rem !important;
  }
  .reference-checkbox {
    display: flex;
    .reference-label {
      margin-top: 16px;
      margin-left: 8px;
    }
  }
}

.survey-seg {
  .ui.raised.segments {
    box-shadow: none !important;
  }

  .report-eval-plan-top-border {
    padding: $spacing-lg $spacing-lg !important;
  }

  .obj-div.inside-question {
    padding: $spacing-sm $spacing-lg 0rem $spacing-lg !important;
  }
  .checkbox-margin {
    display: flex;
    margin-right: 0.313rem;
    margin-top: 0.625rem;
  }
  .checkbox-container {
    display: flex;
    margin-right: 0.313rem;
  }
  .checkbox-label {
    margin-top: -0.18rem;
    margin-left: 0.5rem;
  }
}

.new-self-assess {
  .form-background {
    background-color: #fafafa;
    border: 0.063rem solid #eeeeee;
    border-radius: 4px;

    .outcome-btn {
      margin: 0rem $spacing-lg $spacing-lg $spacing-lg;
    }

    .ui.raised.segments {
      box-shadow: none !important;
    }
  }

  .plan-documentation {
    margin: 0rem !important;
  }

  .self-eval-header {
    font-size: $Body-Default !important;
    margin-top: 16px !important;
    color: $black !important;
  }

  .self-eval-header.method {
    margin-bottom: $spacing-sm !important;
    margin-top: 4px !important;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .number-participants-input {
    margin-bottom: 0rem !important;
  }

  .column {
    padding: 0rem !important;
  }

  .ui.segment {
    padding: 0rem;
    background-color: #fafafa;
  }

  .ui.grid {
    margin: 0rem;
  }

  .form-check-input {
    margin-top: 2px !important;
  }

  .url-card {
    padding: $spacing-lg $spacing-lg $spacing-lg $spacing-lg;
    background-color: #fafafa;
  }

  .survey-gap {
    background-color: #fafafa;
  }

  .form-control {
    background-color: transparent;
  }
}

.projected-lbl {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 20px;
  color: #f44336;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 10px;
  position: absolute;
  width: 70px;
  height: 20px;
  background: #ffebee;
  border-radius: 4px;
}

.projected-contractor-cost {
  box-sizing: border-box;
  position: absolute;
  background: #ffffff;
  border-radius: 0.5rem;

  .dollar {
    background: #ffffff;
  }

  i.circular.icon {
    width: 1.25rem !important;
    height: 1.25rem !important;
    padding: 0.14rem 0 !important;
    margin: 0.7rem 0.85714rem 0.6rem 0.85714rem !important;
    opacity: 1 !important;
  }

  i.inverted.circular.icon {
    background-color: $infoText !important;
  }

  .ui.label {
    padding: 0rem !important;
  }
  .ui.basic.label {
    border-color: $Error !important;
    border-radius: 0.571rem 0rem 0rem 0.571rem !important;
  }
  input[type='text'] {
    border-radius: 0rem 0.571rem 0.571rem 0rem !important;
    border-color: $Error !important;
  }
}

.inKindSupport-partner-cost {
  box-sizing: border-box;
  position: absolute;
  background: #ffffff;
  border-radius: 0.5rem;

  i.circular.icon {
    width: 1.25rem !important;
    height: 1.25rem !important;
    padding: 0.14rem 0 !important;
    margin: 0.7rem 0.85714rem 0.6rem 0.85714rem !important;
    opacity: 1 !important;
  }
  i.inverted.circular.icon {
    background-color: $infoText !important;
  }
  .ui.label {
    padding: 0rem !important;
  }
  .ui.basic.label {
    border-color: $Primary !important;
    border-radius: 0.571rem 0rem 0rem 0.571rem !important;
  }
  input[type='text'] {
    border-radius: 0rem 0.571rem 0.571rem 0rem !important;
    border-color: $Primary !important;
  }
}

.input-icon-border-right {
  i.circular.icon {
    width: 1.25rem !important;
    height: 1.25rem !important;
    padding: 0.14rem 0 !important;
    margin: 0.7rem 0.85714rem 0.6rem 0.85714rem !important;
    opacity: 1 !important;
  }
  i.inverted.circular.icon {
    background-color: $infoText !important;
  }
  .ui.label {
    padding: 0rem !important;
  }
  .ui.basic.label {
    border-color: #c3d3e6 !important;
    border-radius: 0.571rem 0rem 0rem 0.571rem !important;
  }
  input[type='text'] {
    border-radius: 0rem 0.571rem 0.571rem 0rem !important;
  }
}

.error-msg-div {
  @include field-err;
}

.error-msg-hidden {
  display: none;
}

.hr-w-100 {
  width: 100%;
}

.project-partner-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Default;
  line-height: 20px;
}

.culture-count-container {
  width: 234px;

  .href.label {
    display: 'table' !important;
  }
}

.eval-culture-count-container {
  width: 234px;
  margin-right: 25px;

  .href.label {
    display: 'table' !important;
  }
}

.eval-culture-count {
  background: #ef8123;
  border: 1px solid #ef8123;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  flex-direction: row;
  border-radius: 4px;
  background-color: transparent;

  font-family: $fontFamily;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  font-size: $Body-Default;
  text-align: center;
  width: 234px;
  height: 42px;
  color: $gradientRight;
}

.eval-culture-count:hover {
  background-color: rgba(239, 129, 35, 0.1);
}

.manage-activity-members {
  .custom-segment {
    border-bottom: none !important;
  }

  .add-goal-custom {
    display: flex;
    justify-content: flex-end;
  }
}

.segment-custom-heading-evidence {
  @include Body-Default-Semibold(#3b3b3b);
  margin-bottom: 8px;
}

.evaluation-segment {
  .right.aligned.middle.aligned.eight.wide.computer.sixteen.wide.mobile.eight.wide.tablet.column {
    display: flex;
    align-content: flex-end;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.linkedActivity-eval-plan {
  padding: 1.14286rem 2.28571rem 1.7142857rem 2.28571rem !important;
  .column {
    padding: 0rem !important;
  }
  .ui.grid .org-sort {
    padding: 0rem !important;
  }
  .eval-plan-desc {
    margin-bottom: 1.143rem;
  }
}

.evaluation-model {
  i.grey.dollar.sign.circular.inverted.icon {
    height: 20px !important;
    width: 20px !important;
    margin-top: 4px;
  }

  .ui.grid + .grid {
    margin-top: 0rem !important;
  }

  .goal-label {
    @include Body-Default($Text-Secondary);
  }

  .evidence-modal-label {
    @include Body-Default;
  }

  .right.aligned.middle.aligned.column {
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
  }
}

.survey-for-test-type {
  .ui.segments {
    padding: $spacing-lg !important;
  }

  .survey-gap {
    padding: 0rem $spacing-lg !important;
  }

  .ui.grid.custom-gen-questions {
    .row {
      padding: 0.857rem 0rem 0.857rem 0rem !important;
    }
  }

  .ui.stacked.segment:after {
    height: 0rem !important;
    bottom: 0rem !important;
  }

  .ui.segments > .segment {
    border: none;
  }

  .Primary-Button {
    padding: 0rem 4.571rem !important;
  }
}

.rc-slider-dot-active {
  background-color: $amber !important;
  border-color: $amber !important;
}

.rc-slider-handle,
.rc-slider-handle:hover,
.rc-slider-handle:focus {
  background-color: $amber !important;
  border-color: $amber !important;
  border: solid 2px $amber;
}

element.style {
  left: 35%;
  right: auto;
  transform: translateX(-50%);
}

/* Media Query for Mobile Devices */
@media (max-width: 990px) {
  .mainRow {
    padding: 3% 2% !important;
    min-height: 100vh !important;
  }

  .rc-slider-mark-text {
    font-size: 0.7rem !important;
  }

  .survey-for-test-type {
    .ui.segments {
      padding: $spacing-sm !important;
    }

    .survey-gap {
      padding: 0rem $spacing-sm !important;
    }

    .ui.grid.custom-gen-questions {
      .row {
        padding: 0.857rem 0rem 0.857rem 0rem !important;
      }
    }

    .ui.stacked.segment:after {
      height: 0rem !important;
      bottom: 0rem !important;
    }

    .ui.segments > .segment {
      border: none;
    }

    .Primary-Button {
      padding: 0rem 4.571rem !important;
    }
  }
}

.add-a-new-goal,
.edit-a-goal {
  .content {
    padding: 0rem !important;
  }

  thead.full-width {
    th {
      @include Body-Default-Semibold;
    }
  }

  .model-space,
  .model-buttons {
    padding: $spacing-lg !important;
  }

  .org-goals-policy-table {
    :hover {
      background: $White;
    }
  }

  .Primary-Button {
    width: 76px;
  }
  .error-text {
    padding-left: $spacing-lg !important;
  }

  td:first-child,
  th:first-child {
    padding-left: $spacing-lg !important;
  }

  th:last-child,
  td:last-child {
    padding-right: $spacing-lg !important;
  }
}

.group-management {
  .content {
    padding: 0rem !important;
  }
}
.close-confirmation-modal {
  .model-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .button {
    margin-left: 0rem !important;
  }
}

.form-group {
  width: 100%;
}

.recordCode {
  width: 250px;
}

.modal > .ui.dropdown .scrolling.menu,
.ui.scrolling.dropdown .menu {
  max-height: 6.61rem;
}

.ui.selection.dropdown .menu {
  // max-height: 6.61rem;
}

@media only screen and (min-width: 992px) {
  .ui.selection.dropdown .menu {
    // max-height: 6.61rem;
  }
}

.approved-lbl {
  width: 46px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  padding: 2px 12px;
  color: #05944f;
  background: #e8f5e9;
  border-radius: 4px;
}

.pending-lbl {
  width: 39px;
  height: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  padding: 2px 12px;
  color: #ffc043;
  background: #fff7da;
  border-radius: 4px;
}

.approve-btn {
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 20px;
  color: #ffffff !important;
  background: #05944f !important;
  border-radius: 4px;
}

.review-btn {
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 20px;
  color: #ffffff !important;
  background: #c62828 !important;
  border-radius: 4px;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  cursor: not-allowed !important;
  pointer-events: none !important;
  background: #bdbdbd !important;
  border: 0.063rem solid #bdbdbd !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #ffffff !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 0.45;
  cursor: not-allowed !important;
}

.outcome-Summary {
  .form-group {
    width: auto !important;
  }
  .report-new {
    margin-top: 0rem !important;
  }

  .outcome-summary-selector {
    margin: 1.5rem 0rem;
  }
}
.projected-linked-activity {
  .ui.raised.segments .custom-segment {
    padding: 0.8rem 1.714rem !important;
  }
  .report-activity-seg {
    padding: 0rem 1.714rem !important;
  }
}

.actual-output-checkbox {
  display: flex;
  justify-content: center;
  i {
    margin-top: 3px;
  }
}

.activity-on-a-page-menu > .menu {
  left: -7px !important;
  top: 28px !important;
}

.request-approval-dropdown > .menu {
  max-height: 7.028571rem !important;
}

.document-custom-tab > .ui.segment {
  position: relative;
  background: #fff;
  box-shadow: 0 0 0 0 transparent !important;
  margin: 1rem 0;
  padding: 0rem 0.5rem;
  border-radius: 0.28571429rem;
  border: none !important;
}

.eval-que-header {
  padding-left: 1rem;
}

.eval-que-sub-header {
  @include font-style(1rem, $info, 600);
}

.upload-btn {
  background-color: $gradientRight !important;
  margin-bottom: $spacing-sm !important;
  margin-right: $spacing-xs !important;
  @include font-style(1rem, $white, 600);
  color: $white !important;
}

.graph-div-evaluation {
  margin-left: 1.8rem !important;

  .graph-tabs-documentation {
    margin-top: -2.857rem !important;
  }

  .avg-eval-plan {
    padding: $spacing-lg 0rem 0rem 0rem !important;
    div {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-end !important;
      height: 100% !important;
      vertical-align: bottom !important;
      padding: 0 !important;
      margin: 0 !important;

      li {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  .include-in-report-eval-plan {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }
}

.qr-activityId {
  font-size: 1rem;
  color: $subLabel;
}

.qr-planName {
  font-size: 1rem;
}

.add-linked-btn {
  @include btn;
  background: $white !important;
  color: $Primary !important;
  border-color: $Primary !important;
  outline: 0;

  &:hover {
    border-color: $Primary !important;
    // background-color: $Primary-lighter !important;
    color: $Primary !important;
  }

  &:active {
    border-color: $Primary !important;
    color: $Primary !important;
  }
  &:focus {
    outline: 0;
  }

  .ui.dropdown > .left.menu {
    left: -17px !important;
    right: 0 !important;
    top: 31px !important;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.report-year-text {
  display: flex;
  flex-direction: column;
  .report-label {
    color: #757575;
    font-weight: 400;
  }
}

.report-year-text-partner {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 6px;
  .report-label {
    color: #757575;
    font-weight: 400;
  }
  .report-space {
    margin-left: 5px;
  }
}
