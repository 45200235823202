@import '../../variables.scss';
@import '../../mixins.scss';

.activities-filter-container {
  display: flex;
  flex-direction: row;

  .menu-dropdown {
    margin: auto;
  }
}

.activities-filter {
  @include Body-Default;
  display: flex;
  align-items: center;
  line-height: 1.25rem;
  margin-left: 1.563rem;
}

.activity-confirm-cancel {
  width: 5.875rem !important;
  height: 2.25rem !important;
  border: 0.063rem solid #ef8123 !important;
  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  background: white !important;
  color: #ef8123 !important;
}

.activity-confirm-confirm {
  width: 4.25rem !important;
  height: 2.25rem !important;
  background: #ef8123 !important;
  border: 0.125rem solid #ef8123 !important;
  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  color: #ffffff !important;
}

.objective-warning {
  .ui.button {
    // width: 5.875rem !important;
    // height: 2.25rem !important;
    border: 0.063rem solid #ef8123 !important;
    background: white !important;
    color: #ef8123 !important;
  }

  .ui.primary.button {
    background: #ef8123 !important;
    color: #ffffff !important;
  }
}

.activity-confirm-modal-content-text {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Default !important;
  line-height: 1.5rem !important;
  color: #000000 !important;
}

.steps-container {
  width: 100%;
  position: absolute;
}

@media only screen and (max-width: 1624px) {
  .steps-container {
    position: relative;
  }
}

.mange-groups-item {
  padding: 0.625rem;
}

.goal-confirm-modal-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  font-size: $Body-Default !important;
  height: 4rem !important;
  background-color: $white !important;
}

.warning-header-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.5rem;
}

.warning-content-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Default !important;
  line-height: 1.5rem !important;
}

.goal-confirm-modal-body {
  font-size: $Body-Default !important;
  line-height: 1.875rem !important;
  color: $black !important;
  padding: 1rem $spacing-lg !important;
  background-color: transparent !important;
}

.goal-confirm-modal-actions {
  background-color: $white !important;
}

.slim-close-icon {
  display: flex !important;
  font-size: $h5 !important;
  cursor: pointer !important;
}

.goal-confirm-button {
  width: 4.25rem !important;
  height: 2rem !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background-color: $gradientRight !important;
  font-family: $fontFamily !important;
  line-height: 0.375rem !important;
  color: $white !important;
  border: 0.125rem solid $gradientRight !important;
  border-radius: 0.25rem !important;
}

.goal-cancel-button {
  width: 4.25rem !important;
  height: 2rem !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  font-style: normal !important;
  background-color: transparent !important;
  font-family: $fontFamily !important;
  line-height: 0.375rem !important;
  color: $gradientRight !important;
  border: 0.063rem solid $gradientRight !important;
  padding-left: 0.563rem !important;
  border-radius: 0.25rem !important;
}

.custom-tabs1-act,
.parent-act-tab {
  width: inherit;

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.secondary.pointing.menu .tab-item {
    flex: 1;
    justify-content: center;
    background-color: $white !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    @include Body-Small-Semibold;
    padding: 0 !important;
    border-bottom-color: #efefef !important;
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    margin-bottom: 0rem !important;
  }
}

// .custom-tabs1-act .ui.secondary.pointing.menu .active.item {
//   border-bottom: 0.125rem solid #ef8123 !important;
//   color: #ef8123 !important;
// }

.ui.secondary.pointing.menu .active.item {
  color: #ef8123 !important;
  border-bottom-color: #ef8123 !important;
}

.custom-tabs1-admin {
  width: inherit;

  .ui.secondary.pointing.menu {
    border-bottom: none;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 0.125rem solid $gradientRight !important;
    color: $gradientRight !important;
  }

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.menu {
    display: flex !important;
    flex-wrap: wrap !important;
    width: inherit;
    height: inherit;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 1rem !important;
    background-color: $white;
  }

  .ui.secondary.pointing.menu .item {
    flex: 1;
    justify-content: center;
    background-color: $white !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    border-bottom-color: #efefef !important;

    font-family: Inter !important;
    font-size: $Body-Small !important;
    font-weight: 600 !important;
    line-height: 2.5rem !important;
    padding: 0 !important;
    font-style: normal !important;
  }

  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }

  .item.disabled {
    border: none;
  }
}

.act-tab {
  .ui.secondary.pointing.menu .item {
    align-self: initial;
  }
}

.parent-act-tab {
  .ui.secondary.pointing.menu .item {
    align-self: initial;
    // background-color: $parentTab !important;
  }
  .custom-tabs1-act .ui.secondary.pointing.menu .tab-item,
  .ui.secondary.pointing.menu .tab-item {
    background-color: $parentTab !important;
  }
}

.single-act-tab {
  .custom-tabs1-act .ui.secondary.pointing.menu .tab-item,
  .single-act-tab .ui.secondary.pointing.menu .tab-item {
    background-color: #000000 !important;
  }
}

.tabs-graph {
  width: inherit;

  .ui.secondary.pointing.menu {
    border-bottom: 0.063rem solid #e0e0e0;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 0.125rem solid $gradientRight !important;
    color: $gradientRight !important;
  }

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.menu {
    display: flex !important;
    flex-wrap: wrap !important;
    width: inherit;
    height: inherit;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    background-color: $white;
  }

  .ui.secondary.pointing.menu .item {
    flex: none;
    background-color: #ffffff !important;
    margin: 0rem;
    border-bottom-style: none !important;
    padding: 0.85714286em 1.14285714em !important;
    font-family: Inter !important;
    font-size: $Body-Default !important;
    font-weight: 600 !important;
    line-height: 1.25rem !important;
  }

  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }

  .item.disabled {
    border: none;
  }
}

.custom-tabs-mobile-act {
  width: inherit;

  .ui.secondary.pointing.menu {
    display: block;
  }

  .ui.borderless.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .item {
    width: -webkit-fill-available;
    display: flex !important;
    justify-content: center !important;
    color: $info !important;
    font-family: Inter !important;
    font-size: $Body-Small !important;
    font-weight: 600 !important;
    line-height: 1.188rem !important;
    background-color: $background !important;
  }

  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }
}

.ui.tab.active.custom-tab-act {
  padding: 0 !important;
  background-color: $background !important;
}

.ui.menu .active.item {
  span {
    color: $gradientRight !important;
  }
}

i.inverted.bordered.blue.icon,
i.inverted.circular.blue.icon {
  background-color: $Info !important;
}

i.inverted.bordered.green.icon,
i.inverted.circular.green.icon {
  background-color: $Success !important;
}

i.green.icon {
  color: $Success !important;
}

i.blue.icon {
  color: $Info !important;
}

.customSteps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0rem;

  .customStep-number {
    margin-bottom: 0;
  }

  .customStep-label {
    text-align: center;
    margin: 0.063rem;
  }

  .customStep-icon {
    padding-right: 0.125rem;
  }
}

i.inverted.whitebox.circular.icon {
  background-color: $Primary !important;
  color: #fff !important;
}

i.whitebox.icon.outline {
  color: $gradientRight !important;
}

.act-inputs-test {
  border-radius: 0.5rem !important;
  height: 2.857rem !important;
}

.primary-goal-container {
  display: flex !important;
  background-color: #e8ecef !important;
  align-items: center !important;
}

.act-participant-container {
  display: flex;
  align-items: center;
  height: 100%;

  .act-participant-title {
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Large !important;
    line-height: 1.375rem !important;
  }
}

.library-evidence-modal-description {
  @include Body-Default;
  text-align: justify !important;
}

.participant-users-container {
  display: flex;
  flex-direction: column;
}

.participant-users-mainText {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  line-height: 1.375rem !important;
  font-weight: 300 !important;
}

.participant-users-text {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 300 !important;
  line-height: 1.375rem !important;

  .invitation-status {
    text-transform: capitalize;
  }
}

.evidence-box {
  margin: 0rem 0rem 1.25rem 0rem;

  .evidence-title {
    margin-bottom: 0.313rem;
    padding: 0rem 0rem 0rem 0rem !important;
  }

  .evidence-desc {
    font-size: $Body-Small;
    color: #000000 !important;
    font-weight: 400 !important;
    text-align: justify;
  }
}

.sub-topic {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #757575;
}

.add-goal-custom {
  .closeicon {
    margin-top: 0.313rem;
  }
}

.evidence {
  padding: 0rem !important;
}

.checkbox-title {
  padding-left: 0rem;

  legend {
    font-size: $Body-Small;
  }

  .checkbox-check {
    padding-left: 0rem;
    padding-bottom: 0.313rem;
  }
}

.checkbox-title-eval {
  padding-left: 0rem;

  legend {
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Default;
    line-height: 1.5rem;
    color: #3b3b3b;
    padding-bottom: 0.5rem;
    margin-bottom: 0rem !important;
  }

  .checkbox-check {
    padding-left: 0rem;
    padding-bottom: 0.313rem;
  }
}

.view-plan {
  @include Body-Default($Primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.edit-the-plan {
  @include Body-Default($Primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.preview-the-plan {
  @include Body-Default($Primary);
  cursor: pointer;
}

.eval-remove-new {
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  color: #d6001a;
}

.form-title {
  padding-left: 0rem;

  legend {
    font-size: $Body-Default;
  }

  .form-control {
    background: transparent;
  }

  .form-check {
    padding-left: 0rem;
    padding-bottom: 0.313rem;
  }
}

.export-dropdown {
  margin-top: 1.25rem;
}

.small-text {
  font-size: $Body-Small !important;
}

.loading-segment {
  padding: 3.125rem !important;
}

.custom-segment {
  .export-button {
    margin-top: 0rem;
    margin-right: 0.5rem;
    padding: 0.375rem !important;
    background-color: $gradientRight !important;
    @include font-style($Body-Small !important, $white !important, 400);

    .ui.dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 100% !important;
    }

    .ui.dropdown > .left.menu {
      top: 29px;
      left: -5.5rem !important;
    }

    .ui.ui.dropdown .menu > .item:hover {
      background-color: $background !important;
    }

    .export-btn-icon {
      margin-left: 0.25rem;
    }
  }

  .export-button-evidence {
    background-color: $gradientRight !important;
    width: 11.25rem;
    height: 2rem;
    margin-right: $spacing-xl;
    padding: 0.313rem;

    @include font-style($spacing-sm !important, $white !important, 400);

    .ui.dropdown > .left.menu {
      top: 1-px;
      left: -5.5rem !important;
    }

    .ui.dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 100% !important;
    }

    .ui.ui.dropdown .menu > .item:hover {
      background-color: $background !important;
    }
  }

  .new-title {
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Default;
    line-height: 1.5rem;
    color: #3b3b3b;
  }

  .segment-heading-custom-new {
    @include Body-Default($Primary);
  }

  .segment-title-subTitle-no-line-height {
    line-height: 0.625rem !important;
    margin-top: 0.375rem !important;
    margin-left: 1.125rem !important;
    margin-bottom: -0.375rem !important;
    font-family: Inter;
    font-size: normal;
    font-weight: 600;
    font-size: $Body-Default !important;
  }

  .loading-btn {
    background-color: #fff !important;
  }
}

// .ui.grid > .act-row .activity-sub-column .evaluation-lbl {
//   cursor: pointer;
//   margin-top: 0.625rem;
//   @include font-style(1rem, $gradientRight, 600);
// }
.greenlight-evaluation-method-radio {
  label {
    font-size: $Body-Small !important;
  }
}

.documentation-eval-plan-radio {
  margin-top: 0 !important;
  margin-right: 0.938rem !important;

  label {
    font-size: $Body-Default !important;
  }
}

.no-padding {
  padding: 0rem;
}

.individual-r {
  margin-left: 1.8rem !important;
}

.comments-table {
  max-height: 400px;
  overflow: auto;

  .copy-comment {
    cursor: copy;
    white-space: pre-line;
  }

  .pre-line {
    white-space: pre-line;
  }

  .ui.table td.one.wide,
  .ui.table th.one.wide {
    width: 0.25% !important;
  }

  .ui.table thead tr:first-child > th {
    background: $background !important;
    position: sticky !important;
    top: 0;
  }
}

.doc-evaluation-comments {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-collapse: initial !important;
}

.doc-evaluation-comments th {
  align-items: center;
  padding: 0.625rem 0.5rem 0.625rem 0.688rem !important;
  border: 0.063rem solid #e0e0e0;
}

.doc-evaluation-comments td {
  border-top: 1px solid #dee2e6 !important;

  color: $info;
  font-size: $Body-Default;
  line-height: 1.375rem;
  padding: 0.938rem 0.5rem 0.938rem 0.75rem !important;
}

.doc-evaluation-comments tr {
  border: 0.063rem solid #e0e0e0 !important;
}

.doc-evaluation-comments thead {
  border-left: 0.063rem solid #e0e0e0;
  position: sticky;
  top: 0;
}

.doc-evaluation-comments tbody {
  height: 9.375rem;
  overflow: auto;
}

.doc-evaluation-comments-items {
  text-align: center !important;
}

.survey-results {
  @include Body-Default;
  margin-top: 1.563rem;
}

.participant-download {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;

  .ui.labeled.icon.button > .icon,
  .ui.labeled.icon.buttons > .button > .icon {
    background-color: #ffffff !important;
  }

  .ui.button:hover {
    background: #ffffff !important;
  }

  .ui.button:focus {
    background: #ffffff !important;
  }

  .download-btn {
    background: #ffffff;
    box-shadow: 0rem 0.125rem 0.375rem rgba(210, 210, 210, 0.5);
    margin-bottom: 0rem;
    color: $gradientRight !important;
    font-family: $fontFamily;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: $Body-Small !important;
    line-height: 0.5rem !important;
    width: 235px;
    height: 2rem;
    border: 0.063rem solid #ef8123;
    box-sizing: border-box;
    border-radius: 0.25rem;
  }

  .download-label {
    padding-left: 0.625rem;
    padding-top: 0.438rem;
  }
}

.preRate {
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #000000;
  width: 5.625rem;
}

.question-comment {
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #000000;
  width: 95px;
}

.comment-section {
  font-size: $Body-Default;
}

.self-assessed-top-level {
  .report {
    padding: 1.438rem;
  }

  .padding-report {
    padding: 0.313rem !important;
    font-size: $Body-Default !important;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .results {
      display: flex;
      flex-direction: column;
    }
  }

  .last-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.include-in-report-float-right {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-right: $spacing-xl;
}

.results-grid {
  @include Body-Default-Semibold;
  margin: 1.125rem $spacing-xl 0.5rem $spacing-xl !important;
}

.results-row {
  padding-top: 6.25rem !important;
}

.segment-eval-plans {
  background-color: $white !important;
  padding: 0rem 0rem !important;
  .add-icon {
    padding: 0rem !important;
  }
  .participant-group-div,
  .eval-checkBox {
    .form-check {
      display: flex !important;
      line-height: 1.25rem !important;
    }
  }
}

.eval-name-plan-disabled {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  margin-left: $spacing-sm !important;
  cursor: not-allowed;

  .desc-title {
    @include font-style($Body-Small, $info, 600);
  }

  p {
    @include font-style(0.75rem, $info);
  }

  .creation-summary {
    @include font-style(0.75rem, $info, 600);
  }

  .creation-info {
    @include display-flex(space-between, flex-start);

    @media (max-width: 48rem) {
      flex-direction: column;
    }

    label,
    font {
      @include font-style(0.75rem, $info);
    }

    label {
      font-weight: 600;
    }
  }
}

.isDisabled-eval {
  display: flex;
  justify-content: center;
  background: #ffebee !important;
  border-radius: 0.25rem !important;
  color: $red !important;
  width: 66px;
  height: 1.25rem;
  font-size: $Body-Small !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem 0.75rem;

  .text-dis {
    line-height: 1.25rem;
    text-align: center;
    color: $red;
  }
}

.eval-cell {
  margin-bottom: 1.25rem;
  margin-left: $spacing-sm !important;

  .desc-title {
    @include font-style($Body-Small, $info, 600);
  }

  p {
    @include font-style(0.75rem, $info);
  }

  .creation-summary {
    @include font-style(0.75rem, $info, 600);
  }

  .creation-info {
    @include display-flex(space-between, flex-start);

    @media (max-width: 48rem) {
      flex-direction: column;
    }

    label,
    font {
      @include font-style(0.75rem, $info);
    }

    label {
      font-weight: 600;
    }
  }
}

.creation-eval {
  padding: 0.938rem 0rem 0rem 2.857rem !important;

  .eval-inputs {
    background: #ffffff;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 29.25rem;
    height: 2.857rem;
  }
}

.creation-eval-new {
  padding: 0rem 0rem 0rem 2.857rem !important;

  .eval-inputs {
    background: #ffffff;
    border: 0.031rem solid #c3d3e6;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 29.25rem;
    height: 2.857rem;
  }
}

.sep-line-eval {
  border: 0.01rem solid #e0e0e0;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.tet9 {
  display: flex;
  flex-direction: row;
}

.questions-eval-new {
  padding-bottom: 1.25rem !important;

  .border-line {
    border: 0.01rem solid #e0e0e0;
  }

  .question-text {
    padding: 1.875rem 0rem 0rem 0rem !important;
    @include Body-Default-Semibold(#3b3b3b);
  }
}

.questions-modal-eval {
  padding-bottom: 1.25rem !important;
  margin-top: 1.25rem;

  .border-line {
    border: 0.063rem solid #e0e0e0;
  }

  .question-text {
    padding: 1.25rem 0rem 0.625rem 0rem !important;
    @include Body-Default-Semibold(#3b3b3b);
  }
}

.questions-eval-padding {
  padding-bottom: 1.25rem !important;
}

fieldset {
  padding: 0rem 0rem;
}

.msg-leave {
  .ui.tiny.modal {
    width: 540px;
    margin: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  }

  .ui.modal > .content {
    background-color: $white !important;
  }
}

.buttons-stay-leave {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.938rem;
  border-top: 0.063rem solid #e0e0e0 !important;
}

.buttons-add-series {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0.938rem;
}

.subtitle-inputs {
  font-size: $h6;
  size: 1.5rem;
}

.ui.button.leave {
  width: 5.5rem;
  height: 2rem;
  background: #ef8123;
  border: 0.125rem solid #ef8123;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
  margin-left: 0.938rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 0.625rem;
  color: #ffffff;
  text-align: center;
}

.ui.button.stay {
  width: 5.5rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
  background: $white;
  border: 0.125rem solid #ef8123;
  box-sizing: border-box;
  color: $gradientRight !important;
  border-radius: 0.25rem;
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-style: normal;
  font-weight: normal;
  line-height: 0.625rem;
  text-align: center;
}

.ui.button.warning {
  width: 5.5rem;
  height: 2rem;
  background: #ef8123;
  border: 0.125rem solid #ef8123;
  padding: 0.25rem $spacing-lg;
  margin-left: 0.938rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 0.625rem;
  color: #ffffff;
  text-align: center;
}

.seperator {
  background-color: $white !important;
  height: 1.875rem !important;
}

.dropdown-eval-plans {
  .ui.dropdown .menu > .item {
    width: 100% !important;
    background-color: white !important;
    border-bottom: 0.063rem solid #e0e0e0;
  }

  .ui.dropdown .menu > .item:hover {
    background-color: rgba(239, 129, 35, 0.1) !important;
  }
}

.eval-bg-new {
  background-color: #ffffff !important;

  .ui.table {
    margin: 0rem !important;
  }

  .ui.table thead {
    top: 0;
  }

  th:nth-child(2),
  th:nth-child(3) {
    padding-left: 0rem !important;

    b {
      padding-left: 0.625rem !important;
    }
  }
}

.eval-table-new th {
  font-size: $Body-Small;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  // letter-spacing: 0.2000000029802321.5rem;
  color: $subLabel !important;
  padding: 0.625rem 0.5rem 0.625rem 0.688rem !important;
}

.eval-table-new td {
  border: none !important;
  border-bottom: 0.063rem solid #e0e0e0 !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.375rem;
  padding: 0.938rem 0.5rem 0rem 0.75rem !important;
  background-color: $white !important;
}

.eval-table-new thead {
  background-color: $background;
}

.eval-table-new-items {
  text-align: center !important;
}

.padding-outcomes {
  padding: 1.875rem 3.125rem 1.875rem 3.125rem !important;
}

.greenlight-evaluation-plan-extra-margin {
  padding-left: 1.125rem;
}

.greenlight-evaluation-plan-objective-extra-left-padding {
  padding-left: 1.125rem;
}

.eval-plan-radio-group-row {
  margin-top: 0.286rem !important;
}

.evaluation-respondent-type-row {
  font-size: $Body-Small !important;
  padding-left: 1.125rem !important;
  padding-top: 0.5rem !important;
}

.greenlight-objective-eval-group-list {
  display: list-item;
  font-size: $Body-Default !important;
  color: $info !important;
  margin-left: 1rem;
  font-weight: 400 !important;
}

.preRat-small {
  font-size: $Body-Default;
}

.comments-table {
  border-bottom: 0.063rem solid #e0e0e0 !important;
  max-height: 400px;
  overflow: auto;

  .copy-comment {
    cursor: copy;
    white-space: pre-line;
  }

  .pre-line {
    white-space: pre-line;
  }

  .ui.table td.one.wide,
  .ui.table th.one.wide {
    width: 0.25% !important;
  }

  .ui.table thead tr:first-child > th {
    background: $background !important;
    position: sticky !important;
    top: 0;
  }
}

.approval-objectives-margin {
  margin-bottom: 1.875rem !important;
}

.approval-request-data {
  white-space: pre-line !important;
  margin-top: 0.5rem !important;
  @include Body-Default;
}

.projected-input-venues {
  display: list-item !important;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
}

.requiring-greenlight {
  @include Body-Default;
  text-align: left;
}

.projected-greenlight {
  background-color: $background !important;
  padding: 1.25rem 1.125rem !important;
}

.primary-objective-greenlight {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
}

.primary-objective-greenlight-single {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  margin-top: -2.857rem;
}

.projected-output-greenlight {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
}

.projected-inputs-greenlight {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  padding-left: 0rem !important;
  margin-left: 0rem !important;
}

.projected-outputs-greenlight {
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  margin-top: -0.625rem;
  margin-bottom: 0.625rem;
  padding-left: 1.25rem !important;
  margin-left: $spacing-sm !important;

  .self-assessed-top-level {
    .report {
      padding: 1.438rem;
    }

    .padding-report {
      padding: 0.313rem !important;
    }

    .first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .results {
        display: flex;
        flex-direction: column;
      }

      .rating_view {
        display: flex;
        flex-direction: row;
        margin-top: 0.313rem;
      }

      .post-average-box {
        width: 2.857rem;
        height: 1.25rem;
        background-color: #d16400;
      }

      .pre-average-box {
        width: 2.857rem;
        height: 1.25rem;
        background-color: #2f80ed;
      }

      .text {
        margin-left: 0.5rem;
      }
    }

    .rating_view {
      display: flex;
      flex-direction: row;
      margin-top: 0.313rem;
    }

    .post-average-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #d16400;
    }

    .pre-average-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #2f80ed;
    }

    .text {
      margin-left: 0.5rem;
    }

    .last-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.custom-table-pagination {
  .ui.menu {
    display: inline-flex !important;
    flex-wrap: nowrap !important;
    vertical-align: middle;
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
    background-color: $white;
  }

  .ui.secondary.pointing.menu .item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white !important;
    border-right: 0 !important;
    border-radius: 0.25rem !important;
    border-bottom-color: #efefef !important;
    box-sizing: border-box;
    font-family: Inter !important;
    font-size: $Body-Small !important;
    font-weight: normal !important;
    line-height: 1 !important;
    padding: 0.625rem !important;
    font-style: inherit !important;
    min-width: 3em;
  }

  .ui.secondary.pointing.menu .active.item {
    border: 0.063rem solid #ef8123 !important;
    color: #ef8123 !important;
  }

  tfoot {
    tr th {
      background-color: #fff !important;
      text-align: center !important;
    }
  }
}

.modal-report-error-header {
  font-size: $Body-Large;
}

.modal-report-error-paragraph {
  font-size: $Body-Default;
}

.modal-report-error-img {
  width: '3.375rem';
  height: '3.375rem';
}

//models
.new-activity-record,
.create-new-evidence,
.library-evidence,
.add-guest-user {
  .header {
    @include Body-Default-Semibold;
    padding: 1.3rem $spacing-lg 1rem $spacing-lg !important;
  }

  .content {
    padding: $spacing-lg !important;
    background-color: $Gray-10 !important;
  }

  .form-group {
    margin-bottom: 1.4rem;
  }

  .model-btn-align-right {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center;
  }

  .sub-label {
    margin-top: -1rem !important;
  }
  .evidence-modal-label {
    @include Body-Default;
  }
}

.account-permission-group {
  .column {
    padding: 0.5rem $spacing-xl !important;
  }

  .form-check {
    margin: 0rem !important;
  }

  .ui.segment {
    box-shadow: none !important;
  }
}

.contractor-table {
  .current-member-text.p-3 {
    padding-left: 0rem !important;
    margin-left: 60px;
  }
}

.ui.dropdown .menu > .header {
  margin: 1rem 0 0.75rem;
  padding: 0 1.14285714rem;
  color: #757574 !important;
  font-size: 0.78571429em;
  font-weight: 700;
  text-transform: uppercase;
}

.sub-header {
  margin-left: 0.4rem;
  size: 0.8rem;
}

.sub-header-checkbox {
  margin-left: 1.4rem;
  size: 0.8rem;
}

.custom-multiple-checkbox-ul {
  margin-left: -1.6rem;
  size: 0.8rem;
  li {
    margin-top: 1.14rem;
  }
}

.report-outstanding-info {
  margin-top: 1.714rem;
}

.btn-add-note {
  background-color: transparent !important;
  border: transparent !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  font-size: $Body-Small !important;
  padding: 0.78571429em 0.5em 0.78571429em 1.5rem !important;
  margin-bottom: $spacing-md !important;

  i {
    color: $gradientRight !important;
  }

  .add-note-lbl {
    font-size: 1rem;
  }
}

.btn-add-note-disable {
  background-color: transparent !important;
  border: transparent !important;
  color: $Gray-80 !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  font-size: $Body-Small !important;
  padding: 0.78571429em 0.5em 0.78571429em 1.5rem !important;
  margin-bottom: $spacing-md !important;

  i {
    color: $Gray-80 !important;
  }

  .add-note-lbl {
    font-size: 1rem;
  }
}

.note-card {
  margin-bottom: $spacing-xl !important;
  border-color: #757574 !important;
}

.ui.card,
.ui.cards > .card {
  box-shadow:
    0 1px 3px 0 #ffffff,
    0 0 0 1px #e0e0e0 !important;
}

.note-card-extra {
  font-size: $Body-Small !important;
}

.note-card-dropdown-items {
  color: $black !important;
  font-size: $Body-Default !important;
}

.btn-save-note {
  background-color: transparent !important;
  border: transparent !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  font-size: $Body-Small !important;
  padding: 0rem !important;
  margin: $spacing-xs $spacing-xs $spacing-sm !important;
}

.btn-save-note-disabled {
  cursor: not-allowed !important;
}

.btn-cancel-note {
  background-color: transparent !important;
  border: transparent !important;
  color: $Text-Secondary !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  font-size: $Body-Small !important;
  padding: 0rem !important;
  margin: $spacing-xs $spacing-xs $spacing-sm !important;
}

.note-card-create-extra {
  padding: 0rem 0rem !important;
  font-size: $Body-Small !important;
  border: none !important;
  background-color: transparent !important;
  resize: none !important;
  outline: none !important;

  .ui.button:disabled,
  .ui.buttons .disabled.button,
  .ui.disabled.active.button,
  .ui.disabled.button,
  .ui.disabled.button:hover {
    cursor: not-allowed !important;
    background-color: transparent !important;
    border: transparent !important;
    color: $Text-Secondary !important;
    font-family: $fontFamily !important;
    font-weight: 400 !important;
    font-size: $Body-Small !important;
    padding: 0rem !important;
    margin: $spacing-xs $spacing-xs $spacing-sm !important;
  }
}

.note-card-text-area {
  margin: 0rem !important;
  padding: 0rem !important;

  textarea {
    border-color: #fff !important;
    width: 100%;
    border-bottom: 0.01px ridge #d7d7d1 !important;
    border-radius: 0px !important;
    // padding: 0rem !important;
    margin: 0rem !important;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
}

.note-card-view-text-area {
  min-height: 80px !important;
  margin: 0rem !important;
  padding: $spacing-xs $spacing-xs 0 $spacing-xs !important;

  position: relative;
}

.note-card-view-user-area {
  bottom: 0 !important;
  left: 0 !important;
  padding: $spacing-md 0 $spacing-md $spacing-xs !important;
}

.ui.card > .extra,
.ui.cards > .card > .extra {
  max-width: 100%;
  min-height: 0 !important;
  flex-grow: 0;
  border-top: 1px solid transparent !important;
  position: static;
  background: 0 0;
  width: auto;
  margin: 0 0;
  padding: 0.75em 1em;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.4);
  box-shadow: none;
  transition: color 0.1s ease;
}

.note-card-ellipse {
  margin: $spacing-xs !important;
}

.note-error {
  margin: $spacing-xs !important;
  color: red !important;
}

.note-updated-time {
  color: $Text-Secondary !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  padding: 0rem !important;
  margin: $spacing-xs $spacing-xs $spacing-sm !important;
}
