@import '../../variables.scss';
@import '../../mixins.scss';

.checkinfo-tag {
  position: absolute;
  right: 0.625rem;
  background-color: $gradientRight;
  color: white;
  cursor: pointer;
}

.checkinfo-tag-mobile {
  background-color: $gradientRight;
  color: white;
  cursor: pointer;
}

.main-layout {
  min-height: 100vh;
  overflow: hidden;
}

.row {
  margin: 0 !important;
}

.web-navbar {
  position: fixed !important;
  max-width: 64px;
  min-width: 64px;
  min-height: 100vh;
  background-color: $gradientRight;
  z-index: 2;
  overflow: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.custom-sidebar {
  background-color: $gradientRight !important;
  display: flex !important;
  align-items: center !important;

  .item.mobile-logo-container {
    margin-bottom: 5rem !important;
  }

  .item {
    position: initial !important;
    margin-bottom: $spacing-xl !important;
    border: none !important;
  }
}

.navbar-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-height: 90vh;
}

.navbar-active {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar-heading {
    margin-bottom: 6.25rem;
  }
}

.web-navbar-heading {
  margin-bottom: 92px;
}

.navbar-heading {
  margin-bottom: 92px;
  display: flex;
  flex: 1.5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .ui.small.image {
    width: 6.25rem;
    height: 6.25rem;
    display: block;
    text-indent: -9999px;
    object-fit: contain;
  }

  .logo-img {
    width: 6.25rem;
    height: 6.25rem;
    display: block;
    text-indent: -9999px;
    object-fit: contain;
  }

  .text {
    margin-top: 0.25rem;
    @include Body-Default-Semibold($white);
    text-align: center;
  }
}

.route-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;

  .route-img {
    margin-top: 0.813rem;
  }

  .route-text {
    margin-top: 0.5rem;
    color: $white;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 0.857142857rem;
    text-align: center;
  }
}

.item {
  .route-container:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.route-container-active {
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.web-route-container {
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
}

.nav-routes-container {
  display: flex;
  flex: 8;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .item {
    margin-top: $spacing-xl;
  }
}

.main-content {
  margin-left: 64px;
  padding: 0 !important;
  display: flex !important;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  background-color: $background;

  .main-content-header {
    display: flex;
    height: 5rem !important;
    min-height: 5rem !important;
    max-height: 5rem !important;
    border: 0.063rem solid rgba(223, 223, 223, 0.5);
    box-shadow: 0 0.125rem 0.375rem 0 rgba(223, 223, 223, 0.5);
    background-color: $white;

    .header-grid {
      width: 100%;

      .header-title {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        height: 100%;

        .header-title-container {
          display: flex;
          align-items: center;

          .header-title-text {
            max-width: 47.5rem;
            color: $info;
            font-family: $fontFamily;
            font-size: $h6;
            font-weight: bold;
            line-height: 2.357rem;
            margin-left: 3.071rem;
            margin-bottom: 0rem;
          }
        }

        .header-title-group {
          @include Body-Default;

          .header-title-group-color {
            color: $gradientRight;
          }
        }
      }

      .profile-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;

        .text {
          color: $info;
          font-family: $fontFamily;
          font-size: $Body-Large;
          line-height: 1.5rem;
        }
      }
    }
  }

  .content-layer {
    background-color: $background;
    display: flex;
    flex: 9;
    padding-top: $spacing-lg;
    padding-left: $spacing-2xl;
    padding-right: $spacing-2xl;
    overflow: auto;

    &.panel-open {
      flex: 8; // Adjust as needed
      display: flex;
    }

    .content-container {
      width: 100%;
      margin-bottom: 2.857rem;

      &.panel-open {
        display: flex;

        @media (min-width: 1000px) {
          padding-left: 300px;
        }
      }
    }

    .ui.grid {
      margin: 0rem !important;
    }
  }

  .ui.grid > .column:not(.row),
  .ui.grid > .row > .column {
    padding: 0rem $spacing-sm;
  }

  .act-content-layer {
    background-color: $background;
    display: flex;
    flex: 9;
    @include padding-mix($spacing-lg, $spacing-2xl);
    height: 87vh;
    overflow: auto;
    padding-bottom: 3.75rem;

    .content-container {
      width: 100%;
      margin-bottom: 2.857rem;
    }
  }

  .parent-act {
    background-color: $background !important;
  }

  .content-steps {
    background-color: $background;
    display: flex;
    flex: 9;

    .content-container-steps {
      width: 100%;
      margin-bottom: 2.857rem;
    }
  }

  .content-segment {
    border-bottom: none !important;
    border-top: none !important;

    .margin-top {
      margin-top: $spacing-lg !important;
    }
  }

  .content-segment-dashboard {
    border-bottom: none !important;
    border-top: none !important;

    .row.no-padding {
      padding: 0.25rem !important;
    }

    .ui.grid > .row {
      padding: 0rem !important;
    }
  }

  .content-segment-dashboard-todo {
    border-bottom: none !important;
    border-top: none !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .content-segment-dashboard-recent-activity {
    border-bottom: none !important;
    border-top: none !important;
    padding-left: 0rem !important;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-right: 0rem !important;
  }
}

.content-segment-bg {
  background-color: white;
  padding: 0 !important;
}

.mob-main-content {
  box-shadow: 0 0.125rem 0.375rem 0 rgba(223, 223, 223, 0.5);
  padding-bottom: 0.625rem;

  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .header-title-text {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Large;
    font-weight: bold;
    line-height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .text {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.5rem;
    }
  }
}

.mob-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
}

.mob-content-container {
  width: 100%;
}

.pro-image {
  margin-bottom: 0.25rem;
}

.notification-awesome {
  background-color: #685dc3;
  border-left: 0.5rem solid darken(#685dc3, 15%);
}

.ui.segment:last-child {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.ui.raised.segment,
.ui.raised.segments {
  border: none;
  box-shadow: 0 0.125rem 0.375rem 0 rgba(210, 210, 210, 0.5);
}

.item {
  cursor: pointer;
}

.pointer-text {
  cursor: pointer;
}

.content-segment-settings {
  padding-left: 2.125rem !important;
}

.sidebar-custom-segment {
  box-shadow: none !important;
  border-radius: none !important;
  border: none !important;
  min-height: 100vh !important;
}

.pushable > .pusher {
  min-height: 100%;
}

.loadingContainer {
  display: flex;
  min-height: 100vh !important;
  height: 100% !important;
  width: 100% !important;
  background-color: $gradientRight;
}

.help-page {
  box-shadow: '0 0.125rem 0.188rem 0 rgb(34 36 38 / 15%)';
  border-radius: 0.28571429rem;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
  position: fixed;
  top: 43%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #ffffff;
  text-align: center;
  transform: rotate(-90deg);

  .right-centre {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .panel-lbl {
    padding: 0.375rem 0.625rem;
    background: $gradientRight;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
  }
}

.filter-button-container {
  box-shadow: '0 0.125rem 0.188rem 0 rgb(34 36 38 / 15%)';
  border-radius: 0.28571429rem;
  transition: opacity 0.1s ease;
  z-index: 11;
  will-change: transform, opacity;
  position: absolute;
  top: 43%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  color: #ffffff;
  text-align: center;
  transform: rotate(-90deg);

  .right-centre {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .panel-lbl {
    padding: 0.375rem 0.625rem;
    background: $gradientRight;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
  }
}

.right-without-note {
  right: -1.6rem;
}

.right-with-note {
  right: -3.9rem;
}

.right-with-filters-note {
  right: -8rem;
}

.right-with-filters {
  right: -5.7rem;
}

.page-info {
  background-color: white;
  font-family: $fontFamily;

  .content {
    padding: $spacing-xl;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.813rem $spacing-xl;
    background: #f5f5f5;

    .info-close {
      font-size: $Body-Large;
      cursor: pointer;
    }

    label {
      @include Body-Default-Semibold;
    }
  }
  .fw-bold {
    font-weight: bold;
  }
  .btn-show-onboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-width: 160px;
    background-color: $gradientRight;
    color: $white;
    border: 2px solid $amber;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    font-family: $fontFamily;
    font-size: $Body-Large;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
  }
  .btn-show-onboard:hover,
  .btn-show-onboard:active {
    background-color: $white;
    color: $gradientRight;
  }
}

.mobile-header {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0.625rem !important;
}
.side-bar-zone {
  width: 100% !important;
}

.ui.vertical.ui.push.right.wide.visible.sidebar.filter-width.menu {
  width: 300px !important;
}

.ui.vertical.ui.overlay.right.wide.visible.sidebar.filter-width.menu {
  width: 467px !important;
}

.ui.vertical.ui.overlay.right.wide.visible.sidebar.help-note-width.menu {
  width: 350px !important;
}

.advanced-filter-resize {
  width: 300px !important;
}

.ui.segment.pushable.advanced-filter-overflow-x {
  overflow-x: clip;
}

.filter-align {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: sticky;
  top: 0px;
  left: 100%;
  z-index: 1;
  writing-mode: vertical-lr;
  transform: scale(-1);
  @include Body-Small($white);
  color: #ffffff !important;

  .panel-lbl {
    padding: 0.822rem 0.438rem;
    background: $gradientRight;
    border-radius: 0 0.25rem 0.25rem 0rem;
  }
}

.main-content .content-layer .ui.grid {
  margin: 0rem !important;
  width: 100%;
}
