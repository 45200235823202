@import '../../../mixins.scss';
@import '../../../variables.scss';

.ui.segments.report-preview {
  .ui.grid {
    margin: 0rem !important;
  }
}

button.ui.small.icon.left.labeled.button.Primary-Button {
  padding: 0.625rem $spacing-lg !important;
}

.ui.grid {
  .segments {
    width: 100%;
    padding: 0;
    margin: 0;

    .control-holder {
      s .title {
        @include font-style(1rem, $black, 600);
        margin-bottom: 1.25rem;
      }

      .export-org-export {
        float: right;
      }

      .export-button {
        margin: 0.625rem;
        padding: 0.625rem !important;
        background-color: white !important;
        @include font-style(1rem !important, #ef8123 !important, 600);

        .ui.dropdown > .left.menu {
          top: 29px;
          left: -5.5rem !important;
        }
      }

      .Primary-Button {
        float: right;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        background: $Primary !important;
        border-radius: 0.25rem !important;
        @include Body-Default($White);
        line-height: 1.25rem !important;
        border: 0.125rem solid $Primary !important;
        height: 2.857rem !important;

        &:hover {
          background-color: $Primary-dark !important;
          border-radius: 0.25rem !important;
        }

        &:active {
          border: 0.125rem solid $Primary-light !important;
          border-radius: 0.25rem !important;
        }

        &:disabled {
          @include Body-Small($White);
          background: $Gray-50 !important;
          border: 0.125rem solid $Gray-50 !important;

          border-radius: 0.25rem !important;
        }

        .ui.dropdown {
          display: flex !important;
          align-items: center !important;
        }
      }

      .control-selector {
        .date-range-picker {
          display: flex;
          flex-direction: column;

          i.icon {
            margin: 0 0.55rem 0.45rem 0;
          }

          .react-bootstrap-daterangepicker-container {
            width: 100%;
          }

          .date-holder {
            display: flex;
            flex-direction: row;
            width: 100% !important;

            input {
              text-align: center !important;
              font-size: $Body-Small !important;
              width: 230px !important;
              padding-left: 0.625rem !important;
            }

            .date-picker-btn {
              display: flex !important;
              align-items: center !important;
              justify-content: space-between !important;

              span {
                margin-left: 0.375rem !important;
                font-size: $Body-Small !important;
                margin-left: 0.625rem !important;
                font-size: $Body-Small !important;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .btn-icon {
                margin-right: 0.5rem;
              }

              background-color: transparent;
              padding: 0 !important;
              margin: 0;
              @include font-style(0.75rem, $info);
              opacity: 0.7;
              background: #ffffff;
              border: 0.031rem solid #c3d3e6 !important;
              box-sizing: border-box;
              border-radius: 0.5rem;
              width: 18.75rem;
              height: 2.857rem;
            }

            .button {
              padding: 0.313rem !important;
            }

            .button:hover {
              background-color: $amber !important;
              color: $white !important;
            }
          }
        }

        .form-group {
          padding-right: 6.25rem;
          margin: 0;
        }

        .form-control {
          @include font-style($spacing-sm, $black, !important);
          padding: 0;
          border: 0.031rem solid #c3d3e6;
          box-sizing: border-box;
          border-radius: 0.25rem;
          opacity: 0.7;
          padding: 0rem 0rem 0rem 0.313rem !important;
          background: #ffffff;
          border: 0.031rem solid #c3d3e6 !important;
          box-sizing: border-box;
          border-radius: 0.5rem;
          width: 250px;
          height: 2.857rem;
        }

        .input-group {
          flex-wrap: nowrap;

          .input-group-prepend {
            padding-right: 0.5rem;
            padding-top: 0.5rem;
          }
        }

        .multiple-selector {
          .ui.label {
            background-color: $gradientRight;
            color: $white;
          }
        }
      }

      .outcomes-selector {
        display: flex;
        justify-content: center;

        .apply-button,
        .clear-button {
          margin-left: 0.625rem;
        }

        .apply-button {
          @include font-style(1rem, $white, 600);
          background-color: $gradientRight;
        }

        .clear-button {
          @include font-style(1rem, $gradientRight, 600);
          background-color: $white;
        }
      }
    }

    @media only screen and (max-width: 48rem) {
      .chart-holder {
        padding: 0.75rem 0.625rem !important;
      }

      .outcomes-summary-chart-div {
        padding: 0.625rem !important;
      }
    }
  }

  .chart-holder {
    // background-color: $white;

    // padding-right: 1rem !important;
    .blank-report-div {
      height: 50vh;
      @include font-style(1.5rem, $info, bold);
      opacity: 0.5;
      @include display-flex(center, center);
    }

    .outcomes-summary-grid {
      .outcomes-chart-row {
        display: flex;
        flex-direction: column;
        padding-top: 2.857rem;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: $Body-Small;
        line-height: 1.25rem;

        .outcomes-summary-chart-div {
          border: 0.063rem solid #e7e7e7;
          border-radius: 0.25rem;
          background-color: #ffffff;
          // padding: 1.25rem;
          width: 100%;

          .graph-tab > div > .item {
            flex: 1;
            justify-content: center;
          }

          .graph-tab > div > .item.active {
            border-bottom-color: $gradientRight !important;
          }

          .ui.segment {
            padding: 0rem;
          }

          .reference-mainText.item,
          label.core-data-label,
          label.reference-mainText-muted.item {
            display: list-item;
          }

          .report-ref-see-more {
            padding-left: $spacing-xl;
          }

          .row {
            padding-bottom: 0rem !important;
          }
        }

        .legendsContainer {
          margin-bottom: 1.875rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          @include Body-Small($info);

          .legend {
            display: flex;
            flex-direction: row;
          }

          .otherLegends {
            margin-left: 1.25rem;
          }
        }

        .post-res-box {
          height: $spacing-sm;
          width: 3.75rem;
          background-color: #f9cda7;
          margin-right: 0.625rem;
        }

        .pre-res-box {
          height: $spacing-sm;
          width: 3.75rem;
          background-color: #ef99a4;
          margin-right: 0.625rem;
        }

        .post-survey-res-box {
          height: $spacing-sm;
          width: 3.75rem;
          background-color: #f5b37b;
          margin-right: 0.625rem;
        }
      }

      .no-data-div {
        @include display-flex;
        width: 100%;
        height: 50vh;
      }

      .new-label-re {
        @include Body-Default-Semibold;
        /* identical to box height, or 150% */
        padding: 0.938rem 0rem 0.625rem 1.25rem;
      }
    }

    .sub-inp-new {
      margin-left: 105px;
    }

    .sub-inp-new-names {
      margin-left: 50px;
    }

    .headline-input-row {
      .sub-inp {
        padding-left: 2.188rem;
      }

      .height-col {
        height: 2.813rem !important;
      }
    }

    .header-data-outcome {
      text-align: right;
      margin-right: 5rem !important;
    }

    .headline-input-row-data {
      text-align: right;
      margin-right: 5rem !important;

      .sub-inp {
        padding-left: 2.188rem;
      }

      .height-col {
        height: 2.813rem !important;
      }
    }
    .no-border {
      border: none;
    }
    .no-bottom-border {
      border-bottom: none !important;
    }

    .headline-data-grid {
      margin-bottom: $spacing-lg;

      .column {
        padding-left: 0;
      }

      overflow-x: auto;

      .table-holder {
        padding: 0rem $spacing-xl $spacing-lg $spacing-xl;
        overflow-x: auto;
        width: 100%;
      }

      .inputs-table-head {
        background-color: $background;
      }

      .outputs-table-head {
        background-color: $background;
      }

      .outputs-table,
      .inputs-table {
        border-left: 0.063rem solid #e0e0e0;
        border-right: 0.063rem solid #e0e0e0;
        border-radius: 0.25rem;

        thead {
          tr {
            th {
              border: 0.031rem solid #d6d6d6;
              border-left: 0rem;
              border-right: 0rem;
              padding: 0.375rem $spacing-xl !important;
              @include font-style($spacing-sm, $subLabel, 500);
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 0.75rem $spacing-xl !important;
              font-family: $fontFamily;
              font-style: normal;
              font-weight: normal;
              font-size: $Body-Default;
              line-height: 1.5rem;
              color: $info;
              border-top: none;
              border-bottom: 0.063rem solid #e0e0e0;
            }
          }
        }
      }

      .festivals-table {
        padding: 0 $spacing-xl;

        thead {
          tr {
            th:first-child {
              border-left: 0.063rem solid #e0e0e0;
            }

            th:last-child {
              border-right: 0.063rem solid #e0e0e0;
            }
          }
        }

        tbody {
          tr {
            td:first-child {
              border-left: 0.063rem solid #e0e0e0;
            }

            td:last-child {
              border-right: 0.063rem solid #e0e0e0;
            }
          }
        }
      }

      .outputs-table {
        margin-bottom: 0.625rem;
        border-radius: 0.25rem;
      }
    }

    .headline-outputs-grid {
      overflow-x: auto;

      .table-holder {
        padding: 0 $spacing-xl;
        overflow-x: auto;
        width: 100%;
      }

      .inputs-table-head {
        background-color: $background;
      }

      .outputs-table-head {
        background-color: $background;
      }

      .outputs-table,
      .inputs-table {
        border: 0.031rem solid #e0e0e0;
        border-radius: 0.25rem;

        thead {
          tr {
            th {
              border: 0.031rem solid #d6d6d6;
              border-left: 0rem;
              border-right: 0rem;
              padding: 0.375rem $spacing-xl !important;
              @include font-style($spacing-sm, $subLabel, 500);
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 0.75rem $spacing-xl !important;
              font-family: $fontFamily;
              font-style: normal;
              font-weight: normal;
              font-size: $Body-Default;
              line-height: 1.5rem;
              color: $info;
            }
          }
        }
      }

      .outputs-table {
        margin-bottom: 0.625rem;
        border-radius: 0.25rem;
      }
    }

    .festivals-table {
      padding: 0 $spacing-xl;
      margin-bottom: $spacing-lg;
      width: 100%;
    }

    .activity-count-info {
      .ui.grid {
        margin: 0rem !important;

        .column {
          padding: 0rem;
        }
      }

      .activity-summary-grid {
        .row {
          margin: 0rem !important;
        }

        background-color: $orgBackground;
        border: 0.031rem solid #d6d6d6;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        height: 120px;

        // @media (max-width: 1100px) {
        //   height: 130px !important;
        // }

        .org-down {
          margin-top: 0.938rem !important;

          .act-type {
            margin-left: 20px !important;
          }
        }
        .org-down-act-type {
          margin-top: 0.938rem !important;
          margin-left: 18px !important;

          .act-type {
            margin-left: 20px !important;
          }
        }

        .activity-summary-row {
          .summary-details-table {
            margin: 0 !important;

            tbody {
              tr {
                @include font-style($spacing-sm);

                td {
                  padding: 0;
                  padding-right: 1.563rem;
                  border: 0;
                }
              }
            }
          }

          .dev-act,
          .active-act,
          .completed-act {
            padding: 0 0 0 1.25rem;
            margin-bottom: 3.75rem;
            margin-right: 5rem;

            @media (max-width: 48rem) {
              margin-right: 0;
            }
          }

          .dev-act {
            border-left: 0.75rem solid #ff7800;
          }

          .active-act {
            border-left: 0.75rem solid #2d9a2c;
          }

          .completed-act {
            border-left: 0.75rem solid #002f9b;
          }

          .tot-no-holder {
            display: flex;
            flex-direction: column;
          }

          .tot-act-no {
            @include font-style($spacing-xl, $info, 600);
            margin-bottom: $spacing-lg;
          }

          .column {
            padding: 0;
          }

          padding-top: 2.857rem;

          .act-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }

        .left-align {
          display: flex;
          @media (min-width: 1100px) {
            flex: 1;
          }
          @media (max-width: 768px) {
            margin-left: 4px;
          }
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0rem;

          .activity-name {
            margin-left: 1.875rem;
            margin-right: 1.875rem;
            margin-top: -20px;

            &:first-child {
              margin-right: 0.625rem;
              @media (max-width: 768px) {
                margin-left: 0px !important;
              }
            }

            @media only screen and (max-width: 1580px) {
              margin-left: 1.25rem;
              margin-right: 1.25rem;
            }

            label {
              flex: 1;
            }

            .col-wise {
              display: flex;
              flex-direction: column;

              label {
                @include Body-Default;
              }

              .number-centre {
                text-align: center;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: $Body-Default;
                line-height: 1.5rem;
              }
              .number-left {
                text-align: left;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: $Body-Default;
                line-height: 1.5rem;
              }
            }

            .circle {
              min-height: 0.625rem;
              height: 0.625rem;
              min-width: 0.625rem;
              width: 0.625rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .report-circle {
              min-height: 0.625rem;
              height: 1.143rem;
              min-width: 0.625rem;
              width: 1.143rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .circle-bottom {
              margin-bottom: 1.875rem;
            }

            .activity-completed {
              background-color: $blue;
            }

            .activity-success {
              background-color: $green;
            }

            .activity-warning {
              background-color: $amber;
            }

            .reports-cultural {
              background-color: $cultural;
            }

            .reports-economic {
              background-color: $economic;
            }

            .reports-social {
              background-color: $social;
            }

            .reports-environmental {
              background-color: $environment;
            }

            .reports-governance {
              background-color: $governance;
            }
            .activity-error {
              background-color: $red;
            }
          }
        }
        .left-align-sm {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0rem;
          // margin-top: -1.25rem;

          .activity-name {
            margin-left: 1.875rem;
            margin-right: 1.875rem;

            &:first-child {
              margin-right: 0.625rem;
            }

            @media only screen and (max-width: 1580px) {
              margin-left: 1.25rem;
              margin-right: 1.25rem;
            }

            label {
              flex: 1;
            }

            .col-wise {
              display: flex;
              flex-direction: column;

              label {
                @include Body-Default;
              }

              .number-centre {
                text-align: center;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: $Body-Default;
                line-height: 1.5rem;
              }
            }

            .circle {
              min-height: 0.625rem;
              height: 0.625rem;
              min-width: 0.625rem;
              width: 0.625rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .report-circle {
              min-height: 0.625rem;
              height: 1.143rem;
              min-width: 0.625rem;
              width: 1.143rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .circle-bottom {
              margin-bottom: 1.875rem;
            }

            .activity-completed {
              background-color: $blue;
            }

            .activity-success {
              background-color: $green;
            }

            .activity-warning {
              background-color: $amber;
            }

            .reports-cultural {
              background-color: $cultural;
            }

            .reports-economic {
              background-color: $economic;
            }

            .reports-social {
              background-color: $social;
            }

            .reports-environmental {
              background-color: $environment;
            }

            .reports-governance {
              background-color: $governance;
            }
            .activity-error {
              background-color: $red;
            }
          }
        }
      }
      .summary-management-grid {
        .row {
          margin: 0rem !important;
        }

        background-color: $orgBackground;
        border: 0.031rem solid #d6d6d6;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        height: 154px;

        @media (max-width: 900px) {
          height: 170px !important;
        }

        .org-down {
          margin-top: 0.938rem !important;

          .act-type {
            margin-left: 20px !important;
          }
        }
        .org-down-act-type {
          margin-top: 0.938rem !important;
          margin-left: 15px !important;

          .act-type {
            margin-left: 20px !important;
          }
        }

        .activity-summary-row {
          .summary-details-table {
            margin: 0 !important;

            tbody {
              tr {
                @include font-style($spacing-sm);

                td {
                  padding: 0;
                  padding-right: 1.563rem;
                  border: 0;
                }
              }
            }
          }

          .dev-act,
          .active-act,
          .completed-act {
            padding: 0 0 0 1.25rem;
            margin-bottom: 3.75rem;
            margin-right: 5rem;

            @media (max-width: 48rem) {
              margin-right: 0;
            }
          }

          .dev-act {
            border-left: 0.75rem solid #ff7800;
          }

          .active-act {
            border-left: 0.75rem solid #2d9a2c;
          }

          .completed-act {
            border-left: 0.75rem solid #002f9b;
          }

          .tot-no-holder {
            display: flex;
            flex-direction: column;
          }

          .tot-act-no {
            @include font-style($spacing-xl, $info, 600);
            margin-bottom: $spacing-lg;
          }

          .column {
            padding: 0;
          }

          padding-top: 2.857rem;

          .act-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }

        .left-align {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0rem;

          .activity-name {
            margin-left: 1.875rem;
            margin-right: 1.875rem;

            &:first-child {
              margin-right: 0.625rem;
            }

            @media only screen and (max-width: 1580px) {
              margin-left: 1.25rem;
              margin-right: 1.25rem;
            }

            label {
              flex: 1;
            }

            .col-wise {
              display: flex;
              flex-direction: column;

              label {
                @include Body-Default;
              }

              .number-centre {
                text-align: center;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: $Body-Default;
                line-height: 1.5rem;
              }
            }

            .circle {
              min-height: 0.625rem;
              height: 0.625rem;
              min-width: 0.625rem;
              width: 0.625rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .report-circle {
              min-height: 0.625rem;
              height: 1.143rem;
              min-width: 0.625rem;
              width: 1.143rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .circle-bottom {
              margin-bottom: 1.875rem;
            }

            .activity-completed {
              background-color: $blue;
            }

            .activity-success {
              background-color: $green;
            }

            .activity-warning {
              background-color: $amber;
            }

            .reports-cultural {
              background-color: $cultural;
            }

            .reports-economic {
              background-color: $economic;
            }

            .reports-social {
              background-color: $social;
            }

            .reports-environmental {
              background-color: $environment;
            }

            .reports-governance {
              background-color: $governance;
            }
            .activity-error {
              background-color: $red;
            }
          }
        }
        .left-align-sm {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          padding: 0rem;
          // margin-bottom: 0.25rem;

          .activity-name {
            margin-left: 1.875rem;
            margin-right: 1.875rem;

            &:first-child {
              margin-right: 0.625rem;
            }

            @media only screen and (max-width: 1580px) {
              margin-left: 1.25rem;
              margin-right: 1.25rem;
            }

            label {
              flex: 1;
            }

            .col-wise {
              display: flex;
              flex-direction: column;

              label {
                @include Body-Default;
              }

              .number-centre {
                text-align: center;
                font-family: Inter;
                font-style: normal;
                font-weight: 400;
                font-size: $Body-Default;
                line-height: 1.5rem;
              }
            }

            .circle {
              min-height: 0.625rem;
              height: 0.625rem;
              min-width: 0.625rem;
              width: 0.625rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .report-circle {
              min-height: 0.625rem;
              height: 1.143rem;
              min-width: 0.625rem;
              width: 1.143rem;
              border-radius: 0.625rem;
              margin-right: 0.625rem;
              margin-left: 0.625rem;
              margin-bottom: 2.188rem;
              background-color: $info;
            }

            .circle-bottom {
              margin-bottom: 1.875rem;
            }

            .activity-completed {
              background-color: $blue;
            }

            .activity-success {
              background-color: $green;
            }

            .activity-warning {
              background-color: $amber;
            }

            .reports-cultural {
              background-color: $cultural;
            }

            .reports-economic {
              background-color: $economic;
            }

            .reports-social {
              background-color: $social;
            }

            .reports-environmental {
              background-color: $environment;
            }

            .reports-governance {
              background-color: $governance;
            }
            .activity-error {
              background-color: $red;
            }
          }
        }
      }

      label {
        @include Body-Small($Text-Secondary);
      }

      h6 {
        @include Body-Default();
      }

      .row {
        padding: 0;
      }
    }
  }

  .new-report-outcomes-div {
    .outcome-report-title {
      font-weight: 600;
      font-size: $Body-Default;
      line-height: $spacing-lg;
      padding: 0rem $spacing-xl $spacing-lg $spacing-xl;
    }
  }

  .report-selector {
    width: 292px;
    margin-bottom: 0.625rem !important;
  }

  .activity-type,
  .report-type,
  .report-outcome,
  .report-method,
  .report-goal,
  .report-year,
  .report-reference,
  .financial-report,
  .report-year,
  .report-status,
  .report-org-type {
    border-radius: 0.5rem !important;
    height: 40px;
    text-overflow: ellipsis;

    .text {
      width: 98%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .report-type {
    .disabled {
      color: $black !important;
    }

    .ui.disabled.dropdown,
    .ui.dropdown .menu > .disabled.item {
      cursor: default !important;
      pointer-events: none !important;
      font-weight: 700 !important;
      color: $black !important;
    }
  }

  .report-selector {
    margin-bottom: 10px !important;
  }

  .report-outcome-selector {
    margin-right: 100px;
  }

  .report-org-summary-selector {
    margin-top: 20px !important;
  }

  .left-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 75px 0rem;

    label {
      transform: rotate(270deg);
      color: #3b3b3b;
      font-family: $fontFamily;
      font-size: $Body-Small;
      font-weight: 600;
      line-height: 17px;
      text-align: center;
    }
  }

  .report-outcome-selector {
    width: 12.5rem;
    margin-right: 6.25rem;
  }

  .report-org-summary-selector {
    margin-top: 1.25rem !important;
  }

  .left-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 4.688rem 0rem;

    label {
      transform: rotate(270deg);
      @include Body-Default-Semibold;
      text-align: center;
    }
  }

  .bottom-info-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0rem 3.125rem;

    label {
      @include Body-Small($Text-Secondary);
    }
  }
  .strategic-plane-document {
    margin-left: 15px !important;
    // padding-left: 40px;
    .title {
      font-size: 0.857142857rem !important;
      font-family: 'Inter', sans-serif;
      color: #757575;
    }
  }
  .act-types-summary {
    margin-left: -15px !important;
    width: 100px;
  }
  .act-types-summary-details {
    margin-left: 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin-left: 300px; // Adjust this value to your needs
    }
  }
  .align-right-summary {
    text-align: right;
    margin-right: 20px;
  }

  .heat-map-report {
    border-radius: 0.25rem;
    background-color: #ffffff;
    width: 100%;
    margin-top: 0.75rem;

    .graph-tab > div > .item {
      flex: 1;
      justify-content: center;
    }

    .graph-tab > div > .item.active {
      border-bottom-color: $gradientRight !important;
    }

    .heat-map {
      padding-top: 1.25rem;

      .total-data-label {
        @include font-style(0.75rem);

        @media (max-width: 48rem) {
          @include font-style(0.625rem);
        }
      }

      .total-data-label {
        @include font-style(0.75rem, $info, bolder);
        padding-bottom: 1.563rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .heat-map-container {
        padding: 1rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0rem !important;
        border: 0.063rem solid #e7e7e7;

        .left {
          flex: 1 0 4%;
          align-self: center;

          .y-axis-label {
            transform: rotate(-90deg);
          }

          .y-axis-label {
            @include font-style(0.75rem);

            @media (max-width: 48rem) {
              @include font-style(0.625rem);
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          flex: 1 0 96%;

          .x-axis-label {
            display: flex;
            justify-content: space-between;
            padding-top: 0.625rem;
            padding-left: 2.857rem;
          }

          .x-axis-label {
            @include font-style(0.75rem);

            @media (max-width: 48rem) {
              @include font-style(0.625rem);
            }
          }
          @media (max-width: 768px) {
            div {
              div {
                div:first-child {
                  flex: 0 0 4.8vw !important;
                }
              }
            }
          }
          div {
            div {
              div:first-child {
                flex: 0 0 2.8vw;
              }

              div {
                height: 0.625rem;
                display: flex;
                font-size: $Body-Small;

                div {
                  div {
                    padding: 0 0.313rem 0 0 !important;
                    font-size: $Body-Small;
                    display: flex !important;
                    align-items: center;
                    justify-content: flex-end !important;
                  }
                }
              }
            }

            div:last-child {
              div {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }

  .pre-post-chart-div {
    border: 0.063rem solid #e7e7e7;
    border-radius: 0.25rem;
    background-color: #ffffff;
    padding: $spacing-lg $spacing-xl !important;
    width: 100%;
  }

  .individualRateChart {
    .graph-tab > div > .item {
      flex: 1;
      justify-content: center;
    }

    .graph-tab > div > .item.active {
      border-bottom-color: $gradientRight !important;
    }

    padding-top: 1.25rem;

    .total-data-label {
      @include font-style(0.75rem);

      @media (max-width: 48rem) {
        @include font-style(0.625rem);
      }
    }

    .total-data-label {
      @include font-style(0.75rem, $info, bolder);
      padding-bottom: 1.563rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    padding: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0rem !important;

    .left {
      flex: 1 0 4%;
      align-self: center;

      .y-axis-label {
        transform: rotate(-90deg);
      }

      .y-axis-label {
        @include font-style(0.75rem);

        @media (max-width: 48rem) {
          @include font-style(0.625rem);
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex: 1 0 96%;

      .x-axis-label {
        display: flex;
        justify-content: space-between;
        padding-top: 0.625rem;
        padding-left: 2.857rem;
      }

      .x-axis-label {
        @include font-style(0.75rem);

        @media (max-width: 48rem) {
          @include font-style(0.625rem);
        }
      }

      div {
        div {
          div:first-child {
            flex: 0 0 3vw !important;
          }

          div {
            height: 0.625rem;
            display: flex;
            font-size: $Body-Small;

            div {
              div {
                padding: 0 0.313rem 0 0 !important;
                font-size: $Body-Small;
                display: flex !important;
                align-items: center;
                justify-content: flex-end !important;
              }
            }
          }
        }

        div:last-child {
          div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .ui.segment {
      padding: 0rem !important;
    }

    .reference-mainText.item,
    label.core-data-label,
    label.reference-mainText-muted.item {
      display: list-item;
    }

    .report-ref-see-more {
      padding-left: $spacing-xl;
    }
  }

  body {
    .pop-up {
      background-color: #000000 !important;
      opacity: 0.7;
      border: 0;
      @include Body-Small($White);
      white-space: normal !important;

      &:before {
        background: #000000 !important;
      }
    }
  }

  .title {
    @include font-style(1rem, $info, 600);
  }

  .activity-container {
    background-color: #fafafa;
    min-height: 118px;

    .column {
      padding: 1.875rem 42px;
    }

    .text-org {
      font-family: 'inter';
      font-style: normal;
      font-weight: 400;
      font-size: $Body-Small;
      line-height: 1.25rem;
      color: #757575;
    }

    .text-org-types {
      font-family: 'inter';
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      color: #757575;
    }

    .option-text {
      font-size: $Body-Default;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0rem;
      text-align: left;
    }

    .activity-types {
      font-size: $Body-Default;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0rem;
      text-align: left;
      display: flex;
      font-family: 'inter';
      flex-direction: row;
    }
  }

  .organisation-summary {
    display: flex;
    flex-direction: column;
    padding: 0rem $spacing-lg 0rem;

    .text-org {
      font-family: 'inter';
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      margin-top: 42px;
      padding-bottom: 0.5rem;
      color: #757575;
    }

    .text-org-types {
      @include Body-Default(#757575);
      margin-top: $spacing-lg;
      margin-left: 1.25rem;
      padding-bottom: 0.5rem;
    }

    .option-text {
      font-size: $Body-Default;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      letter-spacing: 0rem;
      text-align: left;
    }

    .activity-types {
      font-size: $Body-Default;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      letter-spacing: 0rem;
      text-align: left;
      display: flex;
      flex-direction: row;
    }

    .act-gap {
      margin-right: 0.375rem;
    }

    .circle {
      min-height: 0.625rem;
      height: 0.625rem;
      min-width: 0.625rem;
      width: 0.625rem;
      border-radius: 0.625rem;
      margin-right: 0.625rem;
      margin-bottom: 2.188rem;
      background-color: $info;
    }

    .circle-bottom {
      margin-bottom: $spacing-sm;
      margin-top: 0.313rem;
    }

    .activity-completed {
      background-color: $blue;
    }

    .activity-success {
      background-color: $green;
    }

    .activity-warning {
      background-color: $amber;
    }
  }

  .org-report-table-header {
    background-color: #fafafa;
  }

  .organisation-summary-number-linked-acts {
    width: 11.25rem;
  }

  .organisation-summary-action,
  .organisation-summary-number-status {
    width: 5rem;
  }

  .organisation-summary-objective {
    width: 20.625rem;
  }

  .organisation-summary-activity-name,
  .organisation-summary-goal,
  .organisation-summary-activityType {
    width: 16.625rem;
  }

  .nooflinked-centre {
    margin-left: 1.125rem;
  }

  .organisation-table-horizontal {
    overflow: auto;

    .ui.table td.one.wide,
    .ui.table th.one.wide {
      width: 0.25% !important;
    }

    .ui.table thead tr:first-child > th {
      background: $background !important;
      position: sticky !important;
      top: 0;
    }
  }

  .org-report-list {
    margin-top: 0.313rem;
  }

  .org-sort {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0rem 1.5rem $spacing-lg 0rem !important;

    .activities-filter-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0rem 1.25rem 0.125rem 0rem;

      .activities-filter-container {
        display: flex;
        flex-direction: row;
      }

      .activities-filter {
        display: flex;
        align-items: center;
        color: $info;
        font-family: $fontFamily;
        font-size: $Body-Default;
        line-height: 1.375rem;
        margin-left: 0.938rem;
      }
    }
  }

  .data.goal {
    padding-bottom: 1.875rem;
    font-size: $Body-Default;
  }

  .development-types {
    display: flex;
    flex-direction: column;
    @include Body-Default;
    margin-left: 1.25rem;
    letter-spacing: 0rem;
    text-align: left;

    .act-summary-dev {
      color: #000000;

      .activeTypeActive {
        width: 13rem;
      }

      .In-development {
        width: 13rem;
      }

      .activeTypeComplete {
        width: 13rem;
      }
    }

    .gap {
      margin-right: 0.188rem;
    }
  }

  .orange-text {
    color: $amber;
  }

  .blue-text {
    color: $blue;
  }

  .green-text {
    color: $green;
  }

  .icon-org-summary {
    cursor: pointer;
  }

  .report-goal {
    display: flex;
    flex-direction: row;
    text-align: left;
    height: auto;

    label {
      flex: 1;
    }

    .goal-description {
      display: flex;
      flex-direction: column;

      .goal-info {
        color: black !important;
      }
    }

    .reportDescription {
      font-size: $Body-Small;
    }

    .reportDate {
      @include Body-Small($Text-Secondary);
      width: 149px;
    }

    .square {
      min-height: $spacing-sm;
      height: $spacing-sm;
      min-width: $spacing-sm;
      width: $spacing-sm;
      border-radius: 0.188rem;
      margin-right: 0.625rem;
      margin-bottom: 0.313rem;
      background-color: $info;
    }

    .activity-completed {
      background-color: $blue;
    }

    .activity-success {
      background-color: $green;
    }

    .activity-warning {
      background-color: $amber;
    }

    .activity-error {
      background-color: $red;
    }

    .circle {
      min-height: 0.625rem;
      height: 0.625rem;
      min-width: 0.625rem;
      width: 0.625rem;
      border-radius: 0.625rem;
      margin-right: 0.25rem;
      margin-bottom: 0.313rem;
      background-color: $info;
    }

    .circle-right {
      margin-right: 0.625rem;
      margin-top: 0.313rem;
    }

    .activity-completed {
      background-color: $blue;
    }

    .activity-success {
      background-color: $green;
    }

    .activity-warning {
      background-color: $amber;
    }

    .activity-error {
      background-color: $amber;
    }
  }

  .percentage {
    display: flex;
    flex-direction: row;

    .per-right {
      margin-left: 0.25rem;
    }
  }

  .intense-outcome-hr {
    border: none;
  }

  .legends-bottom-margin {
    margin: 0rem !important;
    margin-bottom: $spacing-lg !important;
    padding: 0rem !important;
    border: none !important;
  }

  .report-activity-count {
    margin: 0rem $spacing-xl !important;
  }

  .report-new {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-lg;
    padding-left: $spacing-sm;
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
    border: 0.063rem solid #e0e0e0;
    border-bottom: none;

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    .report-ref-info {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      padding-bottom: 0.625rem !important;
    }

    .reports {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      padding-bottom: 0.625rem !important;
    }

    .counts {
      display: flex;
      flex-direction: row;

      .diff {
        padding: 0rem 70px 0rem 0.063rem !important;
      }
    }

    .chart-type-label-row {
      margin-bottom: $spacing-sm !important;
      margin-top: $spacing-sm !important;
    }

    .ui.grid {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }

  .self-assessed-legends-labels {
    display: flex;
    flex-direction: row;
    margin-left: $spacing-xl !important;

    .after-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #d16400;
    }

    .before-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #2f80ed;
      margin-left: 0.938rem !important;
    }

    .after-response {
      width: 2.857rem;
      height: 1.25rem;
      background-color: rgba(253, 143, 88, 0.5);
      margin-left: 0.938rem !important;
    }

    .before-response {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #97bff6;
    }

    .response-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #d4ccf9;
      margin-left: 0.938rem !important;
    }

    .blue-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #2f80ed;
      margin-left: 0.938rem !important;
    }

    .average-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #998bf2;
    }

    .text-change {
      margin-left: 0.938rem !important;
    }
  }

  .counts {
    display: flex;

    .diff {
      margin-right: 40px;
    }
  }

  .report-ref-info {
    @include Body-Default;
    line-height: 20px;
    padding-bottom: 10px !important;
    text-transform: capitalize;
  }

  .extra-margin {
    margin-top: 1rem !important;
  }

  .post-pre-container {
    .post-pre-legend-custom {
      margin-top: 0.625rem !important;
      margin-left: $spacing-xl !important;
      margin-bottom: -0.625rem !important;
    }
  }

  .post-pre-border {
    border: 0.063rem solid #e0e0e0;
    border-radius: 0.25rem !important;
    padding: 1.25rem 0.625rem;
  }

  .chart-border {
    padding: 1.25rem 0.625rem !important;
    border: 0.063rem solid #e0e0e0 !important;
    border-radius: 0.25rem !important;
  }

  .chart-border-single-eval-plans {
    margin-left: 0rem !important;
    padding: 1.25rem 0.625rem !important;
    border: 0.063rem solid #e0e0e0 !important;
    border-radius: 0.25rem !important;
  }

  .legend-labels {
    display: flex;
    flex-direction: row;
    margin-top: 0.625rem;
    margin-left: $spacing-xl;
    margin-bottom: $spacing-lg;

    .after-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #d16400;
      margin-left: 0.938rem !important;
    }

    .before-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #2f80ed;
      margin-left: 0.938rem !important;
    }

    .average-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #d16400;
    }

    .response-box {
      width: 2.857rem;
      height: 1.25rem;
      background-color: rgba(253, 143, 88, 0.5);
      margin-left: 0.938rem !important;
    }

    .before-response {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #97bff6;
    }

    .after-response {
      width: 2.857rem;
      height: 1.25rem;
      background-color: rgba(253, 143, 88, 0.5);
      margin-left: 0.938rem !important;
    }

    .average-box-intercept {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #424242;
      margin-left: 0rem !important;
    }

    .response-box-intercept {
      width: 2.857rem;
      height: 1.25rem;
      background-color: #eeeeee;
      margin-left: 0.938rem !important;
    }

    .text-change {
      margin-left: 0.938rem !important;
    }
  }

  .activity-summary-new-table {
    margin: 0rem !important;

    tr {
      border: 0.063rem solid #e0e0e0;

      th {
        border: none !important;
      }

      &:hover {
        background-color: $white !important;
        color: $Primary !important;
      }
    }

    td {
      padding: $spacing-lg 1rem !important;
    }

    .normal-label {
      border-right: 0.063rem solid #e0e0e0 !important;
    }

    td.top.aligned.two.wide.normal-label {
      padding-left: $spacing-xl !important;
    }
  }

  .outcome-measures-label {
    padding-left: 1rem !important;
  }

  .outcome-measure-label {
    @include Body-Default();
  }

  .in-development {
    @include Body-Default($Primary);
    background-color: #fff4eb !important;
  }

  .not-set {
    background-color: $background !important;
  }

  .active-report {
    @include Body-Default($Success);
    background-color: #f2fff8 !important;
  }

  .report-comp {
    @include Body-Default($Info-dark);
    background-color: #ecf2ff !important;
  }

  .report-total {
    @include Body-Default();
  }

  .margin-down {
    margin-top: $spacing-lg !important;
  }

  .graph-tab {
    .ui.secondary.pointing.menu {
      margin-left: 0;
      margin-right: 0;
      border-bottom: none !important;
    }
  }

  .report-border-container {
    border: 0.063rem solid #e0e0e0;
    padding: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .msg-report {
    .ui.blue.message {
      background-color: #eff6ff !important;
      box-shadow: none !important;
      @include Body-Default(#1e40af);
    }

    .ui.icon.message > .icon:not(.close) {
      display: block;
      flex: 0 0 auto;
      width: auto;
      line-height: 1;
      font-size: $h6;
      opacity: 0.8;
      color: #002f9b !important;
    }

    border-radius: 0.375rem;
    margin-bottom: 1.563rem !important;
    background-color: red !important;
  }

  .msg-series-evaluation {
    .ui.yellow.message {
      background-color: #fff7da !important;
      box-shadow: none !important;
      color: #bd640d !important;
      font-size: $Body-Small !important;
    }

    .ui.icon.message > .icon:not(.close) {
      display: block;
      flex: 0 0 auto;
      width: auto;
      line-height: 1;
      font-size: $h6;
      opacity: 0.8;
      color: #bd640d !important;
    }

    border-radius: 0.375rem;
    margin-bottom: 1.563rem !important;
    background-color: red !important;
  }

  .financial-flex {
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;

    .title-fin {
      font-weight: 400;
      font-size: $Body-Small;
      line-height: 1.25rem;
    }

    .sub-fin {
      @include Body-Small($Text-Secondary);
    }
  }
}

.fin-head {
  display: flex;
  flex-direction: column;

  .ui.selection.dropdown {
    min-width: 250px !important;
  }

  .includeGraph-dropdown {
    max-width: 35%;
  }

  .includeGraph-dropdown .menu {
    max-height: 115px !important;
  }

  .includeGraph-dropdown {
    a.ui.label {
      padding: 0.563rem !important;
      margin-left: 0.125rem !important;
    }
  }

  .includeGraph-dropdown.ui.multiple.dropdown {
    padding: 0.063rem;
    margin-top: 0.063rem;
  }

  .include-container {
    max-height: 6.25rem;
    @include Body-Small($Text-Secondary);
    margin: 0.938rem 0rem 1rem 1.25rem;
  }

  .program-activity-subheading {
    height: 1.125rem;
    @include Body-Small($Text-Secondary);
  }
}

.chart-holder {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }
}

.report-objective-table {
  width: 100%;
  padding: 0px !important;
  .row {
    margin: 0rem !important;
    padding: 0rem !important;
  }

  .headline-holder {
    padding: 0rem !important;
    margin: $spacing-lg $h6;
    @include Body-Default-Semibold;
  }

  .activity-count-info {
    margin: $spacing-lg $spacing-xl !important;
  }

  .org-notification-container {
    margin: $spacing-lg $spacing-xl !important;
    display: block !important;
  }
}

.report-preview {
  .dropdown {
    min-height: 2.857rem !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    border-color: #c3d3e6 !important;
    border-radius: 0.5rem !important;
  }

  .export-dropdown-btn .menu.transition.left.visible {
    margin-top: 0rem !important;
  }

  .control-holder {
    padding: $spacing-lg 1.429rem !important;
  }

  .ui.grid {
    row-gap: $spacing-lg;
  }
}

.fin-head .ui.selection.dropdown {
  min-width: 250px !important;
}

.ui.selection.dropdown .menu > .item {
  border-top: 1px solid #fafafa;
  padding: 0.78571429rem 1.14285714rem !important;
  white-space: normal;
  word-wrap: normal;
}

// .ui.grid .segments .chart-holder .activity-count-info .ui.grid {
//   margin-top: 0.5rem !important;
// }

.clustericon {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -15px;
  left: -15px;
  color: #040404;
  text-align: center;
  //  background: url("../../../assets/map/Active.svg");
  background: url(https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png);
  background-size: contain;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 50px;
}

.outcomes-measured {
  .outcomes-summary-chart-div {
    padding: 16px 32px 24px 32px;
  }
}

.row.programme-activity {
  margin: 0rem 2.28571rem !important;
}

.programme-activity-heading {
  .content {
    display: flex;
    justify-content: space-between;
    padding: $spacing-lg 0rem;

    label {
      @include Body-Large-Semibold;
    }
  }
  margin: 0rem 1.5rem;
  border-top: 1px solid $Stroke;
}

.programme-map {
  margin: 0rem $spacing-xl;
}
.programme-outcomes-report {
  margin: 0rem 2.28571rem 2.28571rem;
}

.outcome-address-back {
  margin-top: 5px;
  margin-bottom: 5px;
  .outcome-address-back-arrow {
    border-style: none !important;
  }
}

.outcomes-reported {
  .outcomes-summary-chart-div {
    padding: 16px 32px 24px 32px;
  }
}
// .chart-header {
//   @include Body-Default-Semibold;
//   padding-top: 20px;
//   padding-left: 35px;
// }

.outcomes-container {
  .chart-header {
    @include Body-Default-Semibold;
    padding-top: 1.25rem;
    padding-left: 2.5rem;
  }
}

.evaluation-outcome-summary-charts {
  .chart-header {
    @include Body-Default-Semibold;
    padding-bottom: 1rem;
  }
}

.chart-header-self-assessed-separate {
  @include Body-Default-Semibold;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 5px;
}
.chart-header-outcome-sum {
  @include Body-Default-Semibold;
  padding-bottom: 20px;
  padding-top: 20px;
}

.ui.raised.segments.Contractor,
.ui.raised.segments.Activities,
.ui.raised.segments.Organisation,
.ui.raised.segments.Activity_Type,
.ui.raised.segments.Activity_Venues,
.ui.raised.segments.Activity_Participants,
.ui.raised.segments.Outcomes_Measured,
.outcomes-reported {
  .no-goals-container {
    height: 12.143rem;
  }
}

.organisation-summary-table {
  ul {
    padding-left: 1rem;
  }
}

.google-map-popup {
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  padding: 0.625rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  min-width: 3.125rem;
  max-width: 25rem;
  min-height: 2.5rem;
  max-height: 18.75rem;
  overflow: hidden;
  top: -4.5rem;
  left: 0.625rem;
  transform: translateX(-50%);
  overflow-y: auto;
  z-index: 1;

  .heading {
    display: flex;
    align-items: center;
    margin: 4px 0px;
  }

  .development {
    width: 0.625rem;
    height: 0.625rem;
    background-color: $amber;
    border-radius: 50%;
    margin-right: 0.313rem;
  }

  .active {
    width: 0.625rem;
    height: 0.625rem;
    background-color: $green;
    border-radius: 50%;
    margin-right: 0.313rem;
  }

  .completed {
    width: 0.625rem;
    height: 0.625rem;
    background-color: $blue;
    border-radius: 50%;
    margin-right: 0.313rem;
  }

  .min-point {
    width: 0.25rem;
    height: 0.25rem;
    background-color: black;
    border-radius: 50%;
    margin-right: 0.313rem;
  }

  .label-text {
    font-size: 1rem;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .activity-count {
    font-size: 1rem;
    color: $Gray-70;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .view-details {
    color: $amber;
    margin-top: 0.5rem;
  }

  .activity-list {
    margin: 1rem 0rem 1.5rem;
  }

  .activity-name {
    margin-bottom: $spacing-xs;
    margin-left: $spacing-xs;
  }
}

.management-summary-act-status {
  margin-top: 20px !important;
}

.bar-chart {
  padding-right: 1rem !important;
}
