@import '../../../mixins.scss';
@import '../../../variables.scss';

// oraganization profile / layout
.ui.grid > .org-row {
  .org-sub-column {
    padding-top: 0rem;
    padding-bottom: 1.875rem;
  }
}

.name-dropdown {
  @include Body-Default;
  margin-bottom: 0.25rem;
}

.year-dropdown {
  @include Body-Small($Text-Secondary);
}

.dropdown-content-goals {
  margin-left: 5px !important;
}

.dropdown-content-row {
  padding: 0rem !important;
}

.dropdown-selection {
  .ui.selection.dropdown {
    min-height: 0rem !important;
    border: none !important;
    box-shadow: none !important;
  }

  width: max-content !important;

  .ui.fluid.dropdown {
    border: none !important;
  }

  .ui.dropdown .menu > .item:hover {
    background-color: $Gray-30 !important;

    .text-color {
      color: $Text-Primary;
    }
  }

  .ui.selection.dropdown .menu {
    width: max-content !important;
    border: none !important;
  }
}

.archive-dropdown {
  border-radius: 1rem !important;
}

@media only screen and (max-width: 767px) {
  .ui.grid > .org-row {
    margin-left: $spacing-xl !important;
    margin-right: 2.563rem !important;
  }

  .ui.grid > .org-row .org-sub-column label {
    margin-left: 0.313rem !important;
  }
}

.customColor-wrapper {
  width: 200px !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 1px solid #ef8123 !important;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ef8123 !important;
  font-family: Inter !important;
  font-size: $Body-Default !important;
}

.customColor-wrapper-contact {
  width: auto !important;
  height: 2.857rem !important;
  background: #ffffff !important;
  border: 0.063rem solid #ef8123 !important;
  box-sizing: border-box;
  border-radius: 0.25rem;
  color: $amber !important;
  font-family: Inter !important;
  @include btn;
  @include Body-Default($amber);
}

.ui.button.goal-add-button:hover,
.ui.buttons.goal-add-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.ui.button.customColor:hover,
.ui.buttons.customColor .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.ui.button.no-background-btn {
  background-color: #fff !important;
  border: 0.125rem solid #ef8123;
  color: $gradientRight !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: bold !important;
  line-height: 1.375rem !important;
  text-align: center !important;
}

.ui.button.customColor {
  background: $Primary;
  border-radius: 0.25rem !important;
  @include Body-Default($White);
  line-height: 1.25rem;
  padding: 0.625rem 1rem;
  border: 0.063rem solid $Primary;
  height: 2.857rem;

  color: $white !important;

  &:active {
    color: $Primary !important;
  }
}

.ui.button.goal-add-button {
  background-color: $gradientRight !important;
  color: #fff !important;
  min-width: 122px !important;
  border-radius: 0.25rem !important;
  height: 40px !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  text-align: center !important;
  padding: 0.25rem 1rem;
}

.ui.button.customColor-custom {
  background-color: $gradientRight !important;
  color: #fff !important;
  min-width: 5.063rem !important;
  width: 5.063rem !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  text-align: center !important;
  min-height: 2rem !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
}

.ui.button.custom-default {
  padding: 0 !important;
  height: 2.857rem !important;
  min-width: 21.25rem !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: bold !important;
  line-height: 1.375rem !important;
  text-align: center !important;
}

.ui.button.customColor:active {
  background-color: $gradientRight !important;
}

.ui.button.contractor:hover,
.ui.buttons.contractor .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.ui.button.contractor {
  background-color: $gradientRight !important;
  color: #fff !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: bold !important;
  line-height: 1.375rem !important;
  text-align: center !important;
}

.ui.button.contractor:active {
  background-color: $gradientRight !important;
}

.ui.button.white:hover,
.ui.buttons.white .button:hover {
  background-color: $white !important;
  color: $gradientRight !important;
}

input[type='checkbox'] {
  height: 1.125rem;
  width: 1.125rem;
  border: 0.063rem solid #b1b1b1;
  border-radius: 0.25rem;
  background-color: $white;
}

.custom-margin {
  margin-top: 2.25rem !important;
}

.reassign-btn {
  background-color: $gradientRight !important;
  color: white !important;
  font-family: inherit !important;
  height: 2.857rem !important;
  width: 8.125rem !important;
  font-weight: normal !important;
}

.ui.button.change-pw-btn {
  background: $gradientRight;
  border: 0.125rem solid $gradientRight;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: $Body-Small;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  font-family: Inter;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
  margin-top: 1.875rem;
}

.ui.button.profile-save-btn {
  background: $gradientRight;
  border: 0.125rem solid $gradientRight;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: $Body-Small;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  font-family: Inter;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
  margin-top: 1.875rem;
}

.custom-new-reassign {
  margin-top: 1.563rem !important;
  margin-left: 430px !important;
  margin-bottom: 0rem !important;
}

.user-activity .table-right-border {
  border-right: 0.063rem solid #22242626 !important;
}

.custom-button-ico {
  height: 1.25rem;
  width: 1.25rem;
  padding-bottom: 0.063rem;
}

.user-activity {
  label {
    margin-bottom: 0rem !important;
  }

  @media only screen and (max-width: 767px) {
    .modal-section {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .org-trigger-actions {
    text-align: right;
    cursor: pointer;
  }

  .org-trigger-actions:hover {
    color: $gradientRight;
  }

  :hover {
    background-color: #ba212100;
  }
}

.tab-section {
  padding: 0 0.938rem 0.938rem;
  width: 100%;

  .activity-tabs > div > .item {
    flex: 1;
    justify-content: center;
  }

  .activity-tabs > div > .item.active {
    border-bottom-color: $gradientRight !important;
  }

  .activity-tabs > .ui.bottom.attached.segment.tab {
    padding: 0;
  }
}

// organization goals
.ui.button.white {
  display: flex;
  justify-content: center;
  height: 2.857rem !important;
  width: 97px !important;
  background-color: $white !important;
  color: $gradientRight !important;
  @include Body-Default;
  text-align: center !important;
  border-radius: 0 0.5rem 0.5rem 0rem !important;
  border: 0.063rem solid #c3d3e5;
}

.ui.button.white.custom {
  display: flex;
  justify-content: center;
  height: 2.857rem !important;
  width: 9.375rem !important;
  background-color: $white !important;
  color: black !important;
  @include Body-Default;
  text-align: center !important;
  align-items: flex-start !important;
}

.ui.button.white:active {
  background-color: $white !important;
}

.org-input-file {
  display: none;
}

.upload-report-model {
  .form-group {
    label {
      @include Body-Small($Text-Primary);
    }
  }

  .upload-media-header {
    @include Body-Default-Semibold;
  }

  .browse-header {
    @include Body-Small($Text-Primary);
  }

  .core-report-modal-drop {
    margin-top: 0.813rem;
    margin-bottom: 1.313rem;
  }

  .browse-file {
    border-radius: 0.5rem 0 0 0.5rem !important;
    background-color: #ffffff !important;
    font-size: $Body-Small !important;
  }
}

.browse-input {
  width: 400px;
  max-width: 400px;
}

.browse-input-custom {
  width: 79% !important;
}

.browse-info {
  font-family: $fontFamily;
  font-size: $Body-Small;
  margin-top: 0.25rem;
}

.custom-margin-goals {
  margin-top: 1rem !important;
}

.invite-user-button {
  margin-top: 1.25rem !important;
  width: 157px !important;
  height: 2.857rem !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Default !important;
  background: #ef8123 !important;
  border-radius: 0.25rem !important;
  color: #ffffff !important;
}

.right-heading-container .msg {
  font-size: $h5 !important;
}

.no-goals-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;

  border-radius: 0.25rem;

  li.ref-item:first-child {
    padding: 0rem !important;
  }

  label.msg {
    padding: 1rem 0rem 0.063rem 0rem;
  }

  .details-conatiner {
    .msg-header {
      width: 100%;
      font-style: normal;
      color: #000;
      font-family: $fontFamily;
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: $h5;
      line-height: 2.5rem;
    }

    .msg {
      @media only screen and (max-width: 400px) {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        width: 100%;
      }

      width: 100%;
      font-style: normal;
      font-family: $AuthPageFont;
      font-size: $h5;
      font-weight: 500;
      line-height: 2.813rem;
      display: flex;
      text-align: center;
      justify-content: center;
    }

    .desc {
      @media only screen and (max-width: 400px) {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }

      width: 100%;
      margin-top: 1rem;
      color: $info;
      font-family: $AuthPageFont;
      font-size: $Body-Default;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      padding-left: 3.125rem;
      padding-right: 3.125rem;
      color: #3b3b3b;
    }
  }

  .genericVerify-button {
    @media only screen and (max-width: 48rem) {
      padding-left: 1.875rem;
      padding-right: 1.875rem;
      min-width: 12.5rem;
      width: auto;
    }

    height: 3.125rem;
    // width: 450px;
    border-radius: 0.25rem;
    background-color: $gradientRight;
    font-family: $AuthPageFont;
    font-size: $Body-Default;
    font-weight: 500;
    line-height: 1.375rem;
    margin-top: 32px;
    align-items: center !important;
    color: white;
  }

  .genericVerify-button:hover {
    background-color: $gradientRight !important;
    color: $white !important;
  }

  .btn-primary.genericVerify-button {
    background-color: $gradientRight !important;
    border-color: $gradientRight !important;
    color: white;
  }
}

.no-goals-container-bg {
  background: $background;
  border: 0.5px solid #c3d3e6;
}

.no-access {
  position: fixed;
  top: 50%;
  left: 50%;

  .msg {
    margin-top: 1.875rem;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: center;
  }
}

.org-goals-table th,
.org-goals-table td {
  border-top: none !important;
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
}

.org-goals-table tfoot .ui.secondary.pointing.menu a.item.active {
  border: 0 !important;
  background-color: #ef8123 !important;
  color: #fff !important;
}

.goals-description {
  display: flex;
  flex-direction: column;

  .goals-policy {
    color: $info;
    font-family: $fontFamilyLight;
    font-size: $Body-Default;
    line-height: 1.375rem;
    font-weight: 300;
  }

  .setHeading {
    @include Body-Default;
  }

  .goal-labels {
    @include Body-Default($Gray-70);
    border-radius: 0.5rem;
    background-color: $Gray-20 !important;
  }
}

@media only screen and (max-width: 767px) {
  .goals-actions {
    flex-direction: column;
  }

  tfoot .ui.secondary.pointing.menu a.item {
    margin-bottom: 0.25rem !important;
  }
}

@media only screen and (max-width: 1624px) {
  tfoot .ui.secondary.pointing.menu a.item {
    max-width: 2rem;
    max-height: 2.25rem;
  }
}

.goals-actions {
  max-height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: $spacing-lg !important;

  .goals-edit {
    color: $gradientRight;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 400;
    line-height: 1.375rem;
    cursor: pointer;
  }

  .goals-delete {
    color: $red;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: right;
    cursor: pointer;
  }
}

.ui.modal > .actions {
  background: white !important;
}

.ui.modal {
  border-radius: 0.5rem;
}

.ui.modal > .content {
  background: $white !important;

  .model-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0rem;
  }

  .model-contactor-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.25rem;
  }

  @media only screen and (max-width: 767px) {
    .model-button {
      flex-direction: column;

      .goal-create-button {
        margin-top: 1.25rem;
      }
    }

    .model-contactor-buttons {
      flex-direction: column;

      .goal-create-button {
        margin-top: 1.25rem;
      }
    }
  }

  .org-inputs-pass-input {
    height: 2.857rem !important;
    font-size: $Body-Small !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
  }

  .add-user-modal {
    .org-inputs-password {
      height: 2.857rem !important;
      border-radius: 0.5rem 0rem 0rem 0.5rem !important;
    }
  }

  .org-inputs-location-search {
    height: 2.25rem !important;
    font-size: $Body-Small !important;
    height: 36px !important;
    font-size: $Body-Default !important;
    color: $subLabel !important;
    border-radius: 8px;
  }

  .org-inputs-location-search-venue {
    font-size: $Body-Default !important;
    color: $subLabel !important;
    border-radius: 8px;
  }

  .org-inputs-location-search-contact {
    height: 36px !important;
    font-size: $Body-Default !important;
    color: $subLabel !important;
    border-radius: 0.5rem;
  }

  .org-inputs-textarea {
    min-height: 10em !important;
  }
  .org-inputs-textarea-notes {
    min-height: 10em !important;
    min-width: 46.289em !important;
  }

  .label {
    font-family: 'Inter', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.285714286rem;
    color: #000000;
  }
}

.goals-table {
  display: flex;
  flex-direction: column;

  .form-check-input:checked {
    background-color: $gradientRight !important;
  }
}

.org-goals-policy-table thead,
.org-goals-policy-table th,
.org-goals-policy-table tr {
  border: none !important;
  @include Body-Small($info);
  background-color: transparent;
}

.org-goals-policy-table th,
.org-goals-policy-table td {
  border-top: none !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.188rem;
  margin: 0;
  padding-left: 0 !important;
  border: transparent;
}

.invalid-policy {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.ui.button.core-participant-button:hover,
.ui.buttons.core-participant-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.ui.button.core-participant-button {
  width: 190px;
  border-radius: 0.25rem;
  background-color: $gradientRight;
  color: $white;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: 600;
  line-height: 0.688rem;
  text-align: center;
}

.ui.button.goal-create-button:active {
  background-color: $gradientRight !important;
}

.ui.button.act-create-button {
  background: $gradientRight;
  border: 0.125rem solid $gradientRight;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: $Body-Small;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  font-family: Inter;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
}

.ui.button.goal-create-button:hover,
.ui.buttons.goal-create-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.venue-add-button:hover,
.venue-add-button .button:hover {
  background-color: $amber !important;
  color: $white !important;
}

.ui.button.goal-create-button {
  width: 10.188rem;
  border-radius: 0.25rem;
  background-color: $gradientRight;
  color: $white;
  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.modal-toc-preview-header {
  font-family: $fontFamily;
  color: #3b3b3b;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.5rem;
}

.toc-knowing {
  font-family: Inter;
  font-style: normal;
  font-size: $Body-Small !important;
  background: #eeeeee;
}

.ui.button.act-create-button {
  background: $gradientRight;
  border: 0.125rem solid $gradientRight;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: $Body-Small;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  font-family: Inter;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem $spacing-lg;
}

.ui.button.goal-create-button:active {
  background-color: $gradientRight !important;
}

.ui.button.goal-cancel-button {
  width: 10.188rem;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $red;
  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.ui.button.goal-cancel-button:hover,
.ui.buttons.goal-cancel-button .button:hover {
  background-color: transparent !important;
  color: $red !important;
}

.ui.button.goal-cancel-button:active {
  background-color: transparent !important;
}

.ui.button.act-count-btn {
  background-color: $gradientRight;
  color: white;
  font-weight: 400 !important;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.125rem;
  text-align: center;
}

.ui.button.act-count-btn:hover,
.ui.buttons.act-count-btn .button:hover {
  background-color: $amber !important;
  color: white !important;
}

.ui.button.act-count-btn:active {
  background-color: $gradientRight !important;
}

.add-goal {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .closeicon {
    margin-top: 0.313rem;
  }
}

.ui.modal > .header:not(.ui) {
  font-size: $h6;
  font-family: $fontFamily;
  line-height: 1.28571429em;
  font-weight: 600;
  width: full;
}

.no-access {
  position: fixed;
  top: 50%;
  left: 50%;

  .msg {
    margin-top: 1.875rem;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .browse-input {
    width: auto;
    max-width: 400px;
  }

  .add-goal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-check {
  label {
    margin-left: 0.714rem;
  }
}

.form-check-different-address {
  display: flex !important;
  align-items: center !important;

  label {
    margin-left: 0.625rem;
  }
}

.activity-actions-warn {
  font-weight: normal !important;
  color: $gradientRight !important;

  text-align: right;
  cursor: pointer;
}

// organization user management
.org-users {
  .org-users-table {
    th,
    td {
      vertical-align: bottom !important;
    }

    .org-users-container {
      display: flex;
      flex-direction: column;
    }

    .org-users-default-container {
      display: flex;
      flex-direction: column;
    }

    .org-users-cdn-container {
      display: flex;
      flex-direction: row;
    }

    @media only screen and (max-width: 1624px) {
      .cdn-admin {
        height: fit-content !important;
      }
    }

    .cdn-admin {
      @include Body-Default($white);
      line-height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .org-users-mainText {
      @include Body-Default;
    }

    .org-users-text {
      @include Body-Default($Text-Secondary);
    }

    .org-users-text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .org-users-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .org-users-actions-warn {
      color: $gradientRight;
      text-align: right;
      cursor: pointer;
    }

    .org-users-actions-error {
      color: $red;
      text-align: right;
      cursor: pointer;
    }

    .org-users-actions-success {
      color: $success;
      font-family: $fontFamily;
      font-size: $Body-Small !important;
      font-weight: 600;
      line-height: 1.375rem;
      text-align: right;
      cursor: pointer;
    }
  }

  .add-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .add-user {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .org-users-actions {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .first-down {
      margin-top: 0.625rem !important;
    }
  }

  .guest-search {
    @media (max-width: 48rem) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    display: inline-flex;

    .guest-search-container {
      margin-right: $spacing-lg;

      .search-label {
        @include font-style(0.75rem, $info);
      }

      .activitySearch {
        .ui.icon.input {
          width: 100% !important;

          input {
            border-radius: 0.25rem;
            border: 0.063rem solid #ced4da;
          }
        }

        .results.transition.visible {
          display: none !important;
        }
      }
    }
  }

  // Manage user Filter
  .manage-user-drop {
    display: inline-flex;
    width: 100%;

    .manage-user-container {
      margin-right: 16px;
      .formik-custom-field {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        width: 100%;
      }
    }

    .ui.search.selection.dropdown.manage-user-type {
      min-width: 240px;
    }

    .manage-user-addUser {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .ui.label {
    margin-top: 0.188rem;
  }
}

.org-checklist {
  .org-checklist-updated {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default;
    font-style: italic;
    line-height: 1.375rem;
  }

  .org-checklist-table {
    .org-checklist-container {
      display: flex;
      flex-direction: column;
    }

    .org-checklist-default-container {
      display: flex;
      flex-direction: column;
    }

    .org-checklist-mainText {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
      font-weight: 300;
    }

    .org-checklist-text {
      @include Body-Default;
    }

    .org-checklist-text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .org-checklist-actions {
      display: flex;
      flex-direction: row;
      margin-right: 1.25rem;
    }

    .org-checklist-actions-warn {
      @include Body-Default($Primary);
      text-align: right;
      cursor: pointer !important;
    }

    .org-checklist-actions-error {
      @include Body-Default($red);
      text-align: right;
      cursor: pointer;
    }
  }

  .add-checklist {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .add-checklist {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .org-checklist-actions {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .first-down {
      margin-top: 0.625rem !important;
    }
  }
}

.ui.labeled.input > .label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $gradientRight;
}

.input-group-text {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
  cursor: pointer !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 600 !important;
  line-height: 1.375rem !important;
  width: 6.25rem !important;
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}

.input-field-wrap {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.org-inputs-custom-flex {
  height: 2.25rem !important;
  border-radius: 0.5rem !important;
  width: 90% !important;
}

.input-group-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  font-family: $fontFamily !important;
  font-size: $Body-Large !important;
  line-height: 1.375rem !important;
  width: 3.125rem !important;
  border: 0.063rem solid #ced4da;
  border-left: none !important;
  padding-left: 0.313rem;
  background: #ffffff !important;
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}

.org-inputs-custom {
  height: 2.25rem !important;
  border-radius: 0.5rem !important;
}

.org-inputs-custom-search {
  height: 2.25rem !important;
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  border-right: none !important;
}

.activity-reassign {
  padding-left: 1rem;
}

.reassign {
  display: flex;
  flex-direction: column;

  .reassign-header {
    @include Body-Default-Semibold($info);
  }

  .reassign-description {
    @include Body-Small($Text-Secondary);
  }
}

.venue-form {
  .form-check {
    display: flex;
    background-color: transparent !important;
    padding: 0;
    margin-top: 0.625rem;
    font-size: $Body-Small !important;
  }

  .venue-type-des {
    margin-bottom: 1rem;
    font-style: inherit !important;
  }

  .row-venue {
    margin: 0rem 0rem 0rem 0rem !important;
  }

  .venue-address-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem !important;
  }

  .contact-drop-search {
    .ui.selection.dropdown {
      min-height: 2.857rem !important;
    }
  }

  .error-contractor {
    border: 0.063rem solid #dc3545 !important;
  }

  .venue-inputs-new {
    height: 2.25rem !important;
    background: #ffffff;
    @include Body-Default($Text-Secondary);
    box-sizing: border-box;
    border-radius: 0.5rem !important;

    .ui.input > input {
      height: 2.25rem !important;
      background: #ffffff;
      @include Body-Default($Text-Secondary);
      box-sizing: border-box;
      border-radius: 0.5rem;
    }
  }
  .anonymous-row {
    margin-top: -50px;
  }

  .venue-add-button {
    background: $gradientRight;
    border: 0.125rem solid $gradientRight;
    box-sizing: border-box;
    border-radius: 0.25rem;
    font-size: $Body-Small;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    font-family: Inter;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem $spacing-lg;
  }

  .location-search-max-width {
    width: 100%;
  }
}

.core-data-grid {
  overflow-x: auto;
  background-color: $white;

  .date-holder {
    margin-top: 38px;
    margin-bottom: 0.75rem;
    margin-left: 0.313rem;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.188rem;
  }

  .table-holder {
    overflow-x: auto;
    width: 100%;
  }
  .inputs-table {
    margin-top: 3rem;

    .inputs-table-head {
      background-color: #f5e0e3 !important;
      th {
        @include Body-Default-Semibold($Gray-70);
      }
    }
  }
  .inputs-table {
    margin-top: $spacing-lg !important;
    margin-bottom: 2.857rem;

    thead {
      tr {
        th {
          background-color: #f5e0e3 !important;
          border-radius: 0rem !important;
          border-top: 0rem;
          padding-top: 1rem !important;
          padding-bottom: 1rem !important;

          //@include font-style($spacing-sm, $info, 600);
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 1rem 1rem 1rem 0rem !important;
          @include font-style(1rem, $info);
        }
        td:first-child {
          padding-left: $spacing-xl !important;
        }
      }
    }
  }
}

.coredata-edit-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .coredata-edit-area-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    .coredata-edit-input-container {
      display: flex;
      flex-direction: column;
      margin-top: -0.5rem;
    }

    .input {
      height: 2rem;
      width: 90%;
    }

    .input-err {
      border: 0.063rem solid #ff0000; //when error
      border-radius: 0.5rem !important;
      height: 42px !important;
    }

    .error-msg-div {
      @include field-err;
    }

    .error-msg-hidden {
      height: 2.857rem !important;
      display: none;
    }
  }

  .coredata-edit-action-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .coredata-edit-text {
      color: $gradientRight;
      cursor: pointer;
    }

    .actions {
      display: flex;

      .coredata-cancel-text {
        margin-left: 0.571rem;
        color: $gradientRight;
        cursor: pointer;
      }
      .coredata-update-text {
        color: $gradientRight;
        cursor: pointer;
      }
    }
  }
}

.org-report-year {
  width: 70%;
}

.report-section {
  padding: 0 0 2.5em 1em;
}

@media only screen and (max-width: 400px) {
  .report-section {
    padding: 0 0 2.5em 0em;
  }

  .browse-info {
    text-align: center;
  }

  .org-report-year {
    width: 70%;
    text-align: center;
  }
}

.select-report-year {
  width: 100%;
  border-radius: 0.5rem !important;
}

.opacity-date {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.multi-stage-evaluation-toggle {
  label {
    margin-bottom: 0rem;
  }
}

.two-step-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 25%;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  label {
    flex: 2;
    margin-right: 0.938rem;
  }

  margin: 1.25rem 0rem;

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.user-act-heading-container {
  display: flex;
  flex-direction: column;
  text-align: center;

  .user-act-heading-type {
    @include Body-Default($Text-Secondary);
    text-align: center;
  }
}

.auth-mode {
  margin: -0.8rem 2.8rem;

  @media only screen and (max-width: 767px) {
    margin: -0.8rem 2.2rem;
  }
}

.auth-code {
  margin: -0.8rem $spacing-lg;

  @media only screen and (max-width: 767px) {
    margin: -0.8rem 1rem;
  }
}

.auth-code-label {
  color: #3b3b3b !important;
  font-size: $Body-Small;
  margin-bottom: 0.5rem;
}

.auth-code-input {
  width: 100% !important;

  input {
    font-size: $Body-Small !important;
  }
}

.code-field-settings.form-control {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
}

.reference-holder-bottom {
  margin-bottom: 1.25rem;
}

.reference-doc-holder-bottom {
  margin-bottom: 1.25rem;
}

.reference-holder-margin {
  margin-right: -0.938rem !important;
}

.new-label {
  margin-left: 0.625rem;
  color: #dc3545;
  background: #e8cfcf;
  border-radius: 0.313rem;
  padding: 0rem 0.375rem;
}

.pre-line {
  white-space: pre-line;
}

.label-padding {
  margin-bottom: 0.125rem !important;
}

.broadcast-form {
  .form-check {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.invalid-dropdown-input {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.broadcast-dropdown .menu {
  max-height: 115px !important;
}

.broadcast-dropdown {
  a.ui.label {
    padding: 0.563rem !important;
    margin-left: 0.125rem !important;
  }
}

.broadcast-dropdown.ui.multiple.dropdown {
  padding: 0.063rem;
  margin-top: 0.063rem;
}

.broadcast-form {
  .onlyAdminCheckbox {
    margin-top: 0.125rem !important;
  }
}

tfoot .ui.secondary.pointing.menu a.item.active {
  border: 0 !important;
  background-color: #ef8123 !important;
  color: #fff !important;
}

.ui.button.support-mail {
  width: 290px !important;
}

.btn-right {
  display: flex !important;
  justify-content: flex-end;

  @media only screen and (max-width: 767px) {
    display: block !important;
  }
}

.financial-method-container {
  padding: 1rem;
  width: auto;
  //display: grid;
  grid-template-columns: 6fr 9fr;
  margin-bottom: $spacing-xl;
  background: #fafafa;
  border: 0.5px solid #d6d6d6;
  border-radius: 0.25rem;

  .right-column {
    margin-left: 64px !important;
  }

  .financial-method-header {
    color: $info !important;
    grid-column: 1/3;
    font-family: $fontFamily !important;
    line-height: 1.25rem !important;
    font-weight: 600;
    font-size: $Body-Default;
    line-height: 1.5rem;
    margin: 0rem !important;
  }

  .financial-method-radio {
    margin-top: $spacing-lg;
    display: flex;
    align-items: stretch;

    .financial-method-radio-btn {
      margin-top: 0.125rem !important;
    }

    .financial-method-radio-text {
      flex: 1;
      @include Body-Default($Text-Primary);
      padding-left: 1.25rem !important;

      // padding-top: 1.438rem !important;
      .financial-help-method {
        @include Body-Small($Text-Secondary);
        padding-left: 0.375rem !important;
      }

      .financial-help-method-org {
        @include Body-Small($Text-Secondary);
      }
    }
  }
}

.activity-description {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: 300 !important;
    line-height: 1.375rem !important;
  }

  .square {
    min-height: 0.5rem !important;
    height: 0.5rem;
    min-width: 0.5rem !important;
    width: 0.5rem;
    border-radius: 0.188rem;
    margin-right: 0.625rem;
    margin-bottom: 0.313rem;
    background-color: $info;
  }

  .activity-completed {
    background-color: $blue;
  }

  .activity-success {
    background-color: $green;
  }

  .activity-warning {
    background-color: $amber;
  }

  .activity-error {
    background-color: $red;
  }
}

.actions-warn {
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 600 !important;
  line-height: 1.375rem !important;
  text-align: right !important;
  cursor: pointer !important;
}

.broadcast-dropdown-label {
  margin-top: 0.188rem;
}

.broadcast-dropdown {
  margin-bottom: 1rem;
}

.financial-method-container-admin-broadcast {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;

  .financial-method-radio {
    height: 2.857rem;
  }

  label.form-check-label {
    margin-bottom: 0.188rem;
  }

  @media only screen and (max-width: 48rem) {
    grid-template-columns: 100%;

    .financial-method-radio {
      height: 1.875rem;
    }
  }

  .financial-method-header {
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    margin: 0.5em 0 1.5em 0;
  }

  .financial-method-radio {
    display: flex;
    align-items: center;

    .financial-method-radio-btn {
      margin-top: 0.125rem !important;
    }

    .financial-method-radio-text {
      flex: 2;
      @include Body-Default-Semibold($Text-Secondary);
      padding-left: 0.625rem !important;
      padding-top: 0.313rem !important;

      .financial-help-method {
        @include Body-Small($Text-Secondary);
        padding-left: 0.375rem !important;
      }
    }
  }
}

.inputs-left-align-flex {
  padding-left: 0rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type='number'] {
    -moz-appearance: textfield !important;
  }
}

.inputs-right-align-flex {
  padding-right: 0rem;
}

.venue-row {
  display: flex;
}

.modal-header-font {
  font-size: $Body-Default !important;
  font-size: 300 !important;
  padding-top: 1.25rem !important;
}

.new-activity-modal-close-icon {
  float: right;
  font-size: $h5 !important;
  margin-top: $spacing-sm !important;
  margin-right: 1rem !important;
  width: fit-content;
  cursor: pointer !important;
}

.contact-flex {
  display: flex;
  flex-wrap: wrap;

  .org-checkbox {
    div {
      display: flex;
      background-color: #fafafa !important;
    }
  }

  .ui.input {
    display: inherit !important;
  }

  .ui.icon.input > input {
    width: 318px !important;
    height: 2.25rem !important;
    background: #ffffff;
    font-size: $Body-Small !important;
    color: #757575 !important;
    box-sizing: border-box;
    border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  }

  .ui.search > .results {
    border-radius: 0.5rem !important;
  }

  .ui.search > .results .result {
    padding: 0.625rem 0rem 0rem 0.625rem !important;
  }
}

.organization-save-btn {
  height: 2.857rem !important;
  width: 21.25rem !important;
  margin-top: 1.25rem !important;
  background-color: $gradientRight !important;
  font-family: $fontFamily !important;
  color: $white !important;
  font-weight: 400 !important;
}

.select-items-row-org {
  display: flex;
  flex-direction: column;
  margin-top: 1rem !important;

  .org-inputs {
    width: 500px !important;
  }

  .error-container {
    display: flex;
    flex-direction: column;

    label {
      color: #dc3545 !important;
    }

    .ui.search.selection.dropdown > input.search {
      border: 0.063rem solid #dc3545 !important;
      margin-top: -0.063rem !important;
      margin-left: -0.125rem !important;
      border-radius: 0.5rem !important;
      width: 500px !important;
    }
  }
}

.core-report-drop {
  width: 9.75rem !important;

  .form-control {
    border: none !important;
  }
}

.upload-btn-core {
  background-color: $gradientRight !important;
}

.core-text {
  margin-left: $spacing-xl;
  margin-bottom: $spacing-lg;
}

.actions-core {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.core-download {
  color: $Primary !important;
  margin-right: $spacing-lg !important;
  cursor: pointer;
}

.core-remove {
  color: $red !important;
  text-align: center;
  cursor: pointer;
}

.org-checklist-actions-warn {
  @include Body-Default($Primary);
  text-align: right;
  cursor: pointer !important;
}

//organisation
.update-organisation-details {
  @include segment-container;

  .row {
    margin-top: 0rem !important;
  }

  .ui.grid > .org-row .org-sub-column .form-group {
    margin-top: 1rem;
  }

  .form-check {
    padding: 0;
  }

  .upload-container {
    .row {
      margin: 0rem !important;
    }
  }

  .Primary-Button {
    margin-top: $spacing-lg;
  }
}

.set-cultural-assets-and-budgeted-recurrent-expenditure {
  .ui.grid > * {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .row {
    padding: $spacing-lg $spacing-xl !important;
  }
  label.segment-heading {
    margin: 0rem;
  }

  .form-group {
    margin-bottom: 0rem !important;
  }

  .core-data-reference-table {
    margin-top: $spacing-lg;

    .core-data-label {
      width: 90%;
    }
  }
}

.organization-settings {
  .right-column {
    margin-left: 4.571rem !important;
  }
}

.organisation-goals {
  .no-goals-container {
    margin-bottom: 0.85714rem;
  }
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .text-muted {
    @include Body-Small($Text-Secondary);
  }

  .href-label {
    display: table;
  }

  .content-segment-settings-goals {
    background: $background !important;
    width: -webkit-fill-available;

    .grid-padding {
      margin-top: 2px !important;
    }

    .org-title {
      font-size: $Body-Default;
      color: #757575;
    }

    .org-linkLabel {
      font-weight: 400;
      font-size: $Body-Default;
      line-height: 1.5rem;
      color: $linkLabel;
      margin-left: 5px;
      cursor: pointer;
    }

    .empty-strategic {
      display: flex !important;
      justify-content: center;
    }
  }
}

.manage-users {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }
}

.reassign-activities {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .Primary-Button {
    margin-top: $spacing-lg;
  }

  .reassign-description {
    margin-bottom: $spacing-lg;
  }
}

.guest-users {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .Primary-Button {
    width: 8.25rem;
  }
}

.manage-contractors {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .Primary-Button {
    margin: 0rem !important;
  }
}

.manage-venues {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .Primary-Button {
    margin: 0rem !important;
  }
}

.broadcast-notification {
  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .com-contact-btn {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .Primary-Button {
    margin-left: $spacing-md;
  }

  .ui.table {
    margin: 0rem !important;
  }
}

.org-settings-segment {
  padding: $spacing-lg $spacing-xl 48px $spacing-xl !important;

  .org {
    width: 100% !important;
  }

  .row,
  .column {
    padding: 0rem !important;
  }

  .ui.grid,
  .label {
    margin: 0rem !important;
  }

  .two-step-radio-container {
    margin: 17px 0rem;
  }

  .ui.input > input {
    margin: 1rem 0rem 1rem 0rem !important;
  }

  .right-column {
    margin-left: 72px;
  }
}

.end-cross-referencing {
  .header {
    @include Body-Default-Semibold;
  }

  .Primary-Button {
    padding: 0.625rem 1.7rem !important;
  }
}

.add-checklist-item-btn {
  margin: $spacing-lg $spacing-xl $spacing-lg 0rem !important;
}

.org-cross-ref-segment {
  padding: $spacing-lg 0rem 0rem 0rem !important;

  .financial-method-container {
    margin: 0rem $spacing-xl 0rem $spacing-xl !important;
  }

  .financial-method-radio {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0rem;
  }

  .add-reference-btn {
    margin: $spacing-lg $spacing-xl 0rem 0rem;
  }

  .ui.table {
    margin-top: $spacing-lg !important;
  }

  .ref-label {
    @include Body-Small($Text-Secondary);
    margin-bottom: 0.5rem !important;
  }

  .ref-download-link {
    @include Body-Default($Info);

    a {
      color: $Info;
    }
  }

  a.header {
    @include font-style;
  }

  .ref-label-code {
    @include Body-Default($Text-Primary);
  }

  .menu-dropdown {
    display: flex;
    justify-content: flex-end;
  }

  .ui.grid {
    padding: 1rem;
  }

  .row,
  .column {
    padding: 0rem !important;
  }
}

.add-dept-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.org-dept-unit-segment {
  padding: $spacing-lg 0rem 0rem 0rem !important;

  .financial-method-container {
    margin: 0rem $spacing-xl 0rem $spacing-xl !important;
  }

  .financial-method-radio {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0rem;
  }

  .add-reference-btn {
    margin: $spacing-lg $spacing-xl 0rem 0rem;
  }

  .ui.table {
    margin-top: $spacing-lg !important;
  }

  .ref-label {
    @include Body-Small($Text-Secondary);
    margin-bottom: 0.5rem !important;
  }

  .ref-download-link {
    @include Body-Default($Info);

    a {
      color: $Info;
    }
  }

  a.header {
    @include font-style;
  }

  .ref-label-code {
    @include Body-Default($Text-Primary);
  }

  .menu-dropdown {
    display: flex;
    justify-content: flex-end;
  }

  .ui.grid {
    padding: 1rem;
  }

  .row,
  .column {
    padding: 0rem !important;
  }
}

.add-ref-model,
.edit-ref-model {
  .header {
    @include Body-Default-Semibold;
  }

  .content {
    padding: 0rem !important;
  }

  .ui.selection.dropdown {
    padding-right: 1rem !important;
    margin-bottom: 0.25rem !important;
  }

  i.icon {
    float: right !important;
  }

  // TODO: why use this ?
  p {
    // margin-bottom: 1rem !important;
  }

  .Primary-Button {
    padding: 0.625rem 1.4rem !important;
  }

  button.ui.button.btn-add-ref {
    padding: 0rem !important;
  }

  input,
  select {
    height: 40px;
  }

  label {
    @include Body-Small($Text-Primary);
    margin-bottom: 0.5rem !important;
  }

  p.text-muted {
    @include Body-Small($Text-Secondary);
  }

  .form-group {
    width: 100%;
    margin-bottom: 0.5rem !important;
  }

  .model-upper {
    padding: $spacing-lg $spacing-lg 0rem $spacing-lg !important;
  }

  .model-below {
    padding: 0rem $spacing-lg 1rem $spacing-lg !important;
  }

  .ui.section.divider {
    margin: $spacing-lg 0rem $spacing-lg 0rem !important;
  }

  .ref-ul-custom {
    margin: 0rem 0rem 0rem 1rem !important;
    padding: 0rem !important;
    text-align: left;

    li {
      padding-bottom: 1rem;
    }
  }
}

.modal {
  .header {
    @include Body-Default-Semibold;
  }
}

.add-cross-ref-model {
  .header {
    @include Body-Default-Semibold;
  }

  .content {
    padding: 0rem !important;
  }

  p {
    margin-bottom: 1rem !important;
  }

  .cross-reference {
    width: 520px !important;
    margin-bottom: $spacing-lg !important;
  }

  .form-text {
    margin-top: 0.25rem !important;
  }

  label {
    margin-bottom: 0.5rem !important;
  }

  .browse-container {
    label {
      margin: 0rem !important;
    }
  }

  input,
  select {
    height: 40px;
    border-radius: 0.5rem !important;
  }

  label {
    @include Body-Small($Text-Primary);
  }

  label.form-text.text-muted,
  p.text-muted {
    @include Body-Small($Text-Secondary);
    padding-bottom: 1rem;
  }

  .model-upper {
    padding: $spacing-lg $spacing-lg 0rem $spacing-lg !important;
  }

  .model-below {
    padding: 0rem $spacing-lg 1rem $spacing-lg !important;
  }

  .ui.section.divider {
    margin: 26px 0rem 1rem 0rem !important;
  }
}

.cross-reference-label {
  margin-bottom: 1rem !important;

  .ui.icon.right.attached.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  textarea {
    height: 75px !important;
    resize: none;
    border: 0.5px solid #c3d3e6;
    border-radius: 0.5rem;
  }

  .textarea-with-btn {
    border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  }

  .segment {
    padding: 0rem 0.063rem 0rem 0rem !important;
  }

  .ui.icon.right.attached.button {
    height: 73px;
    background-color: white !important;
    border-radius: 0rem 0.5rem 0.5rem 0rem !important;
    margin-top: 0.063rem;
    border-color: red !important;
  }
}

.ui.icon.right.attached.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ref-document-dropdown {
  padding-right: 1rem;

  .ui.selection.dropdown {
    padding: 0rem !important;
  }

  .ui.selection.dropdown {
    border: none;

    i.angle.down.icon {
      float: right !important;
    }
  }
}

.contact-category-checkBox {
  div {
    display: flex;
    background-color: transparent !important;
    width: -webkit-fill-available;
    margin-left: 5px;
  }
}

.contact-category-row {
  padding-top: 0px !important;
}

.contact-category-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;

  .contact-category-header {
    margin-left: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: $Body-Default;
    line-height: 20px;
  }

  .form-check {
    .form-check-label {
      margin-top: 0px;
    }

    .form-check-input {
      margin-top: 0px;
    }
  }
  .grid {
    .contact-category-radio {
      display: flex;
      align-items: center;

      .contact-category-radio-text {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Default !important;
        line-height: 22px !important;
        padding-left: 10px !important;
        padding-top: 3px !important;
      }
      .contact-category-radio-text-custom {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Default !important;
        line-height: 22px !important;
        padding-left: 10px !important;
        padding-top: 8px !important;
      }
    }
    .contact-category-checkbox {
      display: flex;
      align-items: center;
      padding-bottom: 8px !important;
      .form-check {
        padding-left: 0px !important;
      }

      .contact-category-radio-text {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Default !important;
        line-height: 22px !important;
        padding-left: 10px !important;
        padding-top: 3px !important;
      }
      .contact-category-radio-text-custom {
        flex: 1;
        color: $info !important;
        font-family: $fontFamily !important;
        font-size: $Body-Default !important;
        line-height: 22px !important;
        padding-left: 10px !important;
        padding-top: 8px !important;
      }
    }
  }
}

.contactSearch {
  width: 230px;
}

.required-asterisk {
  color: red;
  margin-left: 4px;
}

.add-new-user {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }

  .Primary-Button {
    padding: 0.625rem 1.4rem !important;
  }
}

.broadcast-new-message {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }

  .broadcast-dropdown {
    height: 0em !important;
  }

  .Primary-Button {
    width: 76px;
  }
}

.organisation-contact-tab {
  background-color: #fff;
}

.org-profile-pic-label {
  margin-top: 0.438rem !important;
  @include Body-Small($Text-Secondary);
}

.org-profile-pic-segment {
  border: 0.031rem solid #c3d3e6;
  border-radius: 0.5rem;
  max-height: 7.5rem;

  .row {
    margin: 0rem !important;
    column-gap: $spacing-lg;
    row-gap: 0.5rem;
  }

  .org-profile-container {
    width: 9.75rem;
    height: 120px;

    img {
      width: 9.75rem !important;
      height: 7.3rem !important;
    }
  }

  .org-profile-btn-col {
    display: flex !important;
  }

  .image {
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    display: block !important;
    object-fit: cover !important;
    border-right: 0.031rem solid #c3d3e6;
    max-height: 7.375rem;
  }

  .org-profile-pic {
    display: flex;
  }

  .org-profile-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .org-click-to-upload {
    cursor: pointer !important;
    @include Body-Default-Semibold($Primary);
    margin-bottom: 0.5rem !important;
  }

  .org-profile-des {
    color: #757575 !important;
  }

  .org-profile-pic-name {
    color: #757575 !important;
    margin-bottom: 0.5rem !important;
    width: 11.25rem;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media only screen and (min-width: 768px) {
    max-height: 22.5rem !important;

    .row {
      display: flex;
      justify-content: center;
    }

    .image {
      border: none !important;
    }

    .org-profile-btn {
      align-items: center;
    }
  }

  @media only screen and (min-width: 1250px) {
    max-height: 7.5rem !important;

    .image {
      border-right: 0.031rem solid #c3d3e6 !important;
    }

    .row {
      display: flex !important;
      justify-content: flex-start !important;
    }

    .org-profile-btn {
      align-items: flex-start !important;
    }
  }
}

.org-profile-pic-segment-edit {
  border: 0.031rem solid #c3d3e6;
  border-radius: 0.5rem;
  max-height: 7.5rem;

  .row {
    margin: 0rem !important;
    column-gap: $spacing-lg;
    row-gap: 0.5rem;
  }

  .org-profile-container {
    width: 9.75rem;
    height: 120px;

    img {
      width: 9.75rem !important;
      height: 7.3rem !important;
    }
  }

  .org-profile-btn-col {
    display: flex !important;
  }

  .image {
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    display: block !important;
    object-fit: cover !important;
    border-right: 0.031rem solid #c3d3e6;
    max-height: 7.375rem;
  }

  .org-profile-pic {
    display: flex;
  }

  .org-profile-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .org-click-to-upload {
    cursor: pointer !important;
    @include Body-Default-Semibold($Primary);
    margin-bottom: 0.5rem !important;
  }

  .org-profile-des {
    color: #757575 !important;
  }

  .org-profile-pic-name {
    color: #757575 !important;
    margin-bottom: 0.5rem !important;
    width: 11.25rem;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media only screen and (min-width: 768px) {
    max-height: 22.5rem !important;

    .row {
      display: flex;
      justify-content: center;
    }

    .image {
      border: none !important;
    }

    .org-profile-btn {
      align-items: center;
    }
  }

  @media only screen and (min-width: 1250px) {
    max-height: 7.5rem !important;

    .image {
      border-right: 0.031rem solid #c3d3e6 !important;
    }

    .row {
      display: flex !important;
      justify-content: flex-start !important;
    }

    .org-profile-btn {
      align-items: flex-start !important;
    }
  }
}

.organisation-strategic-document {
  .ui.input > input {
    padding: 0.5rem 0.7rem;
  }

  label {
    @include Body-Default;
  }

  .text-muted {
    color: $Text-Secondary !important;
  }

  .text-error {
    @include Body-Small($Error);
  }
}

.edit-outcome {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }
}

.edit-help-tip {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }
}

.view-broadcast-message {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }
}

.delete-user {
  .actions {
    display: flex;
    justify-content: flex-end;
  }
}

.view-activities-guest-user {
  .content {
    padding: 0px !important;
  }
}

.ui.checkbox {
  z-index: 1;
}

.txt-partner-description {
  font-size: $Body-Default !important;
}

.add-contact-container {
  //@include set-margin($spacing-lg, $spacing-xl, $spacing-lg, $spacing-xl);
}

.contacts-tab {
  @include Body-Default;
}

.manage-contacts {
  .add-contact-container {
    display: flex;
    justify-content: flex-end;
  }
  .org-users {
    .Primary-Button {
      margin: $spacing-md $spacing-xl $spacing-md;
    }
  }
  .ui.pointing.secondary.menu {
    padding-left: 32px;
    .item {
      padding-left: 0px !important;
    }
  }
}

.user-activity {
  .header {
    padding: $spacing-md $spacing-xl !important;
  }
}

.ui.radio.checkbox {
  label {
    color: $info;
    font-family: $fontFamily;
    font-size: 14px;
  }
}
.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
  background-color: #fff !important;
  border: solid 2px $gradientRight;
}
.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before {
  background-color: #fff !important;
  border: solid 2px $gradientRight;
}
.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: $gradientRight;
}
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: $gradientRight;
}

.radio-label {
  color: $info;
  font-family: $fontFamily;
  font-size: 12px;
  line-height: 17px;
}
.radio-btn {
  margin: 0.5em 2.5rem 0.5em 0;
}

.ui.modal > .content label {
  color: #3b3b3b;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
}

.partner-inputs-disabled {
  height: 36px !important;
  background: #bdbdbd;
  font-size: 14px !important;
  color: $subLabel !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  .ui.input > input {
    height: 36px !important;
    background: #bdbdbd;
    font-size: 14px !important;
    color: $subLabel !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
}
.multi-select-dropdown {
  position: relative;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  margin-top: 7px;
}

.arrow.open {
  border-width: 0 5px 5px 5px;
}

.placeholder {
  color: #999;
}

.options {
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #ffffff;
  border: 0.5px solid #bdbdbd;
  box-shadow: 0px 2px 6px rgba(210, 210, 210, 0.5);
  border-radius: 4px;
  margin-bottom: 25px;
}

.option {
  display: flex;
  padding: 14px 13px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  gap: 5px;
}

.option input[type='checkbox'] {
  margin-right: 10px;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.selected-option {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #eaeaea;
  border-radius: 5px;
}

.remove-option {
  margin-left: 5px;
  cursor: pointer;
}

.clear {
  display: block;
  padding: 10px;
  text-align: center;
  background-color: #f7f7f7;
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.clear:hover {
  background-color: #eaeaea;
}

.three-step-radio-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30%;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  label {
    @include font-style(1rem !important, $info);
    flex: 2;
    margin-right: 0.938rem;
  }

  margin: 1.25rem 0rem;

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $gradientRight !important;
  }

  .ui.checkbox {
    z-index: 1 !important;
  }
}

.view-container-goals {
  margin-left: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ef8123;
  cursor: pointer;
}

.outcome-address-back {
  margin: 0.4rem 0;
  .outcome-address-back-arrow {
    border-style: none !important;
  }
}

.view-outcome-back {
  margin-left: 2rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ef8123;
  cursor: pointer;
}

.goal-list-view {
  display: flex;
  flex-direction: column;
  padding: 25px 0px 10px 20px;
}

.list-goals {
  margin-top: 10px;
}

.unit-issues-box {
  // padding: 1rem $spacing-xl 1rem $spacing-xl;
  background-color: white;

  .padding-issue {
    background: #ffebee;
    border: 0.031rem solid #d50000;
    border-radius: 0.25rem;
    padding: 1rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }
}

.sub-header-checkbox {
  margin-left: 1.4rem;
  margin-top: 1rem;
  size: 0.8rem;
}

.custom-multiple-checkbox-ul {
  margin-left: -1.6rem;
  size: 0.8rem;
  li {
    margin-top: 1.14rem;
  }
}

.ui.segment.content-segment {
  border: none !important;
  box-shadow: none !important;
}

.lib-header-partner {
  margin-bottom: -20px;
  margin-left: 13px;
}
.org-users-text-partner {
  margin-bottom: 15px;
}
.partner-name {
  margin-top: -20px;
}

.lable-center {
  padding: 5px 5px 5px 5px;
  margin-left: 7px;
  color: red;
}

.actions-invite-activity {
  padding: 8px 8px 8px 8px;
}

.org-users-text-partner-view-history {
  margin-bottom: 10px;
}

.activity-actions-inside {
  margin-top: 10px;
}

.partner-model-style {
  color: red;
}
.partners-remove-view {
  color: red;
}

.over-ride-message {
  .ui.orange.message {
    background-color: #fdf2e9 !important;
    border-radius: 8px !important;
    color: #000000 !important;
    box-shadow: none !important;
  }
  .ui.icon.message > .icon:not(.close) {
    font-size: 20px !important;
  }
}
.link-url {
  color: $amber;
  font-weight: 500;
  text-decoration: underline;
  a {
    color: $amber !important;
  }
}

.add-venue-sub-label {
  margin-bottom: 1rem !important;
  .ui.icon.right.attached.button {
    padding: 0;
    background: none;
    border: none;
    color: red;
    margin-left: -2rem;
  }
  .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -2rem;
    cursor: pointer;
    margin-bottom: 0.3rem;
    color: #757575;
  }
  .ui.icon.right.attached.button.delete {
    border: none !important;
  }
  textarea {
    height: 75px !important;
    resize: none;
    border: 0.5px solid #c3d3e6;
    border-radius: 0.5rem;
  }

  .textarea-with-btn {
    border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  }

  .segment {
    padding: 0rem 0.063rem 0rem 0rem !important;
  }
  .edit-venue-type-input {
    width: 100%;
  }
}

.sub-options-container {
  width: 100%;
}
.ui.modal > .content label {
  font-size: 12px;
  color: #757575;
}

.d-flex.add-venue-sub-label .formik-custom-field {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
